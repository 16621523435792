import { MessagesDe as MessagesDeTheme } from 'materialTheme/src/theme/i18n/MessagesDe';
export class MessagesDe extends MessagesDeTheme {
    constructor() {
        super(...arguments);
        this['?'] = '?';
        this.ProjectnotFound = 'Das Projekt wurde nicht gefunden oder Sie haben keinen Zugriff mehr auf das Projekt!';
        this.abort = 'Abbrechen';
        this.absence = 'Abwesenheit';
        this.absenceCostCenter = 'Kostenstelle';
        this.absenceDeleteQuestion = 'Möchten Sie die Abwesenheit wirklich löschen?';
        this.absenceDialogTitleAdd = 'Neue Abwesenheit erfassen';
        this.absenceDialogTitleChange = 'Abwesenheit ändern';
        this.absenceEnds = 'Bis';
        this.absenceErrorEndsBeforeStart = 'Endzeit vor Startzeit';
        this.absenceMember = 'Für';
        this.absenceNoDataHint = 'Erstellen Sie neue Abwesenheiten, indem Sie auf den + Button drücken';
        this.absenceNoDataHintNoRights = 'Sie haben keine Berechtigung Abwesenheiten zu erfassen';
        this.absenceNoDataText = 'Keine Abwesenheiten erfasst';
        this.absenceNote = 'Bemerkung';
        this.absenceOpenRequests = 'Offene Anträge';
        this.absenceRange = 'Zeitraum';
        this.absenceStarts = 'Von';
        this.absenceState = 'Status';
        this.absenceStateApproved = 'genehmigt';
        this.absenceStateOpen = 'offen';
        this.absenceStateRejected = 'abgelehnt';
        this.absenceTask = 'Grund';
        this.absences = 'Abwesenheiten';
        this.account = 'Mein Konto';
        this.accountAddress = 'Adresse';
        this.accountChangeAddressCity = 'Ort';
        this.accountChangeAddressCountry = 'Land';
        this.accountChangeAddressStreet = 'Straße';
        this.accountChangeAddressStreetN = 'Hausnummer';
        this.accountChangeAddressTitle = 'Adresse ändern';
        this.accountChangeAddressZip = 'PLZ';
        this.accountChangeCompanyTitle = 'Firma ändern';
        this.accountChangeNames = 'Name ändern';
        this.accountChangeNew = 'Neues Passwort';
        this.accountChangeOld = 'Altes Passwort';
        this.accountChangePw = 'Passwort ändern';
        this.accountCompany = 'Firma';
        this.accountCompanySettingsPageTitle = 'Firmenverwaltung';
        this.accountCompanySettingsUserListHeaderTitle = 'Mitarbeiter';
        this.accountDeleteEmailQuestion = 'Wollen Sie diese E-Mail-Adresse wirklich löschen?';
        this.accountDisconnectGoogleAlert = 'Wollen Sie die Verbindung mit Ihrem Google Konto wirklich trennen?';
        this.accountEmailNotValidated = 'E-Mail-Adresse noch nicht verifiziert.';
        this.accountEmailRequestValidateMail = 'Verifizierungs-E-Mail erneut senden?';
        this.accountEmails = 'E-Mail-Adressen';
        this.accountEmailsAdd = 'E-Mail-Adresse hinzufügen';
        this.accountEmailsNotification = 'an diese E-Mail Benachrichtigungen schicken';
        this.accountFunctionInCompany = 'Position';
        this.accountImageDeleteHint = 'Nutzerbild löschen';
        this.accountImageDropHint = 'Nutzerbild ändern';
        this.accountLogout = 'Abmelden';
        this.accountLogoutQuestion = 'Wenn Sie sich abmelden, können Sie sich nur mit vorhandener Internetverbindung' +
            ' wieder anmelden und Ihre Offline gespeicherten Daten gehen vorloren';
        this.accountLogoutQuestionTitle = 'Abmelden bei upmesh?\n';
        this.accountManagerPhone = 'Anrufen';
        this.accountManagerServicesContractTopics = 'Vertragsthemen';
        this.accountManagerServicesLicenses = 'Lizenzen';
        this.accountManagerServicesOnboarding = 'Onboarding';
        this.accountManagerServicesTrainings = 'Schulungen';
        this.accountManagerSubtitle = 'Ihr Ansprechpartner bei upmesh';
        this.accountManagerWriteMail = 'Mail schreiben';
        this.accountNotWithGoogleConnectedButton = 'Mit Google verbinden';
        this.accountSettings = 'Einstellungen';
        this.accountWithGoogleConnectedButton = 'Googleverbindung trennen';
        this.accountWithGoogleConnectedButtonShort = 'trennen';
        this.active = 'aktiv';
        this.activities = 'Aktivitäten';
        this.add = 'Hinzufügen';
        this.addCompanyUserAsTeamMember = 'Eigene Firmen-Mitarbeiter hinzufügen';
        this.addImageDropHint = 'Bild hinzufügen';
        this.addImageOnMultiSelect = 'Bild hinzufügen';
        this.addMapMapTitle = 'Kartentitel';
        this.addMapTitle = 'Karte hinzufügen';
        this.addNewCustomFieldRule = 'Neue Regel';
        this.addPlanSnapshot = 'Planausschnitt hinzufügen';
        this.addSubtickets = 'Sub-Tickets hinzufügen';
        this.addTags = 'hinzufügen';
        this.addTeamMember = 'Teammitglied hinzufügen';
        this.addTicket = 'Ticket hinzufügen';
        this.added = 'Hinzugefügt';
        this.addedPlanVersion = 'Planversion hinzugefügt';
        this.addisonExportCompanyInvalid = 'Die Firmennummer "{error}" ist ungültig für einen Addison-Export. (maximal 7 Ziffern)';
        this.addisonExportCostCenterInvalid = 'Die Kostenstelle "{error}" ist ungültig für einen Addison-Export. (maximal 9 Ziffern)';
        this.addisonExportCostUnitInvalid = 'Der Kostenträger "{{error}}" ist ungültig für einen Addison-Export. (maximal 9 Ziffern)';
        this.addisonExportWageTypeInvalid = 'Die Lohnart "{{error}}" ist ungültig für einen Addison-Export. (maximal 4 Ziffern)';
        this.addressErrorRequireCity = 'Stadt wird benötigt';
        this.addressErrorRequireCountry = 'Land wird benötigt';
        this.addressErrorRequireStreet = 'Straße wird benötigt';
        this.addressErrorRequireStreetNumber = 'Hausnummer wird benötigt';
        this.addressErrorRequireZip = 'PLZ wird benötigt';
        this.addressPhoneCompany = 'Telefonnummer';
        this.adsSettingsCheckboxLabel = 'relevante Werbung';
        this.adsSettingsSectionLabel = 'Ad Einstellungen';
        this.adsSettingsUpgradeSubscriptionLink = 'Sie möchten keine Werbung mehr sehen?\nJetzt upgraden';
        this.adsUserConsentAgreeWithRelevantAdsButtonLabel = 'Ja. Mehr relevante Werbung';
        this.adsUserConsentHeaderNote = 'Wir sind uns unserer Verantwortung gegenüber Ihrer Privatsphäre und Datensicherheit bewusst. Wir können Ihnen diese App kostenfrei anbieten, indem wir Ihnen Werbung zeigen.';
        this.adsUserConsentHeaderQuestion = 'Dürfen wir weiterhin Ihre Daten verwenden, um Ihnen maßgeschneiderte Werbung zeigen zu können?';
        this.adsUserConsentLessRelevantAdsButtonLabel = 'Nein. Weniger relevante Werbung';
        this.adsUserConsentLinkToMoreInfoAboutCollectingData = 'Sie können hier weitere Informationen sehen, wie wir und unsere Partner Daten sammeln und nutzen.';
        this.adsUserConsentSecondPageHeaderNote = 'Sie können uns die Erlaubnis zur Nutzung Ihrer Daten jederzeit in den App- Einstellungen entziehen. Sehen Sie hier, wie wir und unsere Partner Daten sammeln und nutzen.';
        this.adsUserConsentUpdateChoiceLaterText = 'Sie können uns die Erlaubnis zur Nutzung Ihrer Daten jederzeit in den App- Einstellungen entziehen. Unsere Partner sammeln Daten und nutzen dafür eine eindeutige ID, um auf Ihrem Gerät Werbung anzeigen zu können.';
        this.adsUserConsentUpmeshPrivacyLinkText = 'Wie upmesh Ihre Daten nutzt.';
        this.allProjects = 'Alle Projekte';
        this.allTickets = 'Alle Tickets';
        this.apiToken = 'Token';
        this.apiTokenAddCompanyText = 'Firmentoken';
        this.apiTokenAddQuestionCompany = 'Möchten Sie einen neuen firmenbezogenen Zugangstoken für die Api erstellen?';
        this.apiTokenAddQuestionNote = 'Notiz';
        this.apiTokenAddQuestionUser = 'Möchten Sie einen neuen nutzerbezogenen Zugangstoken für die Api erstellen?';
        this.apiTokenAddText = 'Token erzeugen';
        this.apiTokenAddUserText = 'Benutzertoken';
        this.apiTokenAlert = 'Ein API-Token ist ein Authentifizierungsschlüssel, der für den Zugriff auf bestimmte Funktionen und Daten in der App verwendet wird. Damit können autorisierte Anwendungen Aktionen in Ihrem Konto ausführen.';
        this.apiTokenCopyToClipoard = 'Zwischenablage';
        this.apiTokenNotYet = 'Es sind noch keine Api Zugriffs Token vorhanden';
        this.apiTokenRevoke = 'Widerrufen';
        this.apiTokenRevokeQuestion = 'Möchten Sie den Zugang wirklich widerrufen?';
        this.apiTokenRevoked = 'Widerrufen';
        this.apiTokenTitle = 'API Zugriff';
        this.appData = 'App Einstellungen';
        this.apply = 'Bestätigen';
        this.archive = 'Archivieren';
        this.archiveActivePlanMap = 'Karte';
        this.archiveAddressContact = 'Kontatkname';
        this.archiveAddressLatitude = 'Längengrad';
        this.archiveAddressLongitude = 'Breitengrad';
        this.archiveDocumentMimeType = 'MIME-Typ';
        this.archiveDocuments = 'Dokumente';
        this.archiveDocumentsFiles = 'Dateien';
        this.archiveDocumentsSize = 'Dateigröße';
        this.archiveDocumentsTitle = 'Dateiname';
        this.archiveDocumentsUploadedAt = 'Hochgeladen am';
        this.archiveDocumentsUploadedBy = 'Hochgeladen von';
        this.archiveJournals = 'Tagesberichte';
        this.archiveMap = 'Karten';
        this.archivePlan = 'Pläne';
        this.archivePlanActivatedBy = 'Aktiviert von';
        this.archivePlanActivatedOn = 'Aktiviert am';
        this.archivePlanActiveId = 'Aktive PlanId';
        this.archivePlanNotes = 'Anmerkungen';
        this.archivePlanTitle = 'Plantitel';
        this.archivePlanVersions = 'Version';
        this.archiveProjectDetails = 'Projektdetails';
        this.archiveProjectDetailsBuildEnd = 'Bauende';
        this.archiveProjectDetailsBuildStart = 'Baubeginn';
        this.archiveProjectDetailsEnd = 'Projektende';
        this.archiveProjectDetailsProjectAbo = 'Projet Abbonement';
        this.archiveProjectDetailsProjectDescription = 'Projektbeschreibung';
        this.archiveProjectDetailsProjectSize = 'Projektgröße';
        this.archiveProjectDetailsStart = 'Projektstart';
        this.archiveProjectDetailsTitle = 'Projekttitel';
        this.archiveProjectDetailsType = 'Projektart';
        this.archiveTeamCity = 'Stadt';
        this.archiveTeamCompany = 'Firma';
        this.archiveTeamCountry = 'Land';
        this.archiveTeamDescription = 'Beshcreibung';
        this.archiveTeamEmail = 'E-Mail';
        this.archiveTeamFirstName = 'Vorname';
        this.archiveTeamLastName = 'Nachname';
        this.archiveTeamPostalCode = 'Postleitzahl';
        this.archiveTeamRole = 'Rolle';
        this.archiveTeamStatus = 'Status';
        this.archiveTeamStatusAccepted = 'Akzeptiert';
        this.archiveTeamStreet = 'Straße';
        this.archiveTeamStreetNumber = 'Hausnummer';
        this.archiveTicket = 'Ticket archivieren';
        this.archiveTicketArchived = 'Archiviert';
        this.archiveTicketAssignedTo = 'Zugewiesen an';
        this.archiveTicketComment = 'Kommentar';
        this.archiveTicketCreator = 'Ersteller';
        this.archiveTicketDescription = 'Beschreibung';
        this.archiveTicketFileId = 'Datei ID';
        this.archiveTicketFilesFromActivities = 'Dateien aus den Aktivitäten';
        this.archiveTicketImages = 'Bilder';
        this.archiveTicketMiscellaneous = 'Sonstige';
        this.archiveTicketPlanAffiliation = 'Planzugehörigkeit';
        this.archiveTicketPlanPosition = 'Plan Position';
        this.archiveTicketShort = 'Archivieren';
        this.archiveTicketState = 'Ticket Status';
        this.archiveTicketTags = 'Tags';
        this.archiveTicketTitle = 'Ticket Titel';
        this.archiveTicketType = 'Tickettyp';
        this.archiveTickets = 'Tickets';
        this.archiveTicketsDialogHeadline = 'Tickets archivieren';
        this.archiveTicketsQuestion = 'Sollen diese Tickets archiviert / wiederhergestellt werden?\nArchivierte Tickets können' +
            ' jederzeit über die Filterfunktion eingesehen und wieder hergestellt werden';
        this.archived = 'archiviert';
        this.askOwnerForUpgradeProject = 'Diese Funktion ist nur in einem Pro- oder Firmen-Projekt (Enterprise) nutzbar. Bitte' +
            ' fragen Sie den Projekt Eigentümer nach einem Upgrade!';
        this.audio = 'Audio';
        this.back = 'zurück';
        this.badRequest = 'Fehlerhafte Eingabe';
        this.basicProject = 'Basic-Projekt';
        this.bau2cloud = 'upmesh';
        this.bookmark = 'Bookmarks';
        this.brzExportCompanyInvalid = 'Die Betriebsnummer "{{error}}" ist ungültig für einen BRZ-Export. (maximal 6 Ziffern).';
        this.brzExportCompanyNumber = 'Betriebsnummer';
        this.brzExportCompanyNumberNeeded = 'Für den BRZ-Export wird eine Betriebsnummer benötigt.';
        this.brzExportCostCenterInvalid = 'Die Kostenstelle "{{error}}" ist nicht gültig für einen BRZ-Export. (maximal 8 Ziffern)';
        this.brzExportProjectNameInvalid = 'Der Projektname "{{error}}" ist zu lang für einen BRZ-Export. (maximal 15 Stellen)';
        this.brzExportWageTypeInvalid = 'Die Lohnart "{{error}}" ist ungültig für einen BRZ-Export. (maximal 3 Ziffern)';
        this.buildTime = 'Bauzeit';
        this.builder = 'Bauherr';
        this.bulkChangesAddWatcher = 'Beobachter hinzufügen';
        this.bulkChangesArchive = 'Archivieren';
        this.bulkChangesChangeApprover = 'Prüfer ändern';
        this.bulkChangesChangeAssignee = 'Zugewiesenen ändern';
        this.bulkChangesChangeDueDate = 'Fälligkeitsdatum ändern';
        this.bulkChangesChangingEntries = 'Ändere Eintrag {{count}} von {{sum}}.';
        this.bulkChangesChangingTickets = 'Ändere Ticket {{count}} von {{sum}}.';
        this.bulkChangesConfirmationDialog = 'Wollen Sie diese {{count}} Einträge wirklich bearbeiten?';
        this.bulkChangesConfirmationDialogHeader = 'Tickets bearbeiten';
        this.bulkChangesConfirmationDialogText = 'Wollen Sie diese {{count}} Tickets wirklich bearbeiten?';
        this.bulkChangesDelete = 'Löschen';
        this.bulkChangesIconTooltip = 'Massenänderung durchführen';
        this.bulkChangesMoreOptions = 'Mehr Optionen';
        this.bulkChangesNewValue = 'Neuer Wert';
        this.bulkChangesNoRights = '{{count}} Tickets konnten wegen fehlender Berechtigung nicht geändert werden';
        this.bulkChangesNotAssignedToUser = 'Nicht zugewiesen';
        this.bulkChangesSelectAll = 'Alle auswählen';
        this.bulkChangesSelectNone = 'Keins auswählen';
        this.calendarWeekShort = 'KW';
        this.camCreateNewPlan = 'Neuen Plan erstellen';
        this.camCreateNewTicket = 'Neues Ticket erstellen';
        this.camSaveInMyMedia = 'Im privaten Ordner ablegen';
        this.camTargetOptionTitle = 'Foto verwenden für';
        this.camera = 'Kamera';
        this.canChangeAllTickets = 'Alle Tickets bearbeiten';
        this.canEditJournals = 'Kann Tagesberichte bearbeiten';
        this.canTrackExtraPay = 'Kann Zulagen erfassen';
        this.cancel = 'Abbrechen';
        this.cantChangeUserRoleHeIsLastAdmin = 'Die Rolle kann nicht geändert werden. Er ist der letzte Firmenadmin!';
        this.cantDeleteActivePlanversion = 'Die aktive Planversion kann nicht gelöscht werden';
        this.cantDeleteFileHasAssigendTickets = 'Die Datei kann nicht gelöscht werden, da sie einem Ticket zugeordnet ist.' +
            ' Dateien in Tickets können nur vom Ersteller innerhalb von 24 Stunden gelöscht werden.';
        this.cantDeleteHasAssigendTickets = 'Das Teammitglied konnte nicht entfernt werden, da es noch zugewiesene Tickets hat';
        this.cantDeleteHasAssigendTicketsMe = 'Sie konnten das Projekt nicht verlassen, da Sie noch zugewiesene Tickets haben';
        this.cantDeleteRoleUsersHasRole = 'Die Rolle kann nicht gelöscht werden, solange einem Teammitglied die Rolle zugewiesen ist';
        this.cantDeleteTicketLayoutTicketHasType = 'Das Layout kann nicht gelöscht werden, dem zugeordnetem Typ "{{type}}" ist mindestens ein Ticket zugewiesen';
        this.cantRemoveUserHeIsInvolvedInEnterpriseProjects = 'Dem Benutzer kann die Volllizenz nicht entzogen werden. Er hat eine Rolle in einem oder mehreren' +
            ' Firmen-Projekten, in denen die Lizenz benötigt wird.';
        this.cantRemoveUserHeIsInvolvedInEnterpriseProjectsForceRemoveOrDowngrade = 'Soll der Benutzer aus den ensprechenden Projekten entfernt oder seine Rolle zum "Nachunternehmer" geändert werden?';
        this.cantRemoveUserHeIsLastAdmin = 'Dem Benutzer kann die Volllizenz nicht entzogen werden. Er ist der letzte Firmenadmin!';
        this.change = 'Ändern';
        this.changeCompanyBackGroundImage = 'Firmen-Hintergrundlogo ändern';
        this.changeCompanyImage = 'Neu hochladen';
        this.changeFolder = 'Ordner bearbeiten';
        this.changeOwner = 'Projekt übergeben';
        this.changeRoleProError = 'Die Rolle konnte nicht geändert werden: Der Benutzer benötigt einen Pro- oder Firmen-Account für diese Rolle';
        this.changeRoleProProjectError = 'Die Rolle konnte nicht geändert werden: Pro Projekt erforderlich';
        this.changeRoleToManager = 'Projektleiter werden';
        this.changeTicketPlanPosition = 'Ticket Position ändern';
        this.changeView = 'Ansicht ändern';
        this.chipGroupNoGroup = 'Keine Gruppe';
        this.clear = 'Zurücksetzen';
        this.close = 'Schließen';
        this.closeAppText = 'Wollen Sie upmesh wirklich beenden?';
        this.closeAppTitle = 'upmesh beenden?';
        this.collaborator = 'Mitarbeiter';
        this.color = 'Farbe';
        this.commandAcceptInvitation = 'Einladung akzeptieren';
        this.commandActivatePlanVersion = 'Planversion aktiveren';
        this.commandAddEmail = 'E-Mail-Adresse hinzufügen';
        this.commandAddStoredFile = 'Datei hinzufügen';
        this.commandAddTag = 'Tag hinzufügen';
        this.commandAddTicketTag = 'Ticket Tag hinzufügen';
        this.commandAddTicketWatcher = 'Beobachter hinzufügen';
        this.commandArchiveTicket = 'Ticket archivieren';
        this.commandAssignTicketTo = 'Ticket zuweisen';
        this.commandChangeAppData = 'Einstellungen ändern';
        this.commandChangeExtraPayTracking = 'Erfasste Zulage bearbeiten';
        this.commandChangePassword = 'Passwort ändern';
        this.commandChangePlanDetails = 'Plan Details ändern';
        this.commandChangeProject = 'Projektdaten ändern';
        this.commandChangeProjectAddress = 'Ansprechpartner verwalten';
        this.commandChangeProjectCrafts = 'Projekt Gewerke bearbeiten';
        this.commandChangeProjectTags = 'Projekt Tags bearbeiten';
        this.commandChangeProjectTicketLayouts = 'Ticketlayouts bearbeiten';
        this.commandChangeProjectTicketTypes = 'Projekt Tickettypen ändern';
        this.commandChangeTicket = 'Ticketdaten ändern';
        this.commandChangeTicketApprover = 'Ticket Prüfer ändern';
        this.commandChangeTicketCraft = 'Ticket Gewerk ändern';
        this.commandChangeTicketCustomField = 'Benutzerdefiniertes Feld in "{{value}}" ändern';
        this.commandChangeTicketDescription = 'Ticket Beschreibung ändern';
        this.commandChangeTicketPlanAffiliation = 'Planzugehörigkeit ändern';
        this.commandChangeTicketPlanPosition = 'Planposition ändern';
        this.commandChangeTicketStatus = 'Ticket Status ändern';
        this.commandChangeTicketSubTicketFrom = 'Übergeordnetes Ticket ändern';
        this.commandChangeTicketTags = 'Ticket Tags ändern';
        this.commandChangeTicketTitle = 'Ticket Titel ändern';
        this.commandChangeTicketType = 'Tickettyp ändern';
        this.commandChangeTimeTracking = 'Erfasste Zeit bearbeiten';
        this.commandCreateExtraPayTracking = 'Zulage erfassen';
        this.commandCreatePlan = 'Plan erstellen';
        this.commandCreatePlanFile = 'Plandatei hochladen';
        this.commandCreatePlanVersion = 'Plan Version erstellen';
        this.commandCreateProject = 'Projekt erstellen';
        this.commandCreateProjectRole = 'Projekt Rolle erstellen';
        this.commandCreateTicket = 'Ticket erstellen';
        this.commandCreateTicketComment = 'Ticket kommentieren';
        this.commandCreateTimeTracking = 'Zeit erfassen';
        this.commandDeclineInvitation = 'Einladung ablehnen';
        this.commandDeleteExtraPayTracking = 'Erfasste Zulage löschen';
        this.commandDeleteTicket = 'Ticket löschen';
        this.commandDeleteTimeTracking = 'Erfasste Zeit löschen';
        this.commandDiscardPlanFile = 'Plandatei löschen';
        this.commandEditFolders = 'Ordner verwalten';
        this.commandEditTicketComment = 'Ticket Kommentar bearbeiten';
        this.commandFailPlanUpload = 'Plan Upload fehlgeschlagen';
        this.commandInviteUser = 'Benutzer einladen';
        this.commandLinkStoredFile = 'Datei einem Ticket zu weisen';
        this.commandOfflineNotPossible = 'Diese Aktion kann Offline nicht ausgeführt werden';
        this.commandRemoveStoredFile = 'Dateien löschen';
        this.commandRemoveTicketTag = 'Ticket Tag entfernen';
        this.commandRemoveTicketWatcher = 'Ticket Beobachter entfernen';
        this.commandRemoveUser = 'Teammitglied entfernen';
        this.commandRestoreTicket = 'Ticket wiederherstellen';
        this.commandSaveJournal = 'Tagesbericht speichern';
        this.commandSetTicketShouldCompleteDate = 'Ticket Fälligkeitsdatum ändern';
        this.commandStartSoredFileUpload = 'Datei upload starten';
        this.commandUnArchiveTicket = 'Ticket reaktivieren';
        this.company = 'Firma';
        this.companyAddCostCenterDialogTitle = 'Kostenstelle hinzufügen';
        this.companyAddCraftDialogTitle = 'Gewerk hinzufügen';
        this.companyAddEditUserDialogAddMemberInfo = 'Die Person kann andere Mitarbeitende zu Projekten hinzufügen\n\nErforderliche Lizenz: Projekleiterlizenz';
        this.companyAddEditUserDialogAdminInfo = 'Die Person kann Einstellungen für die Firma und anderen' +
            ' Firmenmitglieder vornehmen\n\nErforderliche Lizenz: Projekleiterlizenz';
        this.companyAddEditUserDialogExtraPayInfo = 'Die Person kann Zulagen sich und andere Mitarbeitende erfassen' +
            ' \n\nErforderliche Lizenz: ab Zeiterfassungslizenz';
        this.companyAddEditUserDialogJournalsInfo = 'Die Person kann Tagesberichte für diese Firma erstellen und bearbeiten.\n\nErforderliche Lizenz:' +
            ' Mitarbeiter- oder Projekleiterlizenz';
        this.companyAddEditUserDialogPayrollInfo = 'Die Person kann alle Zeiterfassungen für diese Firma' +
            ' sehen, sowie diese sperren\n\nErforderliche Lizenz: Projekleiterlizenz';
        this.companyAddExtraPayDialogTitle = 'Zulage erstellen';
        this.companyAddFileTypeDialogTitle = 'Kategorie hinzufügen';
        this.companyAddGroupDialogTitle = 'Gruppe hinzufügen';
        this.companyAddHolidayDialogTitle = 'Feiertag hinzufügen';
        this.companyAddNewUserDilaogButton = 'Account erstellen';
        this.companyAddNewUserDilaogText = 'Ein Account mit der E-Mail-Adresse {{email}} wurde nicht gefunden. Wollen Sie einen neuen Account erstellen?';
        this.companyAddRegionDialogTitle = 'Region hinzufügen';
        this.companyAddTaskDialogTitle = 'Tätigkeit hinzufügen';
        this.companyAddTicketTypeDialogTitle = 'Tickettyp hinzufügen';
        this.companyAddUserDilaogButton = 'Hinzufügen';
        this.companyAddUserDilaogCompanyAdmin = 'Firmenadmin';
        this.companyAddUserDilaogFooter = 'Ein Firmenadmin kann:\n- Mitarbeiter in die Firma hinzufügen und entfernen\n- das Firmenlogo ändern';
        this.companyAddUserDilaogText = 'Fügen Sie einen neuen Mitarbeiter zu Ihrem Firmenkonto hinzu';
        this.companyAddUserDilaogTitle = 'Mitarbeiter hinzufügen';
        this.companyAddress = 'Adresse';
        this.companyBackGroundLogoDropHint = 'Als Hintergrundlogo hinzufügen';
        this.companyBackGroundLogoInfoMessage = 'Das Bild wird als Hintergrundbild auf der Startseite angezeigt.';
        this.companyBackgroundLogo = 'Hintergrundlogo';
        this.companyChangeCostCenterDialogTitle = 'Kostenstelle ändern';
        this.companyChangeCraftDialogTitle = 'Gewerk bearbeiten';
        this.companyChangeExtraPayDialogTitle = 'Zulage bearbeiten';
        this.companyChangeFileTypeDialogTitle = 'Kategorie bearbeiten';
        this.companyChangeGroupDialogTitle = 'Gruppe bearbeiten';
        this.companyChangeHolidayDialogTitle = 'Feiertag ändern';
        this.companyChangeRegionDialogTitle = 'Region bearbeiten';
        this.companyChangeTaskDialogTitle = 'Tätigkeit bearbeiten';
        this.companyChangeTicketTypeDialogTitle = 'Tickettyp bearbeiten';
        this.companyChangeUserDilaogTitle = 'Mitarbeiter ändern';
        this.companyCostCenterAlreadyExists = 'Eine Kostenstelle mit dem Kennzeichen existiert bereits';
        this.companyCostCenterCantAddProjectToCostCenterOnlyCostUnit = 'Nur Kostenträgern können Projekte zugeordnet werden';
        this.companyCostCenterCantChangeFromCostUnitIfProjectsAdded = 'Der Kostenträger kann nicht in eine Kostenstelle geändert solange noch Projekte zugeordnet sind';
        this.companyCostCenterChangeProjects = 'Projekte zuweisen';
        this.companyCostCenterMarkOnlyAlphaNumeric = 'Das Kennzeichen darf nur Buchstaben und Zahlen beeinhalten';
        this.companyCostCenterMarkRequired = 'Das Kennzeichen wird benötigt';
        this.companyCostCenterMarkToLong = 'Das Kennzeichen ist zu lang, maximal 20 Zeichen';
        this.companyCostCenterNoDataHint = 'Drücken Sie auf den + Button, um eine neue Kostenstelle zu erstellen';
        this.companyCostCenterNoDataText = 'Keine Kostenstellen vorhanden';
        this.companyCraftAlreadyExists = 'Das Gewerk existiert bereits';
        this.companyCraftNoDataHint = 'Drücken Sie auf den + Button, um ein neues Gewerk zu erstellen';
        this.companyCraftNoDataText = 'Keine Gewerke vorhanden';
        this.companyCraftNotExists = 'Das Gewerk existiert nicht';
        this.companyEditUserDilaogTitle = 'Mitarbeiter bearbeiten';
        this.companyExcelCategoryNameHeader = 'Kategoriename';
        this.companyExcelDialogTitle = 'Excel Import';
        this.companyExcelGroupNameHeader = 'Gruppenname';
        this.companyExcelRegionCountryHeader = 'Land';
        this.companyExcelRegionNameHeader = 'Region';
        this.companyExcelTagGroupHeader = 'Taggruppe';
        this.companyExcelTagNameHeader = 'Tagname';
        this.companyExcelWorkingTimeEnd = 'Ende';
        this.companyExcelWorkingTimePause = 'Pause';
        this.companyExcelWorkingTimePostRecordingHeader = 'Tage nacherfassbar';
        this.companyExcelWorkingTimeStart = 'Start';
        this.companyExtraPayAlreadyExists = 'Eine Zulade mit dem Kennzeichen existiert bereits';
        this.companyExtraPayMarkOnlyAlphaNumeric = 'Das Kennzeichen darf nur Buchstaben und Zahlen beeinhalten';
        this.companyExtraPayMarkRequired = 'Das Kennzeichen wird benötigt';
        this.companyExtraPayMarkToLong = 'Das Kennzeichen ist zu lang, maximal 20 Zeichen';
        this.companyExtraPayNoDataHint = 'Drücken Sie auf den + Button, um eine neue Zulage zu erstellen';
        this.companyExtraPayNoDataHintTracking = 'Gehen Sie zur Zulagenverwatlung in der Firmenverwatlung und erstellen Sie Zulagen';
        this.companyExtraPayNoDataText = 'Keine Zulagen vorhanden';
        this.companyFileTypeAlreadyExists = 'Die Kategorie existiert bereits';
        this.companyFileTypeNoDataHint = 'Drücken Sie auf den + Button, um eine neue Kategorie zu erstellen';
        this.companyFileTypeNoDataText = 'Keine Kategorien vorhanden';
        this.companyFileTypeNotExists = 'Die Kategorie existiert nicht';
        this.companyGroupAlreadyExists = 'Eine Gruppe mit diesem Namen existiert bereits';
        this.companyGroupDefaultName = 'Mitarbeiter';
        this.companyGroupDoesNotExist = 'Gruppe existiert nicht';
        this.companyGroupNeedGroup = 'Nutzer können nicht aus der Standardgruppe entfernt werden';
        this.companyGroupNoChangeDefault = 'Standardgruppenname kann nicht geändert werden';
        this.companyGroupNoDataHint = 'Keine Gruppen vorhanden';
        this.companyGroupNoDataText = 'Drücken Sie auf den + Button, um eine neue Gruppe zu erstellen';
        this.companyGroupNoDeleteDefault = 'Standard Gruppe kann nicht gelöscht werden';
        this.companyGroupRequired = 'Gruppenname wird benötigt';
        this.companyGroupUserNotInCompany = 'Nutzer ist nicht vorhanden';
        this.companyGroupsEditMembers = 'Gruppenmitglieder bearbeiten';
        this.companyHasNoCompanyText = 'Sie sind aktuell keinem Firmenprofil zugeordnet. Bitte kontaktieren Sie Ihren Firmenadministrator oder nehmen Sie mit unserem Support Team Kontakt auf, um Ihr eigenes Firmenprofil zu erstellen.';
        this.companyHolidayAffiliateWithRegion = 'Feiertag Regionen zuweisen';
        this.companyHolidayAlreadyExists = 'Feiertag existiert bereits';
        this.companyHolidayDayOrHours = 'Tag oder Stunden';
        this.companyHolidayName = 'Feiertag';
        this.companyHolidayNoDataHint = 'Keine Feiertage vorhanden';
        this.companyHolidayNoDataText = 'Drücken Sie auf den + Button, um einen neuen Feiertag zu erstellen';
        this.companyHolidayWageType = 'Lohnart';
        this.companyHolidayYearly = 'Jährlich';
        this.companyId = 'Firma';
        this.companyImportHolidayHeadline = 'Feiertage importieren';
        this.companyImportHolidayRegions = 'Importierten Feiertagen Regionen zuweisen';
        this.companyImportHolidayResult = 'Es wurden {{count}} neue Feiertage gefunden';
        this.companyImportHolidayState = 'Bundesländer:';
        this.companyImportHolidayTitle = 'Importiert nicht vorhandene Feiertage für das Jahr {{first}}';
        this.companyImportHolidayWageType = 'Lohnart für importierte Feiertage';
        this.companyLicence = 'Lizenz';
        this.companyListAlreadyExists = 'Eine Liste mit dem Namen "{{name}}" existiert bereits';
        this.companyListNoDataHint = 'Drücken Sie auf den + Button, um eine neue Liste zu erstellen';
        this.companyListNoDataText = 'Keine Listen vorhanden';
        this.companyListNotExists = 'Die Liste existiert nicht';
        this.companyListTableList = 'Optionen';
        this.companyLogo = 'Firmenlogo';
        this.companyLogoDropHint = 'Als Firmenlogo hinzufügen';
        this.companyLogoInfoMessage = 'Das Logo wird in den Tagesberichten, Ticketberichten und als Profilbild angezeigt.';
        this.companyMember = 'Mitarbeiter';
        this.companyMemberCanInviteCompanyUserToProjects = 'Kann Mitarbeiter zu Projekten hinzufügen';
        this.companyProfile = 'Firmenprofil';
        this.companyProject = 'Firmenprojekte';
        this.companyProjectInfo = 'Firmen Projekteinstellungen';
        this.companyProjectStateAlreadyExists = 'Ein Status mit diesem Namen existiert bereits';
        this.companyProjectStateColorRequired = 'Es wird eine Statusfarbe benötigt';
        this.companyProjectTeamAddMeAsManagerButton = 'Mich hinzufügen';
        this.companyProjectTeamAddMeAsManagerQuestion = 'Möchten Sie sich selbst als Projektleiter hinzufügen?';
        this.companyRegionAlreadyExists = 'Region existiert bereits';
        this.companyRegionDoesNotExist = 'Region existiert nicht';
        this.companyRegionsEditMembers = 'Mitglieder zuweisen';
        this.companyRegionsNoDataHint = 'Keine Regionen vorhanden';
        this.companyRegionsNoDataText = 'Drücken Sie auf den + Button, um eine neue Region zu erstellen';
        this.companyRemoveCostCenterQuestion = 'Möchten Sie die Kostenstelle wirklich löschen?';
        this.companyRemoveCraftQuestion = 'Möchten Sie das Gewerk "{{craft}}" wirklich entfernen?';
        this.companyRemoveEnterpriseUserQuestion = 'Möchten Sie den Mitarbeiter "{{username}}" wirklich entfernen?';
        this.companyRemoveExtraPayQuestion = 'Möchten Sie die Zulage wirklich löschen?';
        this.companyRemoveFileTypeQuestion = 'Möchten Sie die Kategorie "{{fileType}}" wirklich löschen?';
        this.companyRemoveGroupQuestion = 'Möchten Sie die Gruppe "{{group}}" wirklich löschen?';
        this.companyRemoveHolidayQuestion = 'Möchten Sie den Feiertag "{{holiday.name}}" wirklich löschen?';
        this.companyRemoveListQuestion = 'Möchten Sie die Liste "{{name}}" wirklich löschen?';
        this.companyRemoveRegionQuestion = 'Möchten Sie die Region "{{region.region}} {{region.country}}" wirklich löschen?';
        this.companyRemoveReportTemplateQuestion = 'Möchten Sie die Berichtsvorlage "{{title}}" wirklich löschen?';
        this.companyRemoveTagsQuestion = 'Möchten Sie das Tag "{{tag.tagName}}" wirklich löschen?';
        this.companyRemoveTaskQuestion = 'Möchten Sie die Tätigkeit "{{task}}" wirklich löschen?';
        this.companyRemoveTicketTypeQuestion = 'Möchten Sie den Tickettypen "{{type}}" wirklich löschen?';
        this.companyRemoveUser = 'Mitarbeiter entfernen?';
        this.companyRemoveWorkingTimeModelQuestion = 'Möchten Sie das Arbeitszeitmodell "{{name}}" wirklich löschen?';
        this.companyReportTemplateAlreadyExists = 'Eine Reportvorlage mit dem Namen existiert bereits in der Firma';
        this.companyReportTemplateType = 'Berichtsart';
        this.companyRole = 'Rolle';
        this.companyRoleAdmin = 'Firmenadmin';
        this.companyRoleInactive = 'Inaktiv';
        this.companyRoleManager = 'Projektleiter';
        this.companyRoleTimetracker = 'Zeiterfasser';
        this.companyRoleUser = 'Mitarbeiter';
        this.companySettings = 'Firmeneinstellungen';
        this.companySettingsCraftsTagsTypesInformation = 'Diese Listeneinträge dienen als Vorlage für neue Firmen-Projekte. Im Projekt sind Anpassungen möglich.';
        this.companySettingsTicketstatusInformation = 'Alle veränderten Ticket-Status überschreiben die ursprünglichen Status in allen Firmenprojekten, außer in jenen Projekten, wo individuelle Anpassungen vorgenommen wurden.';
        this.companySettingsUserInformation = 'Laden Sie neue interne Nutzer zu Ihrer Firma ein, um mit Ihnen in upmesh zu arbeiten. Eingeladene Nutzer benötigen eine Lizenz Ihres Kontingents.';
        this.companyTagAlreadyExists = 'Das Tag existiert bereits';
        this.companyTagColorAlreadyUsed = 'Die Farbe wird schon von einer anderen Gruppe verwendet';
        this.companyTagNotExists = 'Das Tag existiert nicht';
        this.companyTagsNoDataHint = 'Drücken Sie auf den + Button, um ein neues Tag zu erstellen';
        this.companyTagsNoDataText = 'Keine Tags vorhanden';
        this.companyTaskAlreadyExists = 'Eine Tätigkeit mit dem Kennzeichen existiert bereits';
        this.companyTaskCantDeleteUsed = 'Die Tätigkeit kann nicht gelöscht werden, wenn es in der Zeiterfassung verwendet wurde';
        this.companyTaskNoDataHint = 'Drücken Sie auf den + Button, um eine neue Tätigkeit zu erstellen';
        this.companyTaskNoDataText = 'Keine Tätigkeiten vorhanden';
        this.companyTemplatesForProjects = 'Vorlagen für Projekte';
        this.companyTicketLayoutAlreadyExists = 'Das Ticketlayout existiert bereits in der Firma';
        this.companyTicketTypeAlreadyExists = 'Der Tickettyp existiert bereits';
        this.companyTicketTypeNotExists = 'Der Tickettyp existiert nicht';
        this.companyTypeNoDataHint = 'Drücken Sie auf den + Button, um einen neuen Tickttypen zu erstellen';
        this.companyTypeNoDataText = 'Keine Tickettypen vorhanden';
        this.companyUserCanAbsenceFor = 'Abwesenheiten erfassen für';
        this.companyUserCanAbsenceForAll = 'Alle';
        this.companyUserCanAbsenceForGroup = 'Gruppenmitglieder';
        this.companyUserCanAbsenceForNobody = 'Keinen';
        this.companyUserCanAbsenceForSelf = 'Nur sich selbst';
        this.companyUserCanAbsenceFrom = 'Abwesenheiten einsehen für';
        this.companyUserCanAbsenceFromAll = 'Alle';
        this.companyUserCanAbsenceFromGroup = 'Gruppenmitglieder';
        this.companyUserCanAbsenceFromSelf = 'Nur sich selbst';
        this.companyUserCanLockTimeTrackingFrom = 'Zeiten sperren von';
        this.companyUserCanLockTimeTrackingFromAll = 'Allen';
        this.companyUserCanLockTimeTrackingFromGroup = 'Gruppe';
        this.companyUserCanLockTimeTrackingFromNobody = 'Keinem';
        this.companyUserCanPermitAbsenceFor = 'Abwesenheiten genehmigen für';
        this.companyUserCanTimeTrackFor = 'Zeiten erfassen für';
        this.companyUserCanTimeTrackForAll = 'Alle';
        this.companyUserCanTimeTrackForGroup = 'Gruppenmitglieder';
        this.companyUserCanTimeTrackForSelf = 'Nur sich selbst';
        this.companyUserCanTimeTrackFrom = 'Zeiten sehen von';
        this.companyUserCanTimeTrackFromAll = 'Allen';
        this.companyUserCanTimeTrackFromGroup = 'Gruppenmitgliedern';
        this.companyUserCanTimeTrackFromSelf = 'Nur sich selbst';
        this.companyUserForceDowngrade = 'Rolle ändern';
        this.companyUserForceRemove = 'Entfernen';
        this.companyUserLicenseCollaborator = 'Mitarbeiterlizenz';
        this.companyUserLicenseFull = 'Projektleiterlizenz';
        this.companyUserLicenseTimeTracking = 'Zeiterfassungslizenz';
        this.companyUserLicensesCollaborator = 'Mitarbeiterlizenzen';
        this.companyUserLicensesFull = 'Projektleiterlizenzen';
        this.companyUserLicensesTimeTracking = 'Zeiterfassungslizenzen';
        this.companyWorkingTimeModelAlreadyExists = 'Ein Arbeitszeitmodel mit dem Kennzeichen existiert bereits';
        this.companyWorkingTimeModelSetAsDefaultQuestion = 'Möchten Sie das Arbeitszeitmodell "{{name}}" als Standard setzen?';
        this.companylistsTitleCreateNew = 'Neue Liste erstellen';
        this.companylistsTitleEdit = 'Liste "{{name}}" bearbeiten';
        this.companys = 'Firmen';
        this.conflict = 'conflict';
        this.constructionSite = 'Baustelle';
        this.contact = 'Kontakt';
        this.contactPersons = 'Ansprechpartner';
        this.copmanyReportTemplateNoDataHint = 'Drücken Sie auf den + Button, um eine neue Berichtsvorlage zu erstellen';
        this.copmanyReportTemplateNoDataText = 'Keine Berichtsvorlagen vorhanden';
        this.copy = 'Kopieren';
        this.costCenter = 'Kostenstelle';
        this.costCenterCostType = 'Kostenträger?';
        this.costCenterDescription = 'Bezeichnung';
        this.costCenterHasAbsencesItems = 'Die Kostenstelle hat erfasste Abwesenheiten und kann deswegen nicht gelöscht werden';
        this.costCenterHasExtraPayTrackedItems = 'Die Kostenstelle hat erfasste Zulagen und kann deswegen nicht gelöscht werden';
        this.costCenterHasTimeTrackedItems = 'Die Kostenstelle hat erfasste Zeiten und kann deswegen nicht gelöscht werden';
        this.costCenterId = 'Kostenstelle';
        this.costCenterIdNotFound = 'Die Kostenstelle wurde nicht gefunden';
        this.costCenterIsCostUnit = 'Ist Kostenträger';
        this.costCenterMark = 'Kennzeichen';
        this.costCenterMultipleProjectsError = 'Der Kostenstelle sind mehrere Projekte zugewiesen';
        this.costCenterNoProjectError = 'Der Kostenstelle ist kein Projekt zugewiesen';
        this.costCenterProjectWithoutCenterError = 'Dem Projekt ist keine Kostenstelle zugewiesen';
        this.costCenterType = 'Kostenträger?';
        this.costCenters = 'Kostenstellen';
        this.costTypeTypeError = 'costType muss entweder costCenter oder costUnit sein';
        this.costUnit = 'Kostenträger';
        this.costUnitNeedsProject = 'Wenn ein Kostenträger gewählt ist, wird die Projekt id benötigt';
        this.costUnits = 'Kostenträger';
        this.craft = 'Gewerk';
        this.craftCablework = 'Kabelleitungstiefbau';
        this.craftCarpenter = 'Zimmerer';
        this.craftCommunication = 'Kommunikation';
        this.craftConcrete = 'Beton';
        this.craftDemolitionWork = 'Abbruch';
        this.craftDoors = 'Türen';
        this.craftDrywall = 'Trockenbau';
        this.craftEarthWork = 'Erdbau';
        this.craftElectrical = 'Elektro';
        this.craftFacade = 'Fassade';
        this.craftFinishCarpenter = 'Tischler';
        this.craftFlooring = 'Boden';
        this.craftHVAC = 'HLS';
        this.craftLandscaping = 'Landschaftsbau';
        this.craftMason = 'Maurer';
        this.craftMetalWork = 'Metallbau';
        this.craftPaint = 'Maler';
        this.craftPlumbing = 'Klempner';
        this.craftRoofing = 'Dachdecker';
        this.craftSpecialHeavyConstruction = 'Spezialtiefbau';
        this.craftStaging = 'Gerüstbau';
        this.craftSteelwork = 'Stahlbau';
        this.craftTiles = 'Fliesen';
        this.craftWindows = 'Fenster';
        this.crafts = 'Gewerke';
        this.create = 'Erstellen';
        this.createFolder = 'Ordner erstellen';
        this.createPlanVersion = 'Plan "{{planTitle}}" wird erstellt';
        this.createProjectAddCostUnit = 'Bestehender';
        this.createProjectAddCostUnitLabel = 'Bestehenden wählen';
        this.createProjectFromTemplateFinish = 'Ihr Projekt wurde erstellt';
        this.createProjectFromTemplateHint = 'Ihr Projekt wird erstellt';
        this.createProjectNewCostUnit = 'Neu';
        this.createProjectNoCostUnit = 'Keiner';
        this.createSubfolder = 'Unterordner erstellen';
        this.created = 'Erstellt';
        this.createdAt = 'Erstellt';
        this.creator = 'Ersteller';
        this.customFieldCheckboxList = 'Checkliste';
        this.customFieldDate = 'Datum';
        this.customFieldDateRange = 'Datumsbereich';
        this.customFieldDivider = 'Trennlinie';
        this.customFieldList = 'Liste';
        this.customFieldMultiPerson = 'Person (Mehrfachauswahl)';
        this.customFieldMultiselectList = 'Liste (Mehrfachauswahl)';
        this.customFieldNumber = 'Zahl';
        this.customFieldPerson = 'Person';
        this.customFieldProgress = 'Fortschritt';
        this.customFieldText = 'Text';
        this.customFields = 'Benutzerdefinierte Felder';
        this.dashboard = 'Dashboard';
        this.dashboardChartItemApprovers = 'Prüfer';
        this.dashboardChartItemCraft = 'Gewerk';
        this.dashboardChartItemOther = 'Sonstige';
        this.dashboardChartItemPercentage = 'Prozentsatz';
        this.dashboardChartItemQuantity = 'Anzahl';
        this.dashboardChartItemShowPercentage = 'Prozentsatz zeigen';
        this.dashboardChartItemTicketOverview = 'Ticketübersicht';
        this.dashboardChartItemType = 'Typ';
        this.dashboardFocusItemAllAssignees = 'Alle Zugewiesenen';
        this.dashboardFocusItemNoTickets = 'Es sind keine Tickets in diesem Zeitraum fällig';
        this.dashboardFocusTicketsItem = 'Fokustickets';
        this.dashboardFocusTicketsItemAssignedTo = 'Zugewiesen an';
        this.dashboardFocusTicketsItemDueDate = 'Fälligkeitsdatum';
        this.dashboardFocusTicketsItemDueInAMonth = 'In einem Monat fällig';
        this.dashboardFocusTicketsItemDueInAWeek = 'In einer Woche fällig';
        this.dashboardFocusTicketsItemDueInTwoWeeks = 'In zwei Wochen fällig';
        this.dashboardFocusTicketsItemDueToday = 'Heute fällig';
        this.dashboardFocusTicketsItemDueTomorrow = 'Morgen fällig';
        this.dashboardFocusTicketsItemLastChanged = 'Zuletzt geändert';
        this.dashboardFocusTicketsItemOverdue = 'Überfällig';
        this.dashboardFocusTicketsItemSortBy = 'Sortieren nach';
        this.dashboardFocusTicketsItemTitle = 'Titel';
        this.dashboardGanttChart = 'Gantt-Chart';
        this.dashboardGanttChartDateRange = 'Datumsbereich';
        this.dashboardGanttChartTimeScale = 'Zeitskala';
        this.dashboardGanttChartTimeSortBy = 'Sortieren nach';
        this.dashboardItemsAssignee = 'Zugewiesener';
        this.dashboardItemsStatus = 'Status';
        this.dashboardNotes = 'Persönliche Notizen';
        this.dashboardTimeType = 'genereller Zeitraum';
        this.date = 'Datum';
        this.datevExportPersNrInvalid = 'Die PersNr {{error}} ist ungültig für einen LODAS-Export. (maximal 5 Ziffern)';
        this.datevExportWageTypeInvalid = 'Die Lohnart "{{error}}" ist ungültig für einen LODAS-Export. (maximal 4 Ziffern)';
        this.datevKostenstellebwdTooLong = 'Die kostenstelle#bwd oder kostentraeger#bwd "{{error}}", waren länger als die von LODAS vorgegebenen 13 Zeichen.';
        this.datevLohnUndGehaltAusfallInvalid = 'Der Ausfallschlüssel "{{error}}" ist zu lang. (maximal 2 Stellen)';
        this.datevLohnUndGehaltKostenStelleInvalid = 'Der Kostenträger/Kostenstelle "{{error}}" ist zu lang. (maximal 8 Stellen)';
        this.datevLohnUndGehaltPersNumberInvalid = 'Die Personalnummer "{{error}}" ist ungültig für einen Datev-Export. (maximal 5 Ziffern)';
        this.datevLohnUndGehaltWageTypeInvalid = 'Die Lohnart "{{error}}" ist ungültig. Nummer einer Lohnart, 4-stellig numerisch, Wertebereich 1 bis 5999 und' +
            ' 8000 bis 9999.';
        this.day = 'Tag';
        this.dayBeforeYesterday = 'Vorgestern';
        this.days = 'Tage';
        this.dbError = 'Datenbankfehler';
        this.default = 'Standard';
        this.defaultErrorTitleOnSave = 'Die Änderung konnte nicht gespeichert werden';
        this.defaultFields = 'Standardfelder';
        this.delete = 'Löschen';
        this.deleteAccount = 'Konto löschen';
        this.deleteAccountDialogConfirmaionText = 'Löschen bestätigen';
        this.deleteAccountDialogSuccessText = 'Schade, dass Sie gehen. Wir werden die Löschung Ihres Kontos in 5 Tagen vornehmen. Solange haben Sie noch Zeit' +
            ' die Löschung zu verhindern und Ihr Konto zu reaktivieren.';
        this.deleteAccountDialogSuccessTitle = 'Löschung erfolgreich beantragt';
        this.deleteAccountDialogText = 'Sobald Sie Ihr Konto löschen, werden alle Ihre Daten dauerhaft gelöscht. Sie verlieren den' +
            ' Zugang zu allen Projekten';
        this.deleteAccountDialogTitle = 'upmesh Konto wirklich löschen?';
        this.deleteAccountErrorHasCompanySub = 'Sie haben durch Ihre Firma ein laufendes Abonnement und können ihr Konto deshalb' +
            ' nicht löschen. Für mehr Informationen kontaktieren Sie bitte unseren Support.';
        this.deleteAccountErrorHasSubscription = 'Sie können Ihr Konto nicht während eines laufenden Abonnements löschen. Für mehr Informationen kontaktieren ' +
            'Sie bitte unseren Support.';
        this.deleteAccountErrorStillInProjects = 'Damit Sie Ihr Konto löschen können, müssen Sie zuerst alle Projekte, in denen' +
            ' Sie der Projekteigentümer sind, löschen oder die Inhaberschaft an eine weitere Person übertragen und Projekte,' +
            ' in denen Sie Mitglied sind, verlassen.';
        this.deleteAll = 'Alle löschen';
        this.deleteFiles = 'Datei(en) löschen';
        this.deletePdf = 'PDF-Dokument löschen';
        this.deletePlan = 'Plan löschen';
        this.deleteRuleMessage = 'Soll die Regel wirklich gelöscht werden?';
        this.deleteTicket = 'Ticket löschen';
        this.deleteTicketDeleted = 'Gelöscht';
        this.deleteTicketsDialogHeadline = 'Tickets löschen';
        this.deleteTicketsQuestion = 'Sollen gelöschte Tickets wiederhergestellt und offene gelöscht werden?\nGelöschte Tickets können' +
            ' jederzeit über die Filterfunktion eingesehen und wieder hergestellt werden';
        this.deleteVersion = 'Version löschen';
        this.deleted = 'gelöscht';
        this.deletedProject = 'Gelöschtes Projekt';
        this.discard = 'Verwerfen';
        this.discardChanges = 'Änderungen verwerfen';
        this.distribution = 'Vertrieb';
        this.documents = 'Dokumente';
        this.downgrade = 'downgrade';
        this.download = 'herunterladen';
        this.downloadLinkError = 'Fehler beim Downloaden der Datei';
        this.downloadPlan = 'Plan herunterladen';
        this.downloadPlanWithTickets = 'Plan mit Tickets herunterladen';
        this.dropNewFieldHere = 'Neues Feld hier ablegen';
        this.eMailNotFound = 'E-Mail-Adresse nicht gefunden';
        this.edit = 'Bearbeiten';
        this.editPlan = 'Plan bearbeiten';
        this.editProject = 'Projekt bearbeiten';
        this.editTags = 'Tags bearbeiten';
        this.email = 'E-Mail-Adresse';
        this.emailNotification = 'E-Mail Benachrichtigungen';
        this.emailValidateError = 'Ihre E-Mail-Adresse konnte nicht verifiziert werden';
        this.emailValidateErrorNoEmail = 'Ihre E-Mail-Adresse konnte nicht verifiziert werden, E-Mail nicht gefunden';
        this.emailValidateErrorNoSuchCode = 'Ihre E-Mail-Adresse konnte nicht verifiziert werden, Code ungültig';
        this.emailValidateErrorTitle = 'E-Mail-Adresse verifizieren';
        this.emailValidateErrorTokenInvalid = 'Ihre E-Mail-Adresse konnte nicht verifiziert werden, Code ungültig';
        this.emailValidateSend = 'Es wurde eine neue Verifizierungsmail versendet.';
        this.emailValidateSuccess = 'Ihre E-Mail-Adresse wurde erfolgreich verifiziert';
        this.emailValidateTitle = 'E-Mail Verifizierung';
        this.emailValidateTokenExpired = 'Ihre E-Mail-Adresse konnte nicht verifiziert' +
            ' werden, der Code ist abgelaufen. Es wurde eine neue Verifizierungsmail versendet.';
        this.emailValidationError = 'Keine gültige E-Mail-Adresse';
        this.employee = 'Mitarbeiter';
        this.ended = 'beendet';
        this.ends = 'Ende';
        this.enterProject = 'Projekt öffnen';
        this.enterpriseProject = 'Firmen-Projekt';
        this.error = 'Fehler';
        this.exportAsCSV = 'Export als CSV';
        this.exportTimeTrackingEntries = 'Einträge exportieren';
        this.extraField = 'Wert';
        this.extraOnlyOnceADay = 'Die Zulage kann nur ein mal pro Tag angelegt werden';
        this.extraPay = 'Zulage';
        this.extraPayAddTrack = 'Zulage erfassen';
        this.extraPayChangeTrack = 'Zulage ändern';
        this.extraPayDescription = 'Bezeichnung';
        this.extraPayFieldType = 'Zusatzangabe';
        this.extraPayFieldTypeDuration = 'Zeitspanne';
        this.extraPayFieldTypeNone = 'Keine';
        this.extraPayFieldTypeNumber = 'Menge';
        this.extraPayFieldTypeNumberIn = 'Menge in {{unit}}';
        this.extraPayFieldTypeNumberMax = 'Maximum';
        this.extraPayFieldTypeNumberMin = 'Minimum';
        this.extraPayFieldTypeNumberUnit = 'Einheit';
        this.extraPayFieldValue = 'Wert';
        this.extraPayHasTrackedItems = 'Die Zulage hat erfasste Zulagen und kann deswegen nicht gelöscht werden';
        this.extraPayId = 'Zulage';
        this.extraPayMark = 'Kennzeichen';
        this.extraPayOnceAday = 'Einmalig pro Tag';
        this.extraPayTracking = 'Zulagen';
        this.extraPayWageType = 'Lohnart';
        this.extraPays = 'Zulagen';
        this.failed = 'fehlgeschlagen';
        this.fileDeleteQuestion = 'Möchten Sie die Datei wirklich löschen?';
        this.fileIsCopying = 'Datei {{ file }} wird dupliziert';
        this.filePreparingDownload = 'Download wird vorbereitet';
        this.fileReadyToDownload = 'Datei bereit zum herunterladen';
        this.fileTypes = 'Kategorien';
        this.files = 'Dateien';
        this.filesAddTitle = 'Ziel auswählen';
        this.filesAddTitlePlans = 'Plan auswählen';
        this.filesAddTitleProjects = 'Projekt auswählen';
        this.filesAddTitleTickets = 'Ticket auswählen';
        this.filesAll = 'Alle';
        this.filesChangeCommentTypeTooltip = 'Kategorie und Kommentar bearbeiten';
        this.filesChangeFileTypeDialog = 'Kategorie und Kommentar ändern';
        this.filesChangeToNoType = 'Keine Kategorie';
        this.filesCommentHeadline = 'Kommentar';
        this.filesCreateChange = 'Datei bearbeitet/geändert';
        this.filesDeleteQuestion = 'Wollen Sie die ausgewählten Dateien wirklich löschen?';
        this.filesDeleteQuestionAction = 'löschen';
        this.filesDeleteQuestionAllVersions = 'Die Datei hat mehrere Versionen. Wollen Sie nur die aktuelle Version löschen oder alle Versionen?';
        this.filesDeleted = 'gelöscht';
        this.filesDownload = 'Herunterladen';
        this.filesEditPdfEnterpriseFunctionDescription = 'Bearbeiten Sie PDF Dateien, schreiben Sie Anmerkungen, setzen Lesezeichen und mehr. Jetzt upgraden und auf' +
            ' noch weitere Funktionen sowie einen erweiterten Dateispeicher freuen.';
        this.filesEditPdfEnterpriseFunctionHeadline = 'PDF Bearbeitung ist eine Enterprise Funktion';
        this.filesFileSize = 'Dateigröße';
        this.filesFileTypeHeadline = 'Kategorie';
        this.filesFolderPrivate = 'Privat';
        this.filesFolderPublic = 'Projektablage';
        this.filesPathTitle = 'Pfad';
        this.filesRename = 'Umbenennen';
        this.filesRenameHeader = 'Datei Umbenennen';
        this.filesRenameNewFileName = 'Neuer Dateiname';
        this.filesSaveNotPossible = 'Speichern nicht möglich';
        this.filesSaveNotPossibleCreator = 'Sie sind nicht der Autor der Datei.';
        this.filesSaveNotPossibleFolder = 'Sie haben keine Bearbeitungsrechte in diesem Ordner.';
        this.filesSaveNotPossibleOffline = 'Sie sind offline. Stellen Sie die Internetverbindung wieder her oder speichern Sie eine Kopie der Datei.';
        this.filesSaveNotPossibleTime = 'Der erlaubte Bearbeitungszeitraum ist abgelaufen. Ändern Sie diesen in den Projekteinstellungen oder speichern Sie die Datei als Kopie.';
        this.filesTicketTitle = 'Ticket Titel';
        this.filesTitle = 'Name';
        this.filesUploadDate = 'Hochgeladen am';
        this.filesUploadDescription = 'Dateien für die Projektdokumentation';
        this.filesUploadDescriptionUploadButton = 'Keine Dateien vorhanden';
        this.filesUploadHint = 'Fügen Sie neue Dateien mit dem + Button hinzu, um sie später an Tickets anzuhängen.';
        this.filesUploadHintReports = 'Hier finden Sie alle bisher erstellten Tagesberichte.';
        this.filesUploadHintTickets = 'Hier finden Sie alle Dateien, die zu einem Ticket hinzugefügt worden sind.';
        this.filesUploadHintUploadButton = 'Fügen Sie neue Dateien in der Dateiverwaltung hinzu, um sie hier zu verwenden';
        this.filesUploadTextButton = 'Dateien auswählen';
        this.filesUploadTitle = 'Dateien hochladen';
        this.filesUploader = 'Hochgeladen von';
        this.filesVersions = 'Versionen';
        this.filesVersionsManage = 'Versionen verwalten';
        this.filterRemove = 'Filter entfernen';
        this.firstName = 'Vorname';
        this.folder = 'Ordner';
        this.folderAccess = 'Zugriffsrechte';
        this.folderAccessAddToSubFolder = '+ neue Rechte zu Unterordnern hinzufügen';
        this.folderAccessAll = 'Zugriff einschränken';
        this.folderAccessAllHelperText = 'Ohne Einschränkung haben alle Vollzugriff.';
        this.folderAccessOnlyFolder = 'Rechte nur für diesen Ordner';
        this.folderAccessOverwriteSubFolder = '+ Rechte für Unterordner überschreiben';
        this.folderCreateChange = 'Ordner bearbeitet/geändert';
        this.folderDeleteQuestion = 'Möchten Sie den Ordner wirklich löschen?';
        this.folderDescription = 'Beschreibung';
        this.folderName = 'Ordnername';
        this.folderNameRequired = 'Ordnername wird benötigt';
        this.folderRoleOrUser = 'Rolle oder Benutzer';
        this.folderRoleOrUserChangeTitle = 'Benutzer oder Rolle hinzufügen';
        this.folderTakeOverRights = 'Rechte für Unterordner übernehmen?';
        this.folderWithNameAlreadyExists = 'Ein Ordner mit dem Namen "{{title}}" existiert bereits im Projekt';
        this.foldersAndFilesUploadHint = 'Ordner inklusive Unterordner und Dateien hier ablegen';
        this.forbidden = 'Keine Berechtigung';
        this.forbiddenCommand = 'Sie haben keine Berechtigung, um die Aktion auszuführen';
        this.forgotPwCodeCodeTitle = 'Neues Passwort setzen';
        this.forgotPwCodeSubmit = 'Passwort setzen';
        this.forgotPwErrorNoSuchAccount = 'Account mit E-Mail-Adresse nicht gefunden';
        this.forgotPwRegisterNow = 'Jetzt registrieren!';
        this.forgotPwRequestCode = 'Code anfordern';
        this.forgotPwSubmit = 'zurücksetzen';
        this.forgotPwSuccess = 'Sie sollten innerhalb der nächsten Minuten eine E-Mail mit einem Link zur Passwort Wiederherstellung erhalten';
        this.forgotPwText = 'Geben Sie Ihre E-Mail-Adresse ein. Sie erhalten einen Link per E-Mail, um ein neues Passwort zu setzen.';
        this.forgotPwTitle = 'Passwort zurücksetzen';
        this.fromCamera = 'Kamera';
        this.gdiExportCostCenterInvalid = 'Die Kostenstelle "{{error}}" ist ungültig für einen GDI-Export. (maximal 12 Ziffern)';
        this.gdiExportCostUnitInvalid = 'Der Kostenträger "{{error}}" ist ungültig für einen GDI-Export. (maximal 12 Stellen)';
        this.gdiExportWageTypeInvalid = 'Die Lohnartennummer "{{error}}" ist ungültig für einen GDI-Export. (muss im Bereich 1-9899 liegen)';
        this.gdiExportWorkTypeInvalid = 'Das Tätigkeitskennzeichen "{{error}}" ist ungültig für einen GDI-Export. (maximal 2 Stellen)';
        this.globalSearch = 'Suche (Strg/⌘ + k)';
        this.globalSearchLastRequests = 'Letze Suchanfragen';
        this.globalSearchMobile = 'Suche';
        this.goToExtraPayAdmin = 'zur Zulagenverwaltung';
        this.goToHome = 'zur Startseite';
        this.group = 'Gruppe';
        this.groupSetDefault = 'Standardgruppe setzen';
        this.groups = 'Gruppen';
        this.hideMap = 'Karte verbergen';
        this.higherClientVersionNeeded = 'Es wird eine höhere Client Version für die Verbindung benötigt';
        this.holiday = 'Feiertage';
        this.holidays = 'Feiertage';
        this.home = 'Startseite';
        this.homeFirstStepsCreatProjectButton = 'Projekt erstellen';
        this.hours = 'Stunden';
        this.id = 'ID';
        this.imageNotFound = 'Bild nicht verfügbar';
        this.images = 'Bilder';
        this.importExcelDialogHeader = 'Tickets importieren';
        this.importExcelDuplicatesIgnore = 'Tickets überspringen';
        this.importExcelDuplicatesIgnoreDescription = 'Kein Ticket erstellen, falls die Daten aus der Excel mit dieser Spalte übereinstimmen:';
        this.importExcelDuplicatesNew = 'Neue Tickets erstellen';
        this.importExcelDuplicatesNewDescription = 'Alle Excel Elemente als neue Tickets erstellen.';
        this.importExcelDuplicatesUpdate = 'Tickets aktualisieren';
        this.importExcelDuplicatesUpdateDescription = 'Ticket Inhalte überschreiben, falls die Daten aus Excel mit dieser Spalte übereinstimmen:';
        this.importExcelError = 'Es sind Fehler beim Importieren der Tickets aufgetreten';
        this.importExcelErrorAtLine = 'Fehler in Zeile:';
        this.importExcelHandleDuplicatesHeader = 'Wie soll mit Duplikaten umgegangen werden?';
        this.importExcelImport = 'importieren';
        this.importExcelNoAssignment = 'Keine Zuweisung';
        this.importExcelNoValue = 'Kein Wert';
        this.importExcelSelectFile = 'Datei auswählen';
        this.importExcelSelectFileChange = 'Datei ändern';
        this.importExcelSuccess = 'Alle Tickets wurden erfolgreich erstellt';
        this.importExcelTableHeaderExcel = 'Excel-Spalten';
        this.importExcelTableHeaderForm = 'Formular Attribute';
        this.importExcelTableHeaderImport = 'Importierte Attribute';
        this.importExcelTableHeaderPreview = 'Vorschau';
        this.importExcelTableHeaderTicket = 'Ticketfelder';
        this.importExcelToolTip = 'Excel-Import';
        this.importExcelTypeLabel = 'Ticket typ auswählen';
        this.importList = 'Liste importieren';
        this.initDatabase = 'Initialisiere Datenbank';
        this.internalServer = 'Interner Serverfehler';
        this.invalidDate = 'Ungültiges Datum';
        this.invitationAccept = 'Annehmen';
        this.invitationAcceptedLoaded = 'Das Projekt ist jetzt verfügbar!';
        this.invitationAcceptedLoadedButton = 'Zum Projekt';
        this.invitationAcceptedLoading = 'Das Projekt wird im Hintergrund vorbereitet...';
        this.invitationAlreadyAccepted = 'Einladung bereits akzeptiert';
        this.invitationAlreadyIn = 'Dieser Benutzer ist bereits im Team';
        this.invitationMessage = '{{addedByUserName}} hat Sie zum Projekt {{currentProjectTitle}} eingeladen';
        this.invitationReject = 'Ablehnen';
        this.inviteTeamMember = 'Teammitglied einladen';
        this.journal = 'Tagesbericht';
        this.journalAddAttachment = 'Anhänge hinzufügen';
        this.journalAddDateInFutureError = ' Das Datum sollte nicht in der Zukunft liegen';
        this.journalAddNewReceiverMail = '{{email}} hinzufügen';
        this.journalAttachments = 'Anhänge';
        this.journalAttendanceAdd = 'Nachunternehmer hinzufügen';
        this.journalAttendanceArrival = 'Ankunft';
        this.journalAttendanceCompany = 'Firma';
        this.journalAttendanceCompanynameMissing = 'Der Firmenname fehlt bei dem Nachunternehmer-Eintrag {{index}}';
        this.journalAttendanceDeparture = 'Abfahrt';
        this.journalAttendanceEmployeeCount = 'Anzahl Mitarbeiter';
        this.journalAttendanceEmployeeCountToLow = 'Bei dem Nachunternehmer-Eintrag {{index}} muss die Anzahl der Mitarbeiter mindestens 1 betragen';
        this.journalAttendanceFreeText = 'Freitext';
        this.journalAttendances = 'Arbeitszeiten Nachunternehmer';
        this.journalAttendancesNoData = 'Keine Anwesenheitsdaten';
        this.journalCardsThisWeek = 'Diese Woche';
        this.journalCreate = 'Bericht erstellen';
        this.journalCreateVersion = 'Versionieren';
        this.journalCreateVersionQuestion = 'Möchten Sie eine neue Version des Tagesberichtes erstellen? Das generierte pdf kann danach nicht mehr geändert werden.';
        this.journalDamage = 'Schadensmeldungen';
        this.journalDateHasNoJournal = 'Es gibt keinen Tagesbericht für dieses Datum';
        this.journalDatepickerLabel = 'Berichtszeitpunkt';
        this.journalDeleteEntry = 'löschen';
        this.journalDialogHeader = 'Tagesbericht {{date}}';
        this.journalEmployee = 'Arbeitszeiten eigene Mitarbeiter';
        this.journalEmployeeAdd = 'Mitarbeiter/Gruppe hinzufügen';
        this.journalEmployeeGroupCountToLow = 'Bei der Mitarbeitergruppe {{index}} muss die Anzahl der Mitarbeiter mindestens 1 betragen';
        this.journalEmployeeGroupName = 'Der Mitarbeiter-Gruppenname fehlt bei dem Eintrag {{index}}';
        this.journalEmployeeName = 'Name';
        this.journalEmployeeNameMissing = 'Der Mitarbeitername fehlt bei dem Eintrag {{index}}';
        this.journalEmployeeNoData = 'Keine Anwesenheitsdaten';
        this.journalEmployees = 'Arbeitszeiten eigene Mitarbeiter';
        this.journalEmployeesEmployees = 'Mitarbeiter';
        this.journalEmployeesGroup = 'Gruppe';
        this.journalEmployeesHours = 'Stunden';
        this.journalEmployeesHoursAll = 'Stunden [Gesamt]';
        this.journalEmployeesHoursPerEmployee = 'Stunden pro Mitarbeiter';
        this.journalEmployeesJob = 'Tätigkeit';
        this.journalFreeText = 'Freitext';
        this.journalFrom = 'Tagesbericht vom';
        this.journalGetAsPDF = 'PDF Vorschau';
        this.journalHeadline = 'Tagesbericht';
        this.journalImageAddText = 'Bilder hinzufügen';
        this.journalImagePerLine = 'Bilder pro Zeile';
        this.journalImageQuality = 'Fotoauflösung';
        this.journalImageText = 'Bilder';
        this.journalImport = 'Importieren';
        this.journalImportFromOthers = 'Import aus vorherigem Bericht';
        this.journalImportFromTimetracking = 'Aus Zeiterfassung importieren';
        this.journalImportFromTimetrackingButton = 'Importieren';
        this.journalImportFromTimetrackingQuestion = 'Möchten Sie die erfassten Einträge Ihrer Mitarbeiter importieren?';
        this.journalImportFromTimetrackingResult = 'Es wurden {{inserts}} Einträge importiert und {{updates}} Einträge aktualisiert';
        this.journalImportFromWeather = 'Import Wetter';
        this.journalImportFromWeatherAddAddress = 'Möchten Sie jetzt eine Adresse eintragen?';
        this.journalImportFromWeatherAddressNeeded = 'Es wird eine Projektadresse benötigt';
        this.journalImportFromWeatherEndTime = 'Endzeit';
        this.journalImportFromWeatherResult = 'Es wurden {{count}} Wetterdaten importiert';
        this.journalImportFromWeatherStartTime = 'Startzeit';
        this.journalMultiDownload = 'Tagesberichte speichern';
        this.journalMultiDownloadDefaultFilename = 'Tagesberichte_{{from}}_bis_{{to}}';
        this.journalMultiDownloadDownlaod = 'Speichern';
        this.journalMultiDownloadFileName = 'Dateiname';
        this.journalMultiDownloadFinished = 'Gespeichert als {{name}}';
        this.journalMultiDownloadLastMonth = 'Letzter Monat';
        this.journalMultiDownloadLastWeek = 'Letzte Woche';
        this.journalMultiDownloadNoJournalsFound = 'Keine Tagesberichte gefunden';
        this.journalMultiDownloadSelectRange = 'Zeitraum';
        this.journalMultiDownloadShowInFolder = 'In Ordner anzeigen';
        this.journalMultiDownloadThisMonth = 'Dieser Monat';
        this.journalMultiDownloadThisWeek = 'Diese Woche';
        this.journalNoReport = 'Es wurde bisher kein Tagesbericht für den {{date}} erstellt.';
        this.journalNoReportCreateNew = 'Erstellen Sie hier einen neuen Bericht.';
        this.journalNoReportFuture = 'Sie können nur an Berichte vom aktuellen und vergangenen Tagen erstellen.';
        this.journalNoTicketsChangedToday = 'Keine geänderten Tickets';
        this.journalProtocol = 'Tagesberichte';
        this.journalReceiverSearchOrAddNew = 'Suchen oder E-Mail hinzufügen';
        this.journalRecipient = 'Empfänger';
        this.journalSave = 'Entwurf speichern';
        this.journalSearchJournals = 'Tagesberichte suchen';
        this.journalSend = 'senden';
        this.journalSendCancel = 'Nein, danke';
        this.journalSendNow = 'versenden';
        this.journalSendQuestion = 'Möchten Sie den Tagesbericht an folgende Empfänger versenden?';
        this.journalSendTitle = 'Tagesbericht versenden';
        this.journalSender = 'Versender';
        this.journalSettings = 'Berichtseinstellungen';
        this.journalSettingsAdditionalField = 'Feld hinzufügen';
        this.journalSettingsAdditionalFieldName = 'Name';
        this.journalSettingsBuilderContent = 'Angezeigter Bauherr';
        this.journalSettingsBuilderContentHelp = 'Template Variable: {builder}: Bauherr';
        this.journalSettingsBuilderHeader = 'Bauherr';
        this.journalSettingsCompanyName = 'Angezeigter Firmenname';
        this.journalSettingsCustomFieldsHeader = 'Zusätzliche Felder';
        this.journalSettingsFieldActivate = 'Einblenden';
        this.journalSettingsFieldDeactivate = 'Ausblenden';
        this.journalSettingsFilename = 'Dateiname';
        this.journalSettingsFilenameHelp = 'Template Variablen: {forDay}: Datum, {project}: Projekttitel';
        this.journalSettingsHeadlineText = 'Abschnittüberschriften überschreiben';
        this.journalSettingsHeadlineTextHelper = 'Neu definierte Überschriften werden nur im Ausdruck angezeigt.';
        this.journalSettingsLogo = 'Berichtslogo';
        this.journalSettingsLogoButton = 'Berichtslogo auswählen';
        this.journalSettingsTemplateDefault = 'Standardvorlage';
        this.journalSettingsTemplateHeader = 'Vorlage';
        this.journalSettingsTemplateLabel = 'Genutzte Vorlage';
        this.journalSign = 'Signieren';
        this.journalSignDesignation = 'Bezeichnung';
        this.journalSignJournal = 'Tagesbericht signieren';
        this.journalSignPlace = 'Ort';
        this.journalSpecialOccurrences = 'Besondere Vorkommnisse';
        this.journalSpecialOccurrencesNone = 'Keine';
        this.journalSpecialOccurrencesPlaceholder = 'Behinderungen / Schadensmeldung / Begehungen / Abnahmen';
        this.journalSpecifics = 'Besonderheiten';
        this.journalTableNoDataHint = 'Drücken Sie auf den + Button, um einen neuen Tagesbericht zu erstellen';
        this.journalTableNoDataText = 'Kein Tagesbericht vorhanden';
        this.journalTableVersions = 'Versionen';
        this.journalTicketReport = 'Ticketbericht';
        this.journalTicketReportFilter = 'Ticketfilter setzen';
        this.journalTicketReportInclude = 'Ticketbericht einbinden';
        this.journalTicketReportPreview = 'Ticketbericht Vorschau';
        this.journalTicketReportSettings = 'Ticketbericht Einstellungen';
        this.journalVersions = 'Bisherige Versionen';
        this.journalWeather = 'Wetter';
        this.journalWeatherAdd = 'Wettereintrag hinzufügen';
        this.journalWeatherAllDay = 'ganztägig';
        this.journalWeatherClear = 'Sonne';
        this.journalWeatherCloudy = 'Wolken';
        this.journalWeatherCloudyWindy = 'Wolken & Wind';
        this.journalWeatherConditions = 'Wetterlage';
        this.journalWeatherFog = 'Nebel';
        this.journalWeatherHail = 'Hagel';
        this.journalWeatherHighLowerThanLow = 'Bei dem Wettereintrag {{index}} ist die Höchsttemperatur kleiner als die Tiefsttemperatur (tief)';
        this.journalWeatherLightning = 'Blitze';
        this.journalWeatherNoData = 'Keine Wetterdaten';
        this.journalWeatherPartlyCloudy = 'Teils Wolken';
        this.journalWeatherPouring = 'Starkregen';
        this.journalWeatherRain = 'Regen';
        this.journalWeatherSleet = 'Schneeregen';
        this.journalWeatherSnow = 'Schnee';
        this.journalWeatherTemperatureHigh = 'Temp (hoch)';
        this.journalWeatherTemperatureLow = 'Temp (tief)';
        this.journalWeatherThunderstorm = 'Gewitter';
        this.journalWeatherTime = 'Uhrzeit';
        this.journalWeatherWind = 'Wind';
        this.journalWorkDone = 'Durchgeführte Arbeiten';
        this.keepBasicSubscription = 'Basic bleiben';
        this.keepEnterpriseSubscription = 'Enterprise bleiben';
        this.keepProSubscription = 'Pro bleiben';
        this.kiActionCreateTicket = 'Ticket erstellen';
        this.kiActionCreateTicketSuccess = 'Ihr ticket wurde erstellt öffnen';
        this.kiActionHelper = 'Beschreibe das Ticket, das du erstellen möchtest';
        this.kiActionLoading = 'Antwort wird geladen...';
        this.kiActionNotFound = 'Es wurde keine passende Aktion für ihre Anfrage gefunden.';
        this.kiDialogTitle = '🤖 meshy - AI Assistent';
        this.kiFabTitle = 'meshy - AI Assistent öffnen';
        this.language = 'Sprache';
        this.lastMonth = 'letzten Monat';
        this.lastName = 'Nachname';
        this.lastWeek = 'letzte Woche';
        this.latitude = 'Breitengrad';
        this.linkTokenExpired = 'Der Link ist nicht mehr gültig';
        this.listCopyFromEmptyState = 'Keine Liste in den Firmeneinstellungen hinterlegt.';
        this.listCopyFromTitle = 'Wählen Sie eine Liste zum Kopieren aus.';
        this.listsTitle = 'Listen';
        this.loading = 'wird geladen...';
        this.loadingTicketReport = 'Lade Ticketbericht';
        this.logBook = 'Aktivitäten';
        this.logBookAbsenceApproved = '{{creatorName}} hat die Abwesenheit genehmigt';
        this.logBookAbsenceApproved_me = 'Sie haben die Abwesenheit genehmigt';
        this.logBookAbsenceChanged = '{{creatorName}} hat eine Abwesenheit geändert';
        this.logBookAbsenceChanged_me = 'Sie haben eine Abwesenheit geändert';
        this.logBookAbsenceCreated = '{{creatorName}} hat eine Abwesenheit beantragt';
        this.logBookAbsenceCreated_me = 'Sie haben eine Abwesenheit beantragt';
        this.logBookAbsenceRejected = '{{creatorName}} hat die Abwesenheit abgelehnt';
        this.logBookAbsenceRejected_me = 'Sie haben die Abwesenheit abgelehnt';
        this.logBookCopyProject = 'Das Projekt wurde durch {{creatorName}} kopiert';
        this.logBookEntityAbsence = 'Abwesenheit';
        this.logBookEntityPlan = 'Plan';
        this.logBookEntityPlanVersion = 'Planversion';
        this.logBookEntityTicket = 'Ticket';
        this.logBookEntityTicketComment = 'Ticket';
        this.logBookFolderChanged = '{{creatorName}} hat den Ordner geändert';
        this.logBookFolderChanged_me = 'Sie haben den Ordner geändert';
        this.logBookFolderCreated = '{{creatorName}} hat den Ordner "{{title}}" erstellt';
        this.logBookFolderCreated_me = 'Sie haben den Ordner "{{title}}" erstellt';
        this.logBookFolderDeleted = '{{creatorName}} hat den Ordner "{{entityTitle}}" gelöscht';
        this.logBookFolderDeleted_me = 'Sie haben den Ordner "{{entityTitle}}" gelöscht';
        this.logBookFolderMoved = '{{creatorName}} hat den Ordner verschoben';
        this.logBookFolderMoved_me = 'Sie haben den Ordner verschoben';
        this.logBookInDashboardOuterTicket = '{{inner}}, im Ticket "{{entityTitle}}"';
        this.logBookInMultiOuter = '{{inner}} im Projekt "{{projectTitle}}"';
        this.logBookPlanChanged = '{{creatorName}} hat die Plandaten geändert';
        this.logBookPlanChanged_me = 'Sie haben die Plandaten geändert';
        this.logBookPlanCreated = '{{creatorName}} hat einen neuen Plan erstellt';
        this.logBookPlanCreated_me = 'Sie haben einen neuen Plan erstellt';
        this.logBookPlanDeleted = '{{creatorName}} hat den Plan {{plantitle}} gelöscht';
        this.logBookPlanDeleted_me = 'Sie haben den Plan {{plantitle}} gelöscht';
        this.logBookPlanVersionActivated = '{{creatorName}} hat eine neue Planversion aktiviert';
        this.logBookPlanVersionActivated_me = 'Sie haben eine neue Planversion aktiviert';
        this.logBookPlanVersionCreated = '{{creatorName}} hat eine neue Planversion erstellt';
        this.logBookPlanVersionCreated_me = 'Sie haben eine neue Planversion erstellt';
        this.logBookPlanVersionDeleted = '{{creatorName}} hat die Planversion {{version}} gelöscht';
        this.logBookPlanVersionDeleted_me = 'Sie haben die Planversion {{version}} gelöscht';
        this.logBookProjectAddressChanged = '{{creatorName}} hat den Projektkontakt geändert: {{changes}}';
        this.logBookProjectAddressChanged_me = 'Sie haben den Projektkontakt geändert: {{changes}}';
        this.logBookProjectAddressCreated = '{{creatorName}} hat den Projektkontakt hinzugefügt: {{changes}}';
        this.logBookProjectAddressCreated_me = 'Sie haben den Projektkontakt hinzugefügt: {{changes}}';
        this.logBookProjectArchivingChanged = 'Das Projekt wurde archiviert/reaktiviert';
        this.logBookProjectArchivingChanged_false = 'Das Projekt wurde reaktiviert';
        this.logBookProjectArchivingChanged_true = 'Das Projekt wurde archiviert';
        this.logBookProjectChanged = '{{creatorName}} hat die Projektdaten geändert';
        this.logBookProjectChanged_me = 'Sie haben die Projektdaten geändert';
        this.logBookProjectCopied = 'Das Projekt "{{projectTitle}}" wurde erfolgreich von "{{originTitle}}" kopiert';
        this.logBookProjectCreated = 'Das Projekt wurde von {{creatorName}} erstellt';
        this.logBookProjectCreated_me = 'Sie haben das Projekt erstellt';
        this.logBookProjectDeletionChanged = 'Das Projekt wurde gelöscht';
        this.logBookProjectDeletionChanged_false = 'Das Projekt wurde wiederhergestellt';
        this.logBookProjectDeletionChanged_true = 'Das Projekt wurde gelöscht';
        this.logBookProjectOwnerChanged = 'Das Projekt wurde von {{oldOwnerName}} an {{newOwnerName}} übergeben';
        this.logBookProjectOwnerChanged_me = 'Das Projekt wurde von Ihnen an {{newOwnerName}} übergeben';
        this.logBookProjectRoleCreated = '{{creatorName}} hat die Rolle "{{roleName}}" erstellt';
        this.logBookProjectRoleCreated_me = 'Sie haben die Rolle "{{roleName}}" erstellt';
        this.logBookProjectSubscriptionChanged = '{{creatorName}} hat das Projekt Abonnement auf {{projectSubscription}} geändert';
        this.logBookProjectSubscriptionChanged_me = 'Sie haben das Projekt Abonnement auf {{projectSubscription}} geändert';
        this.logBookProjectUserAccepted = '{{creatorName}} hat die Einladung angenommen';
        this.logBookProjectUserAccepted_me = 'Sie haben die Einladung angenommen';
        this.logBookProjectUserAdded = '{{email}} wurde von {{creatorName}} eingeladen';
        this.logBookProjectUserAddedHimself = '{{creatorName}} hat sich zum Projekt hinzugefügt';
        this.logBookProjectUserAddedHimself_me = 'Sie haben sich zum Projekt hinzugefügt';
        this.logBookProjectUserAdded_me = 'Sie haben {{email}} eingeladen';
        this.logBookProjectUserRemoved = '{{creatorName}} hat {{userName}} aus dem Projekt entfernt';
        this.logBookProjectUserRemovedHimself = '{{creatorName}} hat das Projekt verlassen';
        this.logBookProjectUserRemovedHimself_me = 'Sie haben das Projekt verlassen';
        this.logBookProjectUserRemoved_me = 'Sie haben {{userName}} aus dem Projekt entfernt';
        this.logBookProjectUserRoleChanged = '{{creatorName}} hat die Rolle von {{userName}} in {{roleName}} geändert';
        this.logBookProjectUserRoleChanged_me = 'Sie haben die Rolle von {{userName}} in {{roleName}} geändert';
        this.logBookShowMoreActivities = 'Mehr anzeigen';
        this.logBookStoredFileAdded = '{{creatorName}} hat die Datei "{{filename}}" in den Ordner "Projektablage" hochgeladen';
        this.logBookStoredFileAdded_folder = '{{creatorName}} hat die Datei "{{filename}}" in den Ordner "{{folderName}}" hochgeladen';
        this.logBookStoredFileAdded_me = 'Sie haben die Datei "{{filename}}" in den Ordner "Projektablage" hochgeladen';
        this.logBookStoredFileAdded_mefolder = 'Sie haben die Datei "{{filename}}" in den Ordner "{{folderName}}" hochgeladen';
        this.logBookStoredFileAdded_meticket = 'Sie haben die Datei "{{filename}}" ins Ticket hochgeladen';
        this.logBookStoredFileAdded_ticket = '{{creatorName}} hat die Datei "{{filename}}" ins Ticket hochgeladen';
        this.logBookStoredFileChanged = '{{creatorName}} hat die Datei "{{filename}}" im Ordner "Projektablage" geändert';
        this.logBookStoredFileChanged_folder = '{{creatorName}} hat die Datei "{{filename}}" im Ordner "{{folderName}}" geändert';
        this.logBookStoredFileChanged_me = 'Sie haben die Datei "{{filename}}" im Ordner "Projektablage" geändert';
        this.logBookStoredFileChanged_mefolder = 'Sie haben die Datei "{{filename}}" im Ordner "{{folderName}}" geändert';
        this.logBookStoredFileChanged_meticket = 'Sie haben die Datei "{{filename}}" im Ticket geändert';
        this.logBookStoredFileChanged_ticket = '{{creatorName}} hat die Datei "{{filename}}" im Ticket geändert';
        this.logBookStoredFileDeleted = '{{creatorName}} hat die Datei "{{filename}}" aus dem Ordner "Projektablage" entfernt';
        this.logBookStoredFileDeleted_folder = '{{creatorName}} hat die Datei "{{filename}}" aus dem Ordner "{{folderName}}" entfernt';
        this.logBookStoredFileDeleted_me = 'Sie haben die Datei "{{filename}}" aus dem Ordner "Projektablage" entfernt';
        this.logBookStoredFileDeleted_mefolder = 'Sie haben die Datei "{{filename}}" aus dem Ordner "{{folderName}}" entfernt';
        this.logBookStoredFileDeleted_meticket = 'Sie haben die Datei "{{filename}}" aus dem Ticket entfernt';
        this.logBookStoredFileDeleted_ticket = '{{creatorName}} hat die Datei "{{filename}}" aus dem Ticket entfernt';
        this.logBookStoredFilenameChanged = '{{userName}} hat die Datei "{{oldFilename}}" in der "Projektablage" in "{{filename}}" umbenannt.';
        this.logBookStoredFilenameChanged_folder = '{{userName}} hat die Datei "{{oldFilename}}" im Ordner "{{folderName}}" in "{{filename}}" umbenannt.';
        this.logBookStoredFilenameChanged_me = 'Sie haben die Datei "{{oldFilename}}" in der "Projektablage" in "{{filename}}" umbenannt';
        this.logBookStoredFilenameChanged_mefolder = 'Sie haben die Datei "{{oldFilename}}" im Ordner "{{folderName}}" in "{{filename}}" umbenannt';
        this.logBookStoredFilenameChanged_meticket = 'Sie haben die Datei "{{oldFilename}}" im Ticket in "{{filename}}" umbenannt';
        this.logBookStoredFilenameChanged_ticket = '{{userName}} hat die Datei "{{oldFilename}}" im Ticket in "{{filename}}" umbenannt.';
        this.logBookTicketApproverChanged = '{{creatorName}} hat als Prüfer {{approver}} gesetzt';
        this.logBookTicketApproverChanged_himself = '{{creatorName}} hat sich als Prüfer gesetzt';
        this.logBookTicketApproverChanged_me = 'Sie haben als Prüfer {{approver}} gesetzt';
        this.logBookTicketApproverChanged_meyou = 'Sie haben sich als Prüfer gesetzt';
        this.logBookTicketApproverChanged_you = '{{creatorName}} hat Sie als Prüfer gesetzt';
        this.logBookTicketApproverRemoved = '{{creatorName}} hat den Prüfer {{removedUser}} entfernt';
        this.logBookTicketApproverRemoved_himself = '{{creatorName}} hat sich als Prüfer entfernt';
        this.logBookTicketApproverRemoved_me = 'Sie haben den Prüfer {{removedUser}} entfernt';
        this.logBookTicketApproverRemoved_meyou = 'Sie haben sich als Prüfer entfernt';
        this.logBookTicketApproverRemoved_you = '{{creatorName}} hat Sie als Prüfer entfernt';
        this.logBookTicketArchiveStatusChanged = '{{creatorName}} hat das Ticket archiviert/reaktiviert';
        this.logBookTicketArchiveStatusChanged_false = '{{creatorName}} hat das Ticket reaktiviert';
        this.logBookTicketArchiveStatusChanged_falseme = 'Sie haben das Ticket reaktiviert';
        this.logBookTicketArchiveStatusChanged_true = '{{creatorName}} hat das Ticket archiviert';
        this.logBookTicketArchiveStatusChanged_trueme = 'Sie haben das Ticket archiviert';
        this.logBookTicketAssignedChanged = '{{creatorName}} hat das Ticket {{assigner}} zugewiesen';
        this.logBookTicketAssignedChanged_himself = '{{creatorName}} hat sich das Ticket zugewiesen';
        this.logBookTicketAssignedChanged_me = 'Sie haben das Ticket {{assigner}} zugewiesen';
        this.logBookTicketAssignedChanged_meyou = 'Sie haben sich das Ticket zugewiesen';
        this.logBookTicketAssignedChanged_you = '{{creatorName}} hat Ihnen das Ticket zugewiesen';
        this.logBookTicketAssignerRemoved = '{{creatorName}} hat den Zugewiesenen {{removedUser}} entfernt';
        this.logBookTicketAssignerRemoved_himself = '{{creatorName}} hat sich als Zugewiesenen entfernt';
        this.logBookTicketAssignerRemoved_me = 'Sie haben den Zugewiesenen {{removedUser}} entfernt';
        this.logBookTicketAssignerRemoved_meyou = 'Sie haben sich als Zugewiesenen entfernt';
        this.logBookTicketAssignerRemoved_you = '{{creatorName}} hat Sie als Zugewiesenen entfernt';
        this.logBookTicketCommentAdded = '{{creatorName}} hat das Ticket kommentiert: "{{comment}}"';
        this.logBookTicketCommentAddedFile = '{{creatorName}} hat dem Ticket eine Datei hinzugefügt';
        this.logBookTicketCommentAddedFile_me = 'Sie haben dem Ticket eine Datei hinzugefügt';
        this.logBookTicketCommentAddedPicture = '{{creatorName}} hat dem Ticket ein Bild hinzugefügt';
        this.logBookTicketCommentAddedPicture_me = 'Sie haben dem Ticket ein Bild hinzugefügt';
        this.logBookTicketCommentAdded_me = 'Sie haben das Ticket kommentiert: "{{comment}}"';
        this.logBookTicketCommentChanged = '{{creatorName}} hat seinen Ticketkommentar geändert: "{{comment}}"';
        this.logBookTicketCommentChanged_me = 'Sie haben Ihren Ticketkommentar geändert: "{{comment}}"';
        this.logBookTicketCommentFileAdded = 'Datei wurde dem Ticket hinzugefügt';
        this.logBookTicketCompletionOnChanged = '{{creatorName}} hat das Fälligkeitsdatum auf {{date}} geändert';
        this.logBookTicketCompletionOnChanged_me = 'Sie haben das Fälligkeitsdatum auf {{date}} geändert';
        this.logBookTicketCompletionOnRemoved = '{{creatorName}} hat das Fälligkeitsdatum entfernt';
        this.logBookTicketCompletionOnRemoved_me = 'Sie haben das Fälligkeitsdatum entfernt';
        this.logBookTicketCraftChanged = '{{creatorName}} hat das Gewerk in "{{craft}}" geändert';
        this.logBookTicketCraftChanged_me = 'Sie haben das Gewerk in "{{craft}}" geändert';
        this.logBookTicketCraftRemoved = '{{creatorName}} hat das Gewerk entfernt';
        this.logBookTicketCraftRemoved_me = 'Sie haben das Gewerk entfernt';
        this.logBookTicketCreated = '{{creatorName}} hat das Ticket erstellt';
        this.logBookTicketCreated_me = 'Sie haben das Ticket erstellt';
        this.logBookTicketCustomFieldChanged = '{{creatorName}} hat das Feld "{{name}}" in "{{formattedValue}}" geändert';
        this.logBookTicketCustomFieldChanged_me = 'Sie haben das Feld "{{name}}" in "{{formattedValue}}" geändert';
        this.logBookTicketCustomFieldRemoved = '{{creatorName}} hat das Feld "{{name}}" zurückgesetzt';
        this.logBookTicketCustomFieldRemoved_me = 'Sie haben das Feld "{{name}}" zurückgesetzt';
        this.logBookTicketDefault = '{{creatorName}} hat etwas geändert (fehlender Text für: {{eventName}})';
        this.logBookTicketDefault_me = 'Sie haben etwas geändert (fehlender Text für: {{eventName}})';
        this.logBookTicketDeleteStatusChanged = '{{creatorName}} hat das Ticket gelöscht';
        this.logBookTicketDeleteStatusChanged_false = '{{creatorName}} hat das Ticket wiederhergestellt';
        this.logBookTicketDeleteStatusChanged_falseme = 'Sie haben das Ticket wiederhergestellt';
        this.logBookTicketDeleteStatusChanged_trueme = 'Sie haben das Ticket gelöscht';
        this.logBookTicketDescriptionChanged = '{{creatorName}} hat die Beschreibung in "{{description}}" geändert';
        this.logBookTicketDescriptionChanged_me = 'Sie haben die Beschreibung in "{{description}}" geändert';
        this.logBookTicketExpired = 'Das Ticket "{{entityTitle}}" ist heute fällig';
        this.logBookTicketNumberChanged = 'Die Nummer "{{ticket.ticketNumber}}" wurde zugewiesen';
        this.logBookTicketPlanAffiliationChanged = '{{creatorName}} hat das Ticket auf den Plan {{planTitle}} verschoben';
        this.logBookTicketPlanAffiliationChanged_me = 'Sie haben das Ticket auf den Plan {{planTitle}} verschoben';
        this.logBookTicketPlanAffiliationRemoved = '{{creatorName}} hat die Planzugehörigkeit entfernt';
        this.logBookTicketPlanAffiliationRemoved_me = 'Sie haben die Planzugehörigkeit entfernt';
        this.logBookTicketPlanPositionChanged = '{{creatorName}} hat die Position auf dem Plan geändert';
        this.logBookTicketPlanPositionChanged_me = 'Sie haben die Position auf dem Plan geändert';
        this.logBookTicketStatusChanged = '{{creatorName}} hat den Status in "{{status}}" geändert';
        this.logBookTicketStatusChanged_me = 'Sie haben den Status in "{{status}}" geändert';
        this.logBookTicketSubTicketAdded = '{{creatorName}} hat das Sub-Ticket "{{subTicketFromTitle}}" hinzugefügt';
        this.logBookTicketSubTicketAdded_me = 'Sie haben das Sub-Ticket "{{subTicketFromTitle}}" hinzugefügt';
        this.logBookTicketSubTicketFromChanged = '{{creatorName}} hat das übergeordnete Ticket in "{{subTicketFromTitle}}" geändert';
        this.logBookTicketSubTicketFromChanged_me = 'Sie haben das übergeordnete Ticket in "{{subTicketFromTitle}}" geändert';
        this.logBookTicketSubTicketFromRemoved = '{{creatorName}} hat das übergeordnete Ticket "{{subTicketFromTitle}}" entfernt';
        this.logBookTicketSubTicketFromRemoved_me = 'Sie haben das übergeordnete Ticket "{{subTicketFromTitle}}" entfernt';
        this.logBookTicketSubTicketRemoved = '{{creatorName}} hat das Sub-Ticket "{{subTicketFromTitle}}" entfernt';
        this.logBookTicketSubTicketRemoved_me = 'Sie haben das Sub-Ticket "{{subTicketFromTitle}}" entfernt';
        this.logBookTicketTagAdded = '{{creatorName}} hat das Tag "{{tag.tagName}}" hinzugefügt';
        this.logBookTicketTagAdded_me = 'Sie haben das Tag "{{tag.tagName}}" hinzugefügt';
        this.logBookTicketTagRemoved = '{{creatorName}} hat das Tag "{{tag.tagName}}" entfernt';
        this.logBookTicketTagRemoved_me = 'Sie haben das Tag "{{tag.tagName}}" entfernt';
        this.logBookTicketTagsChanged = '{{creatorName}} hat die Tags geändert';
        this.logBookTicketTagsChanged_me = 'Sie haben die Tags geändert';
        this.logBookTicketTitleChanged = '{{creatorName}} hat den Titel in "{{title}}" geändert';
        this.logBookTicketTitleChanged_me = 'Sie haben den Titel in "{{title}}" geändert';
        this.logBookTicketTypeChanged = '{{creatorName}} hat den Typen in "{{type}}" geändert';
        this.logBookTicketTypeChanged_me = 'Sie haben den Typen in "{{type}}" geändert';
        this.logBookTicketTypeRemoved = '{{creatorName}} hat den Typen entfernt';
        this.logBookTicketTypeRemoved_me = 'Sie haben den Typen entfernt';
        this.logBookTicketWatcherAdded = '{{creatorName}} hat den Beobachter "{{watcher}}" hinzugefügt';
        this.logBookTicketWatcherAdded_himself = '{{creatorName}} hat sich als Beobachter hinzugefügt';
        this.logBookTicketWatcherAdded_me = 'Sie haben den Beobachter "{{watcher}}" hinzugefügt';
        this.logBookTicketWatcherAdded_meyou = 'Sie haben sich als Beobachter hinzugefügt';
        this.logBookTicketWatcherAdded_you = '{{creatorName}} hat Sie als Beobachter hinzugefügt';
        this.logBookTicketWatcherRemoved = '{{creatorName}} hat den Beobachter "{{watcher}}" entfernt';
        this.logBookTicketWatcherRemoved_himself = '{{creatorName}} hat sich als Beobachter entfernt';
        this.logBookTicketWatcherRemoved_me = 'Sie haben den Beobachter "{{watcher}}" entfernt';
        this.logBookTicketWatcherRemoved_meyou = 'Sie haben sich als Beobachter entfernt';
        this.logBookTicketWatcherRemoved_you = '{{creatorName}} hat Sie als Beobachter entfernt';
        this.logBookUserInvitationEmailRequested = 'An {{toEmail}} wurde erneut eine Einladungsemail gesendet';
        this.login = 'anmelden';
        this.loginError = 'Fehlerhafte Zugangsdaten';
        this.loginErrorEmailNotValidated = 'Ihre E-Mail-Adresse ist noch nicht verifiziert. Sie sollten in Kürze eine E-Mail erhalten. Bitte bestätigen Sie' +
            ' zunächst den Link in der E-Mail, um sich wieder einloggen zu können.';
        this.loginErrorNoAccount = 'Account nicht vorhanden, jetzt Registrieren?';
        this.loginErrorNoAccountGoogle = 'Bereits registriert? Dann melden Sie sich jetzt an und verbinden Sie unter Mein Konto Ihren Google Account mit Ihrem upmesh Konto.';
        this.loginErrorNoAccountGoogleSub = 'Sie sind neu hier, dann registrieren Sie sich jetzt mit Ihrem Google Account!';
        this.loginErrorNoAccountGoogleTitle = 'Google Account noch nicht verbunden';
        this.loginErrorNoAccountRegister = 'Registrieren';
        this.loginErrorNoGoogle = 'Google Login nicht möglich';
        this.loginErrorWrongCredentials = 'Fehlerhafte Zugangsdaten';
        this.loginForgotPw = 'Passwort vergessen?';
        this.loginInfo = 'Indem Sie auf "anmelden" oder "registrieren" tippen, stimmen Sie den Nutzungsbedingungen und Zahlungsbedingungen von upmesh zu.';
        this.loginOffline = 'Sie benötigen eine Internetverbindung, um sich anzumelden';
        this.loginRegister = 'Registrieren';
        this.loginTitle = 'Anmelden';
        this.loginWithGoogle = 'Mit Google anmelden';
        this.longitude = 'Längengrad';
        this.maxLicensesReached = 'Alle Lizenzen im Einsatz';
        this.maxLicensesReachedText = 'Sie benötigen weitere Lizenzen, um weitere Benutzer hinzuzufügen. Bitte kontaktieren Sie' +
            ' unseren Support unter 05451-59333';
        this.maxProjectsForFree = 'Maximale Anzahl kostenloser Projekte erreicht, jetzt zur Pro Version wechseln!';
        this.maxSizeReached = 'Maximale Projektgröße erreicht';
        this.maxSizeReachedText = 'Das Projekt hat den maximalen Speicherplatz erreicht. Ab sofort können Sie nur noch' +
            ' Inhalte lesen. Jetzt upgraden und auf noch mehr Funktionen und einen erweiterten Datenspeicher freuen.';
        this.maxSizeReachedUpgradeButton = 'Jetzt upgraden';
        this.maxSmallerThanMin = 'Maximum darf nicht kleiner sein als Minimum';
        this.memberId = 'Mitarbeiter';
        this.members = 'Mitglieder';
        this.menuProject = 'Dashboard';
        this.menuProjectDailyReports = 'Tagesberichte';
        this.menuProjectFiles = 'Dateien';
        this.menuProjectInvolved = 'Team';
        this.menuProjectPlans = 'Pläne';
        this.menuTickets = 'Tickets';
        this.methodNotAllowed = 'Methode nicht erlaubt';
        this.minutes = 'Minuten';
        this.minutesShort = 'min';
        this.moduleTimeTrackingJournalEdit = 'Zeiterfasser (alt, mit Tagesbericht)';
        this.month = 'Monat';
        this.more = 'mehr';
        this.moveFile = 'Datei verschieben';
        this.moveFolder = 'Ordner verschieben';
        this.moveFolderTo = `Ordner "{{folderName}}" verschieben nach:`;
        this.moveFolderToQuestion = `Möchten Sie den Ordner "{{folderName}}" nach "{{targetFolderName}}" verschieben?`;
        this.multiTabError = 'upmesh ist bereits in einem anderen Tab oder Fenster geöffnet';
        this.name = 'Name';
        this.networkbluetooth = 'Bluetooth';
        this.networkbvpn = 'VPN';
        this.networkcellular = 'Mobile Daten';
        this.networkethernet = 'Ethernet';
        this.networknone = 'Keine';
        this.networkother = 'Andere';
        this.networkunknown = 'Unbekannt';
        this.networkwifi = 'WLAN';
        this.networkwimax = 'Wimax';
        this.newPlanVersion = 'als neue Version hinzuügen';
        this.next = 'weiter';
        this.nextWeek = 'Nächste Woche';
        this.no = 'Nein';
        this.noActivities = 'Noch keine Aktivitäten';
        this.noActivitiesOffline = 'Aktivitäten stehen offline nicht zur Verfügung';
        this.noCompany = 'Keine Firma';
        this.noEntriesFound = 'Keine Einträge gefunden';
        this.noExtraPayTrackingFound = 'Keine Zulagen gefunden';
        this.noHigherRightThanBaseRole = 'Die Rolle darf nicht mehr Rechte als die Basisrolle haben';
        this.noImages = 'Keine Bilder vorhanden';
        this.noPlan = 'Kein Plan';
        this.noPlanAffiliation = 'Keinem Plan zugeordnet';
        this.noProjectsFound = 'Es wurde kein Projekt gefunden.';
        this.noRightToAddFile = 'Keine Berechtigung, die Datei hinzuzufügen!';
        this.noRightToEditFile = 'Keine Berechtigung, die Datei zu editieren!';
        this.noRightToEditFile24h = 'Keine Berechtigung mehr, die Datei zu editieren! (24 Stunden Limit)';
        this.noRightToRemoveFile = ' Keine Berechtigung, die Datei zu entfernen!';
        this.noTabConnection = 'Es konnte keine Verbindung zum Haupttab hergestellt werden, Serververbindung verloren';
        this.noTargetFolderFound = 'Keine möglichen Zielordner gefunden';
        this.noTicketsAvailable = 'Keine Tickets verfügbar';
        this.noTimeTrackingRecordsForDay = 'Fehlende Erfassung';
        this.notEnoughLicenses = 'Es sind nicht ausreichend Lizenzen verfügbar';
        this.notForFree = 'Diese Funktion ist in Basic-Projekten nicht verfügbar';
        this.notFound = 'Die angeforderte Seite wurde nicht gefunden';
        this.notFoundSite = 'Die angeforderte Seite "{site}" wurde nicht gefunden';
        this.notInBasicProject = 'Diese Funktion ist in Basic-Projekten nicht verfügbar';
        this.note = 'Notiz';
        this.notes = 'Notizen';
        this.notesAddCheckbox = 'Checkbox hinzufügen';
        this.notesAddNote = 'Klicken Sie, um eine Notiz hinzuzufügen';
        this.notesAddNoteToProject = 'Klicken Sie, um eine Notiz zum Projekt {{projectName}} hinzuzufügen';
        this.notesNoExistingNotes = 'Klicken Sie, um ihre erste Notiz zu erstellen';
        this.notesNoNotesInProject = 'In dem Projekt {{projectName}} gibt es noch keine Notiz. Klicken Sie, um dort eine zu erstellen';
        this.notesTypeHere = 'Hier tippen...';
        this.notificationsEverythingReadHeader = 'Alles erledigt!';
        this.notificationsEverythingReadSubline = 'Sie sind auf dem neuesten Stand.';
        this.notificationsErrorMessage = 'Die Benachrichtigungseinstellungen konnten nicht gespeichert werden';
        this.notificationsMarkAllAsRead = 'alle als gelesen markieren';
        this.notificationsMarkAsNotRead = 'als ungelesen markieren';
        this.notificationsMarkAsRead = 'als gelesen markieren';
        this.notificationsOfflineMessage = 'Sie sind mit keinem Server verbunden. Offline können keine Änderungen vorgenommen werden.';
        this.notificationsRead = 'gelesen';
        this.notificationsSettings = 'Benachrichtigungseinstellungen';
        this.notificationsSettingsAutoAddNew = 'Neue automatisch aktivieren';
        this.notificationsShowOnlyUnread = 'nur ungelesene';
        this.novalineExportCostCenterInvalid = 'Die Kostenstelle "{{error}}" ist nicht gültig für einen Novaline-Export. (maximal 15 Stellen)';
        this.novalineExportCostUnitAsCostCenter = 'Kostenträger als Kostenstellen exportieren.';
        this.novalineExportCostUnitInvalid = 'Der Kostenträger "{{error}}" ist ungültig für einen Novaline-Export. (maximal 15 Stellen)';
        this.novalineExportKundenNummerInvalid = 'Die Kunden-Nr. "{{error}}" ist ungültig für einen Novaline-Export. (maximal 8 Ziffern).';
        this.novalineExportKundenNummerNeeded = 'Für den Novaline-Export wird eine Kunden-Nr. benötigt.';
        this.novalineExportMandantNumberInvalid = 'Die Mandant-Nr. "{{error}}" ist ungültig für einen Novaline-Export. (maximal 8 Ziffern).';
        this.novalineExportMandantNumberNeeded = 'Für den Novaline-Export wird eine Mandant-Nr. benötigt.';
        this.novalineExportWageTypeInvalid = 'Die Lohnart "{{error}}" ist ungültig für einen Novaline-Export. (maximal 8 Ziffern)';
        this.novalineExportWorkTypeInvalid = 'Die Arbeitsart {{error}} ist ungültig für einen Novaline-Export. (maximal 2 Stellen)';
        this.numberToBig = 'Der Wert ist zu groß, maximum {{value}}';
        this.numberToSmall = 'Der Wert ist zu klein, minimum {{value}}';
        this.oClock = 'Uhr';
        this.offline = 'Offline';
        this.offlineNotAvailable = 'Offline nicht verfügbar';
        this.offlineSettingsZoomLevel = 'Bis Zoomstufe';
        this.ok = 'ok';
        this.openFile = 'Datei wurde unter "{{target}}" gespeichert.\nMöchten Sie die Datei jetzt öffnen?';
        this.openFolder = 'Ordner öffnen';
        this.openJournal = 'Tagesbericht öffnen';
        this.openLinkInThisTab = 'Diesen Tab zum Haupttab machen';
        this.openMenu = 'Menu öffnen';
        this.openTicket = 'Ticket öffnen';
        this.or = 'oder';
        this.overrideApprovalRequired = 'Bestätigung zum überschreiben benötigt';
        this.ownerChangeConfirmation = 'Die Übertragung des Projektes muss bestätigt werden.';
        this.ownerChangeMaxFreeProjects = 'Das Basis Mitglied ist bereits Eigentümer von 5 Projekten. Mehr als 5 Projekte sind ab der Pro Version möglich';
        this.ownerChangeProProjectOnlyToProUser = 'Ein Pro Projekt kann nur einem Mitglied mit einem Pro Account übergeben werden.';
        this.password = 'Passwort';
        this.pause = 'Pause';
        this.paymentMethod = 'Zahlungsweise';
        this.payroll = 'Lohnabrechner';
        this.pdfHasTooManyPages = 'Der Plan konnte nicht hochgeladen werden. Es sind maximal 50 Seiten pro pdf erlaubt';
        this.pdfSaveChangesQuestionText = 'Sie können wählen, ob die vorhandene Datei ersetzt wird oder eine Kopie mit dem neuen' +
            ' Inhalt erstellt werden soll.';
        this.pdfSaveChangesQuestionTitle = 'Möchten Sie die Änderungen speichern?';
        this.phone = 'Telefon';
        this.plan = 'Plan';
        this.planActivate = 'aktivieren';
        this.planActivateQuestion = 'Planversion zur aktiven Version machen?';
        this.planCurrentVersionNotActiveOne = 'Die neueste Planversion ist nicht aktiviert';
        this.planDeleteQuestionTextWithTickets = 'Wenn Sie diesen Plan löschen, verlieren die zugeordneten Tickets ihre Planzugehörigkeit. Alle Planversionen werden ebenfalls gelöscht.';
        this.planDeleteQuestionTextWithoutTickets = 'Möchten Sie diesen Plan löschen? Alle Versionen werden ebenfalls gelöscht.';
        this.planDeleteQuestionTitle = 'Löschen bestätigen {{plantitle}}';
        this.planDetailChange = 'Plandetails geändert';
        this.planDetails = 'Details';
        this.planDetailsPlanActivePlan = 'Aktive Planversion';
        this.planDetailsPlanNotes = 'Anmerkungen';
        this.planDetailsPlanTags = 'Tags';
        this.planDetailsPlanTitle = 'Plantitel';
        this.planDetailsViewChangeToTiles = 'Zur Kachelansicht wechseln';
        this.planEditTitleExists = 'Der Plantitel existiert bereits';
        this.planFiles = 'Plandateien';
        this.planFilterAddTeammember = 'Hinzufügen';
        this.planFilterHeader = 'Pläne filtern';
        this.planFilterPersons = 'Ersteller';
        this.planFilterTags = 'Tags';
        this.planFilterTimePeriod = 'Erstellungszeitraum';
        this.planNotFound = 'Dieser Plan existiert nicht (mehr)';
        this.planSiteReleasePlans = 'Pläne freigeben';
        this.planSiteSearchPlans = 'Pläne suchen';
        this.planTilesNotYetCreated = 'Der Plan ist noch nicht in voller Qualität verfügbar. Dies kann einige Minuten dauern.';
        this.planTitle = 'Plantitel';
        this.planToTheVersions = 'Zu den Planversionen';
        this.planUploadDescription = 'Pläne hochladen und starten';
        this.planUploadDropHint = 'Plan hinzufügen';
        this.planUploadDropOnMultiSelect = 'Plan hinzufügen';
        this.planUploadExisting = 'Datei bereits vorhanden';
        this.planUploadNew = 'Neu';
        this.planUploadNothingToSave = 'Keinen Plan ausgewählt';
        this.planUploadPlanNotes = 'Anmerkung';
        this.planUploadPlanTitle = 'Plantitel*';
        this.planUploadPlanTitleAlready = 'Plantitel bereits vorhanden. Anderer Titel oder neue Version';
        this.planUploadPlanTitleAlreadyInOtherPage = 'Plantitel wird bereits auf Seite {{page}} verwendet';
        this.planUploadPlanTitleRequired = 'Der Plantitel wird benötigt';
        this.planUploadProgress = 'Planverarbeitung wird durchgeführt';
        this.planUploadTakeSite = 'Seite {{pageNumber}} verwenden';
        this.planUploadTextButton = 'Pläne als PDF oder Bild auswählen';
        this.planUploadTitle = 'Pläne hochladen';
        this.planUploadedDeleteQuestion = 'PDF Datei verwerfen?';
        this.planUploadedErrorOnSubmit = 'Nicht alle Formulare wurden korrekt ausgefüllt';
        this.planUploadedQuestionAfterSubmit = 'Wenn Sie nur eine Auswahl von Seiten eines Plans freigeben möchten, werden die nicht ausgewählten Seiten gelöscht.\n' +
            '\n' +
            'Pläne, von denen Sie jetzt keine Seiten freigeben möchten, bleiben bestehen. Sie können diese auch später noch freigeben.\n' +
            '\n' +
            'Möchten Sie die nicht ausgewählten Seiten der pdf Datei löschen?';
        this.planVersion = 'Planversionen';
        this.planVersionActive = 'Aktiv';
        this.planVersionCreationFailed = 'Die Erstellung des Plans ist fehlgeschlagen \n{{errorMessage}}';
        this.planVersionCreationRecreate = 'Neu erstellen';
        this.planVersionDeleteQuestionText = 'Möchten Sie diese Planversion löschen?';
        this.planVersionDeleteQuestionTitle = 'Löschen bestätigen {{plantitle}}';
        this.planVersionIsCreating = 'Der Plan wird erstellt';
        this.planVersionNotFound = 'Diese Planversion existiert nicht (mehr)';
        this.planVersionSinglePageOnly = 'Das PDF für eine neue Planversion darf nur eine Seite beinhalten';
        this.planVersions = 'Versionen';
        this.planVersionsUploadDirect = 'Neue Planversion als PDF oder Bild auswählen';
        this.planViewChangeToListView = 'Zur Tabellenansicht wechseln';
        this.plans = 'Pläne';
        this.plansActiveVersionDate = 'Aktive Version';
        this.plansAddFile = 'Datei zum Plan {{planTitle}} als neue Version hinzufügen?';
        this.plansAddFiles = '{{counted}} Dateien zum Plan {{planTitle}} hinzufügen?';
        this.plansCreateNewPlanQuestion = 'Ein neuen Plan erstellen?';
        this.plansCreatedDate = 'Erstellt';
        this.plansErrorAtDiscardAction = 'Fehler beim Verwerfen des Plans';
        this.plansErrorNotFound = 'Plan nicht gefunden';
        this.plansErrorTitleRequired = 'Der Plantitel wird benötigt';
        this.plansFormTitle = 'Plandaten bearbeiten';
        this.plansNotFound = 'Keine Pläne gefunden...';
        this.plansNotFoundDescription = 'Führen Sie eine neue Suche durch.';
        this.plansNotes = 'Anmerkungen';
        this.plansNumberOfVersions = 'Versionen';
        this.plansUploadHint = 'Laden Sie neue Pläne hoch, indem Sie auf den + Button drücken';
        this.plansUploadedNoPDFsFound = 'Keine Pläne zur Freigabe gefunden';
        this.plansUploadedTitle = 'Plan freigeben';
        this.plansUploadedWaitingForRelease = 'Es stehen hochgeladene Pläne zur Freigabe aus.';
        this.pleaseSelect = 'Bitte wählen';
        this.prev = 'vorheriges';
        this.preview = 'Vorschau';
        this.proProject = 'Pro-Projekt';
        this.proReportComments = 'Kommentare';
        this.proReportCreatedBy = 'Erstellt von {{user}}';
        this.proReportCreatedWith = 'Erstellt mit {{creator}}';
        this.proReportDescription = 'Beschreibung';
        this.proReportProvidedBy = 'Bereitgestellt von {{user}}';
        this.proReportTableOfContents = 'Inhaltsverzeichnis';
        this.profile = 'Profil';
        this.profilePersonalDataAccountDeleteButten = 'KONTO LÖSCHEN';
        this.profilePersonalDataAccountDeleteInformation = 'Das Löschen des Kontos kann' +
            ' nicht rückgängig gemacht werden! Sie verlieren Zugang zu all Ihren Projekten. Ihre' +
            ' persönlichen Daten werden gelöscht.';
        this.profilePersonalDataAccountTitle = 'Konto';
        this.profilePersonalDataAddEmailAddressButton = 'HINZUFÜGEN';
        this.profilePersonalDataAddEmailAddressComfirmMessage = 'E-Mail-Adresse wirklich löschen?';
        this.profilePersonalDataAddEmailAddressIsNotValidated = 'E-Mail noch nicht verifiziert';
        this.profilePersonalDataAddEmailAddressNormalCase = 'E-Mail-Adresse hinzufügen';
        this.profilePersonalDataAddEmailAddressSendNotification = 'an diese E-Mail Benachrichtigung senden';
        this.profilePersonalDataAddEmailAdressText = 'E-Mail-Adresse';
        this.profilePersonalDataAddEmailAdressUpperCase = 'E-MAIL-ADRESSE HINZUFÜGEN';
        this.profilePersonalDataAddress = 'Adresse';
        this.profilePersonalDataAddressCity = 'Stadt';
        this.profilePersonalDataAddressCountry = 'Land';
        this.profilePersonalDataAddressStreet = 'Straße';
        this.profilePersonalDataAddressStreetAndNumber = 'Straße und Hausnr.';
        this.profilePersonalDataAddressStreetNumber = 'Hausnr.';
        this.profilePersonalDataAddressZipCode = 'PLZ';
        this.profilePersonalDataChangePassword = 'PASSWORT ÄNDERN';
        this.profilePersonalDataChangePasswordComfirm = 'Passwort bestätigen';
        this.profilePersonalDataChangePasswordNew = 'Neues Passwort';
        this.profilePersonalDataCompany = 'Firma';
        this.profilePersonalDataCompleteName = 'Name';
        this.profilePersonalDataEmailTitle = 'E-Mail-Adressen';
        this.profilePersonalDataGivenName = 'Vorname';
        this.profilePersonalDataImageChoose = 'Profilfoto auswählen';
        this.profilePersonalDataImageDelete = 'Löschen';
        this.profilePersonalDataImageDrag = 'Bild hierein ziehen oder';
        this.profilePersonalDataImageTake = 'Foto aufnahmen';
        this.profilePersonalDataImageUpload = 'Bild hochladen';
        this.profilePersonalDataPosition = 'Position';
        this.profilePersonalDataSurName = 'Nachname';
        this.profilePersonalDataTitle = 'Persönliche Daten';
        this.project = 'Projekt';
        this.projectAddCompanyUserToTeamTitle = 'Firmen-Mitarbeiter hinzufügen';
        this.projectAddCraftDialogTitle = 'Gewerk hinzufügen';
        this.projectAddFileTypeDialogTitle = 'Kategorie hinzufügen';
        this.projectAddTagDialogTitle = 'Tag hinzufügen';
        this.projectAddTeamTitle = 'Personen einladen';
        this.projectAddTicketTypeDialogTitle = 'Tickettyp hinzufügen';
        this.projectAddUserGroupDialogTitle = 'Firma hinzufügen';
        this.projectAddress = 'Projektadressen';
        this.projectAddressForWeather = 'Fügen Sie einen Projektkontakt hinzu, um auf Wetterdaten zugreifen zu können';
        this.projectArchiveProFunction = 'Projekt archivieren ist eine PRO Funktion';
        this.projectArchiveProFunctionButton = 'Okay';
        this.projectArchiveProFunctionDescription = 'Archivieren Sie Ihr Projekt, um es später wieder reaktivieren zu können.';
        this.projectArchiveProject = 'Projekt archivieren';
        this.projectArchiveProjectDialogButton = 'Archivieren';
        this.projectArchiveProjectDialogText = 'Wenn Sie dieses Projekt archivieren, ist es für alle Projektbeteiligten' +
            ' archiviert. Sie können archivierte Projekte jederzeit wieder reaktivieren. Nutzen Sie dafür in der' +
            ' Projektübersicht den Filter zur Anzeige aller archivierten Projekte und wählen Sie anschließend das jeweilige' +
            ' Projekt aus. \n\nBite bestätigen Sie, dass Sie das Projekt archivieren möchten.';
        this.projectArchiveProjectDialogTextCompany = 'Wenn Sie dieses Projekt archivieren, ist es für alle Projektbeteiligten' +
            ' archiviert. Sie können archivierte Projekte jederzeit wieder reaktivieren. Gehen Sie dafür zur' +
            ' Firmen-Projektübersicht und wählen Sie das jeweilige Projekt aus.' +
            ' \n\nBite bestätigen Sie, dass Sie das Projekt archivieren möchten.';
        this.projectArchived = 'Das Projekt ist archiviert';
        this.projectBuildingEnd = 'Bauende';
        this.projectBuildingPeriod = 'Bauzeitraum';
        this.projectBuildingStart = 'Baubeginn';
        this.projectChangeCraftDialogTitle = 'Gewerk bearbeiten';
        this.projectChangeFileTypeDialogTitle = 'Kategorie bearbeiten';
        this.projectChangePhoneHelperText = 'Diese Telefonnummer ist nur in diesem Projekt sichtbar';
        this.projectChangeRoleTitle = 'Mitglied ändern';
        this.projectChangeRoleTitleFrom = 'Mitglied "{{userName}}" ändern';
        this.projectChangeRoleTitleMe = 'Meine Rolle oder Firma ändern';
        this.projectChangeTicketTypeDialogTitle = 'Tickettyp bearbeiten';
        this.projectChangeUserGroupDialogTitle = 'Firma bearbeiten';
        this.projectColor = 'Farbe Projekt-Icon';
        this.projectContactLabel = 'Projektkontakt';
        this.projectContactLabelWidget = 'Ansprechpartner';
        this.projectContactWidgetAddTitle = 'Ansprechpartner hinzufügen';
        this.projectContactWidgetChangeTitle = 'Ansprechpartner ändern';
        this.projectContactWidgetSubTitle = 'Für alle Projektbeteiligten sichtbar';
        this.projectCopyProject = 'Projekt kopieren';
        this.projectCopyProjectDialogButton = 'Kopieren';
        this.projectCopyProjectDialogProjectCopyMember = 'Projektmitglieder';
        this.projectCopyProjectDialogProjectCopyPlans = 'Pläne';
        this.projectCopyProjectDialogProjectCopyText = 'Folgende Inhalte werden kopiert:';
        this.projectCopyProjectDialogProjectCopyTicketLayouts = 'Ticketlayouts';
        this.projectCopyProjectDialogProjectCopyTickets = 'Tickets';
        this.projectCopyProjectDialogProjectFolderAndFiles = 'Ordner & Dateien';
        this.projectCopyProjectDialogProjectJournalSettings = 'Berichtseinstellungen';
        this.projectCopyProjectDialogProjectTitle = 'Projektname';
        this.projectCopyProjectSuccessToast = 'Das Projekt wird kopiert. Sobald der Kopiervorgang vollständig abgeschlossen ist, erhalten Sie eine eMail.';
        this.projectCostUnit = 'Kostenträger';
        this.projectCraftAlreadyExists = 'Das Gewerk existiert bereits im Projekt';
        this.projectCraftChangeErrorAssignedToMinOneTicket = 'Das Gewerk ist mindestens einem Ticket zugeordnet und kann deswegen nicht geändert werden';
        this.projectCraftNotExists = 'Das Gewerk existiert nicht im Projekt';
        this.projectCraftRemoveErrorAssignedToMinOneTicket = 'Das Gewerk ist mindestens einem Ticket zugeordnet und kann deswegen nicht gelöscht werden';
        this.projectCreateTitle = 'Neues Projekt';
        this.projectCreationOwnerCompany = 'Firma (Enterprise Projekt)';
        this.projectCreationOwnerMe = 'Ich';
        this.projectCreationOwnerMe_basic = 'Ich (Basic Projekt)';
        this.projectCreationOwnerMe_pro = 'Ich (Pro Projekt)';
        this.projectCreator = 'Eigentümer';
        this.projectCreatorNotOwner = 'Projektersteller';
        this.projectDeleteProject = 'Projekt löschen';
        this.projectDeleteProjectDialogButton = 'Löschen';
        this.projectDeleteProjectDialogConfirmaionText = 'Löschen bestätigen';
        this.projectDeleteProjectDialogDownloadButton = 'Projekt als ZIP-Datei herunterladen';
        this.projectDeleteProjectDialogText = 'Achtung: Diese Aktion kann nicht rückgängig gemacht werden.\n\nWenn Sie dieses' +
            ' Projekt löschen, werden alle Daten dauerhaft gelöscht und die Projektbeteiligten haben keinen Zugriff mehr auf' +
            ' das Projekt. Laden Sie sich das Projekt vorab als ZIP-Datei herunter, um es zu sichern.\n\nTipp: Archivieren' +
            ' Sie abgeschlossene Projekte.';
        this.projectDeleted = 'Das Projekt existiert nicht mehr';
        this.projectDeletionMustBeConfirmed = 'Löschen muss bestätigt werden';
        this.projectDescription = 'Projektbeschreibung';
        this.projectDetailsSave = 'Projektdetails speichern';
        this.projectDone = 'Projekt fertig';
        this.projectDownload = 'Offline Verfügbarkeit';
        this.projectDownloadAllPlans = 'alle aktiven Planversionen';
        this.projectDownloadCurrentConnection = 'aktuelle Verbindung';
        this.projectDownloadDialogText = 'Laden Sie das Projekt "{{projectTitle}}" herunter, um auch ohne aktive' +
            ' Internetverbindung Zugriff auf alle Projektdaten zu haben. Während des Downloads darf die App nicht geschlossen' +
            ' werden.';
        this.projectDownloadDownloaded = 'heruntergeladen';
        this.projectDownloadError = 'Fehler beim erstellen der ZIP-Datei';
        this.projectDownloadFiles = 'Dateien';
        this.projectDownloadHint = 'Projekt als ZIP-Datei herunterladen';
        this.projectDownloadHowToButton = 'Okay';
        this.projectDownloadHowToDescription = 'Klicken Sie in der App im Bereich der Projektliste auf das Wolkensymbol, um die Daten zu synchronisieren.';
        this.projectDownloadHowToHeadline = 'Projekt offline nutzen';
        this.projectDownloadLoading = 'Erstelle Archiv';
        this.projectDownloadMaybeLater = 'Vielleicht später';
        this.projectDownloadNow = 'Okay';
        this.projectDownloadOfflineAvailable = 'Offline verfügbar';
        this.projectDownloadOfflineAvailableSyncing = 'syncronisiert';
        this.projectDownloadOfflineUnavailable = 'Offline nicht verfübar';
        this.projectDownloadProFunction = 'Projekt exportieren ist eine PRO Funktion';
        this.projectDownloadProFunctionButton = 'Okay';
        this.projectDownloadProFunctionDescription = 'Exportieren Sie alle Pläne, Tickets, Fotos und weitere' +
            ' Projektdaten in nur einer ZIP-Datei. Jetzt upgraden und auf noch weitere Funktionen sowie einen erweiterten' +
            ' Datenspeicher freuen.';
        this.projectDownloadUseMobileData = 'auch mobile Daten nutzen?';
        this.projectDownloadWaitForDownload = 'warte auf Download...';
        this.projectEditTitle = 'Projektdetails bearbeiten';
        this.projectEnd = 'Projektende';
        this.projectErrorConstructionStartAfterEnd = 'Der Baubeginn sollte vor dem Ende sein';
        this.projectErrorInvalidColor = 'Die Projektfarbe ist nicht valide.';
        this.projectErrorIsMarkedAsTemplate = 'Das Projekt ist als Vorlage markiert und kann nur von Projektleitern bearbeitet werden.';
        this.projectErrorProOrCollaboratorNeededForRole = 'Für Ihre Rolle in dem Projekt benötigen Sie eine Mitarbeiterlizenz oder einen Pro-Account. Bitte wenden Sie' +
            ' sich an den Projekteigentümer {{ownerName}}, damit er Ihre Rolle ändert oder upgraden Sie Ihren Account!';
        this.projectErrorProOrEnterpriseNeededForRole = 'Für Ihre Rolle in dem Projekt benötigen Sie eine Projektleiterlizenz oder einen Pro-Account. Bitte wenden Sie' +
            ' sich an den Projekteigentümer {{ownerName}}, damit er Ihre Rolle ändert oder upgraden Sie Ihren Account!';
        this.projectErrorProjectStartAfterEnd = 'Der Projektstart muss vor dem Projektende sein';
        this.projectErrorTemplateNotFound = 'Das Vorlagenprojekt wurde nicht gefunden';
        this.projectErrorTitleRequired = 'Der Projektname wird benötigt';
        this.projectErrorTitleToLong = 'Der Projektname ist zu lang';
        this.projectErrorTooManyTickets = 'maximal 500 Tickets können kopiert werden';
        this.projectErrorTypeToLong = 'Die Projektart ist zu lang';
        this.projectFileInvalidFilename = 'Der neue Dateiname ist ungültig';
        this.projectFileTypeAlreadyExists = 'Die Kategorie existiert bereits im Projekt';
        this.projectFileTypeChangeErrorAssignedToMinOneFile = 'Die Kategorie ist mindestens einer Datei zugeordnet und kann deswegen nicht geändert werden';
        this.projectFileTypeNoDataHint = 'Drücken Sie auf den + Button, um eine neue Kategorie zu erstellen';
        this.projectFileTypeNoDataText = 'Keine Kategorie vorhanden';
        this.projectFileTypeNotExists = 'Die Kategorie existiert nicht im Projekt';
        this.projectFileTypeRemoveErrorAssignedToMinOneFile = 'Die Kategorie ist mindestens einer Datei zugeordnet und kann deswegen nicht gelöscht werden';
        this.projectFilterArchived = 'Eigene archivierte Projekte anzeigen';
        this.projectFilterTemplateAll = 'Vorlagen und Projekte';
        this.projectFilterTemplateJustProjects = 'Nur Projekte';
        this.projectFilterTemplateJustTemplate = 'Nur Vorlagen';
        this.projectFilterTemplatePicker = 'Projekt Vorlagen';
        this.projectId = 'Projekt';
        this.projectImageDeleteQuestion = 'Möchten Sie das Projektbild wirklich löschen?';
        this.projectImageDropHint = 'Projektbild ändern';
        this.projectImageRemoveHint = 'Projektbild löschen';
        this.projectInCreation = 'Das Projekt ist noch nicht vollständig kopiert';
        this.projectInfo = 'Projektinfo';
        this.projectInfos = 'Projektinfos';
        this.projectLocked = 'Das Projekt ist gesperrt. Bitte kontaktiere den Projekteigentümer {{ownerName}} oder verlasse das Projekt.';
        this.projectLockedOwner = 'Dieses Projekt ist für alle Beteiligten gesperrt. Bitte aktualisieren Sie Ihr Abonnement, um' +
            ' das Projekt für alle Beteiligten wieder freizuschalten.';
        this.projectManager = 'Projektleiter';
        this.projectMaximumSizeReached = 'Das Projekt hat die maximale Größe erreicht und ist schreibgeschützt. ';
        this.projectMember = 'Projektmitglied';
        this.projectMembers = 'Projektmitglieder';
        this.projectName = 'Projektname';
        this.projectNotInCostUnit = 'Das Projekt ist dem Kostenträger nicht zugeordnet';
        this.projectOption24HourPhotoEditable = 'Fotos und Dateien im Ticket sind nach dem Hochladen 24 Stunden lang bearbeitbar';
        this.projectOptionFilesEditableTickets = 'Fotos und Dateien im Ticket sind nach dem Hochladen bearbeitbar';
        this.projectOptionFilesEditableTickets24h = '24 Stunden lang';
        this.projectOptionFilesEditableTicketsEver = 'jederzeit';
        this.projectOptionFilesEditableTicketsNever = 'niemals';
        this.projectOptions = 'Optionen';
        this.projectOverview = 'Allgemein';
        this.projectOwner = 'Projekteigentümer';
        this.projectOwnerChangeAction = 'Projekt übergeben';
        this.projectOwnerChangeDialogText = 'Sie werden dann als Projektleiter zu dem Projekt hinzugefügt und können das Projekt danach verlassen';
        this.projectOwnerChangeDialogTitle = 'Projekt "{{projectName}}" an "{{username}}" übergeben';
        this.projectOwnerTransferConfirmation = 'Übergabe bestätigen';
        this.projectPeriod = 'Projektzeitraum';
        this.projectRemoveCraftQuestion = 'Möchten Sie das Gewerk "{{craft}}" wirklich löschen?';
        this.projectRemoveFileTypeQuestion = 'Möchten Sie die Kategorie "{{fileType}}" wirklich löschen?';
        this.projectRemoveTagQuestion = 'Möchten Sie das Tag "{{tag.tagName}}" wirklich löschen?';
        this.projectRemoveTicketLayoutQuestion = 'Möchten Sie das Layout "{{title}}" wirklich löschen?';
        this.projectRemoveTicketTypeQuestion = 'Möchten Sie den Typ "{{type}}" wirklich löschen?';
        this.projectRemoveUserGroupQuestion = 'Wollen Sie die Firma "{{groupname}}" wirklich entfernen?';
        this.projectRoles = 'Projektrollen';
        this.projectSetting = 'Projekteinstellungen';
        this.projectSettings = 'Projekteinstellungen';
        this.projectSettingsAddType = 'Typ hinzufügen';
        this.projectSettingsChangeRole = 'Projekteinstellungen bearbeiten';
        this.projectSettingsContainedIn = 'Enthalten in';
        this.projectSettingsCrafts = 'Gewerke';
        this.projectSettingsFileTypes = 'Kategorien';
        this.projectSettingsName = 'Name';
        this.projectSettingsTags = 'Tags';
        this.projectSettingsTicketLayoutFields = 'Felder';
        this.projectSettingsTicketLayoutFieldsRemovedText = 'Ticketfelder die in diesen Feldern Inhalte haben, werden zurückgesetzt';
        this.projectSettingsTicketLayoutFieldsRemovedTitle = 'Felder "{{fields}}" wurden entfernt';
        this.projectSettingsTicketLayoutOnTypes = 'Tickettypen';
        this.projectSettingsTicketLayoutRuleAnd = 'und hinterlegen Sie für folgende Felder einen Wert in der Bedingung: ';
        this.projectSettingsTicketLayoutRuleDeleteAndUpdateError = 'Diese Option wird derzeit als Regel verwendet. Bitte entfernen Sie sie zuerst aus der Regel, bevor Sie sie hier aktualisieren oder löschen.';
        this.projectSettingsTicketLayoutRuleMissingField = 'Fehlendes Feld';
        this.projectSettingsTicketLayoutRuleMissingFieldAndValue = 'Fehlendes Feld und Wert';
        this.projectSettingsTicketLayoutRuleMissingFieldText = 'Bitte hinterlegen Sie für folgende Felder ein Feld in der Bedingung: ';
        this.projectSettingsTicketLayoutRuleMissingValue = 'Fehlender Wert';
        this.projectSettingsTicketLayoutRuleMissingValueText = 'Bitte hinterlegen Sie für folgende Felder einen Wert in der Bedingung: ';
        this.projectSettingsTypes = 'Tickettypen';
        this.projectSize = 'Projektgröße';
        this.projectStart = 'Projektstart';
        this.projectState = 'Projektstatus';
        this.projectStateAddTitle = 'Projektstatus hinzufügen';
        this.projectStateChangeTitle = 'Projektstatus ändern';
        this.projectStateDeleteQuestion = 'Den Projektstatus {{state}} wirklich löschen?';
        this.projectStateInformation = 'Diese Liste dient als eine Auswahl für den Projektstatus. Dieser überschreibt die Projektfarbe, ist allerdings' +
            ' nur innerhalb der Firma sichtbar';
        this.projectStateName = 'Statusname';
        this.projectStateNoDataHint = 'Drücken Sie auf den + Button, um einen neuen Projektstatus zu erstellen';
        this.projectStateNoDataText = 'Kein Projektstatus vorhanden';
        this.projectStateNoState = 'Kein Projektstatus';
        this.projectStateOrder = 'Reihenfolge';
        this.projectSubscription = 'Projekt Abonnement';
        this.projectTagAlreadyExists = 'Das Tag existiert bereits im Projekt';
        this.projectTagChangeErrorAssignedToMinOnePlan = 'Das Tag ist mindestens einem Plan zugeordnet und kann deswegen nicht geändert werden';
        this.projectTagChangeErrorAssignedToMinOneTicket = 'Das Tag ist mindestens einem Ticket zugeordnet und kann deswegen nicht geändert werden';
        this.projectTagNotExists = 'Das Tag existiert nicht im Projekt';
        this.projectTagRemoveErrorAssignedToMinOnePlan = 'Das Tag ist mindestens einem Plan zugeordnet und kann deswegen nicht gelöscht werden';
        this.projectTagRemoveErrorAssignedToMinOneTicket = 'Das Tag ist mindestens einem Ticket zugeordnet und kann deswegen nicht gelöscht werden';
        this.projectTeamCompanyMember = 'Mitglieder';
        this.projectTeamCompanyName = 'Name';
        this.projectTemplateStateHeader = 'Vorlagenstatus:';
        this.projectTemplateStateText = 'Projekt ist eine Vorlage';
        this.projectTemplateTitle = 'Projekt als Vorlage markieren';
        this.projectTemplateUnmarkTitle = 'Projekt nicht mehr als Vorlage markieren';
        this.projectTemplateWarning = 'Wenn Sie das Projekt als Vorlage markieren, wird es für Nicht-Projektleiter' +
            ' schreibgeschützt und taucht nicht mehr unter den normalen Projekten auf.';
        this.projectTicketLayoutAlreadyExists = 'Das Ticketlayout existiert bereits im Projekt';
        this.projectTicketLayoutNoDataHint = 'Drücken Sie auf den + Button, um eine neues Layout zu erstellen';
        this.projectTicketLayoutNoDataText = 'Keine Ticketlayouts vorhanden';
        this.projectTicketTypeAlreadyExists = 'Der Tickettyp existiert bereits im Projekt';
        this.projectTicketTypeChangeErrorAssignedToMinOneTicket = 'Der Tickettyp ist mindestens einem Ticket zugeordnet und kann deswegen nicht geändert werden';
        this.projectTicketTypeNotExists = 'Der Tickettyp existiert nicht im Projekt';
        this.projectTicketTypeRemoveErrorAssignedToMinOneTicket = 'Der Tickettyp ist mindestens einem Ticket zugeordnet und kann deswegen nicht gelöscht werden';
        this.projectTime = 'Projektzeit';
        this.projectTitle = 'Projekttitel';
        this.projectType = 'Projektart';
        this.projectTypeNoDataHint = 'Drücken Sie auf den + Button, um einen neuen Tickttypen zu erstellen';
        this.projectTypeNoDataText = 'Keine Tickettypen vorhanden';
        this.projectUnarchiveProject = 'Projekt reaktivieren';
        this.projectUnarchiveProjectDialogButtonDelete = 'Löschen';
        this.projectUnarchiveProjectDialogButtonDownload = 'Projekt als ZIP-Datei herunterladen';
        this.projectUnarchiveProjectDialogButtonReactivate = 'Reaktivieren';
        this.projectUnarchiveProjectDialogText = 'Wählen Sie eine Option aus:';
        this.projectUnarchiveProjectDialogTitle = 'Archiviertes Projekt';
        this.projectUpgradeDowngradeUserHint = 'Bis auf "Beobachter" und "Nachunternehmer", müssen alle Team Mitglieder einen Pro- oder Firmen-Account' +
            ' (Enterprise) besitzen. Da die folgenden Benutzer keinen Pro- oder Firmen-Account besitzen, wird ihre Rolle beim' +
            ' Upgrade zu "Beobachter" geändert:';
        this.projectUploadButtonHelperText = 'Dateien aus der Projetkablage auswählen';
        this.projectUploadButtonProjectFiles = 'aus Projektablage kopieren';
        this.projectUser = 'Teammitglieder';
        this.projectUserGroupAlreadyExists = 'Die Firma existiert bereits im Projekt';
        this.projectUserGroupNotExists = 'Die Firma existiert nicht im Projekt';
        this.projectUserGroupRemoveErrorAssignedToMinOneUser = 'Die Firma kann nicht entfernt werden, da sie mindestens einem Projekt Mitglied zugeordnet ist.';
        this.projectUsergroupNoDataHint = 'Drücken Sie auf den + Button, um eine neue Firma zu erstellen';
        this.projectUsergroupNoDataText = 'Keine Firmen vorhanden';
        this.projectsFilter = 'Projekte filtern';
        this.projectsWidgetShowMap = 'Karte anzeigen';
        this.projectsWidgetViewType = 'Ansicht';
        this.publishedLinkOverUpmesh = 'Freigabe über upmesh: {{link}}';
        this.publishedOverUpmesh = 'Freigabe über upmesh';
        this.pushDeleteQuestion = 'Möchten Sie das Gerät wirklich entfernen?';
        this.pushDeviceMobile = 'Android/iOS';
        this.pushDeviceWeb = 'Browser';
        this.pushNotification = 'Push-Benachrichtigungen';
        this.pushNotificationOnDevice = 'Auf diesem Gerät';
        this.pushNotificationOnDeviceDenied = 'Blockiert in Geräteeinstellungen';
        this.pushNotificationOnDeviceNotSupported = 'Nicht unterstützt auf diesem Gerät';
        this.pushOtherDevices = 'Weitere Geräte:';
        this.qualityGood = 'Mittel (3x)';
        this.qualityLow = 'sehr Klein (0.5x)';
        this.qualityNormal = 'Klein (1x)';
        this.qualityOptimal = 'Groß (6x)';
        this.qualityPlanGood = 'Mittel (600dpi)';
        this.qualityPlanLow = 'sehr Klein (180dpi)';
        this.qualityPlanNormal = 'Klein (360dpi)';
        this.qualityPlanOptimal = 'Groß (1200dpi)';
        this.quantity = 'Anzahl';
        this.range = 'Dauer';
        this.reSync = 'Synceinstellungen';
        this.recording = 'Aufnahme läuft: {{time}}';
        this.redo = 'wiederherstellen';
        this.region = 'Regionen';
        this.regions = 'Regionen';
        this.registerChangeEmail = 'E-Mail-Adresse ändern';
        this.registerErrorRequireEmail = 'E-Mail-Adresse wird benötigt';
        this.registerErrorRequireFirstName = 'Ihr Vorname wird benötigt';
        this.registerErrorRequireLastName = 'Ihr Nachname wird benötigt';
        this.registerErrorUserAlreadyExisting = 'E-Mail-Adresse ist bereits registriert';
        this.registerWithGoogle = 'Mit Google registrieren';
        this.release = 'freigeben';
        this.removeMyself = 'Entferne mich';
        this.removeTicketWatcherQuestion = 'Möchten Sie sich wirklich von der Beobachtungsliste entfernen?';
        this.removeTicketWatcherTitle = 'Ticket Beobachter';
        this.report = 'Bericht drucken';
        this.reportAttachment = 'Anhang';
        this.reportAttachmentButton = 'Anhangseiten als PDF auswählen';
        this.reportCoverPage = 'Titelseite';
        this.reportCoverPageText = 'Titelseite';
        this.reportCoverPageTextButton = 'Titelseite(n) als PDF auswählen';
        this.reportCreateButton = 'Bericht erstellen';
        this.reportCreated = 'Bericht erstellt';
        this.reportCreation = 'Bericht erstellen | {{x}} Tickets ausgewählt';
        this.reportDefaultName = 'upmesh Statusbericht';
        this.reportDescription = 'Beschreibung';
        this.reportErrorNotForFree = 'Diese Funktion ist in Basic-Projekten nicht verfügbar';
        this.reportGroup = 'Gruppe';
        this.reportGroupByLabel = 'Gruppieren nach';
        this.reportGroup_approverUserId = 'Prüfer';
        this.reportGroup_assignedToUserId = 'Zugewiesener';
        this.reportGroup_companyId = 'Firma';
        this.reportGroup_craft = 'Gewerk';
        this.reportGroup_projectId = 'Projekt';
        this.reportGroup_type = 'Typ';
        this.reportIncludeLinks = 'Links in Überschriften';
        this.reportLayout = 'Layout';
        this.reportLayoutMapLocalization = 'Kartenausschnitt pro Ticket anzeigen';
        this.reportLayoutPlan = 'Planübersicht pro Ticket anzeigen';
        this.reportLayoutPlanSnippet = 'Planausschnitt pro Ticket anzeigen';
        this.reportName = 'Name';
        this.reportPageBreakForTicket = 'Ticket auf neuer Seite beginnen';
        this.reportPicturesPerLineLabel = 'Bilder';
        this.reportPlanWithTicket = 'Plan mit Tickets in Bericht einbinden';
        this.reportQrCodes = 'QR codes anzeigen';
        this.reportReportLogo = 'Berichtslogo';
        this.reportReportLogoButton = 'Berichtslogo auswählen';
        this.reportSelectDescription = 'Beschreibung';
        this.reportSelectTitlepage = 'Titelseite';
        this.reportSettings = 'Berichtseinstellungen';
        this.reportShowToc = 'Inhaltsverzeichnis anzeigen';
        this.reportSortByLabel = 'Sortieren nach';
        this.reportTemplateTitleCreateNew = 'Neue Vorlage erstellen';
        this.reportTemplates = 'Berichtsvorlagen';
        this.reportTemplatesDateExamples = 'Verwenden von Feldern die Datumseinträge enhalten. Weitere Informationen zu Datumsfeldern unter:' +
            ' https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date';
        this.reportTemplatesDateExamplesAll = 'Datum inklusive Uhrzeit';
        this.reportTemplatesDateExamplesDay = 'Nur den Tag des Datums';
        this.reportTemplatesDateExamplesTime = 'Nur die Uhrzeit des Datums';
        this.reportTemplatesDialogSaveAsPdf = 'Als PDF speichern';
        this.reportTemplatesDialogTitle = 'Ticketbericht aus Vorlage';
        this.reportTemplatesDownloadExamples = 'Beispieldateien herunterladen';
        this.reportTemplatesNotesDialogText = 'Hier finden Sie die zur Verfügung stehenden Code-Snippets für Ihre Vorlage';
        this.reportTemplatesNotesDialogTextExec = 'Führt ein bestimmtes JavaScript-Snippet aus, genau wie INS oder =, fügt aber nichts in das Dokument ein. Sie ' +
            'können EXEC beispielsweise verwenden, um Funktionen oder Konstanten zu definieren, bevor Sie sie an anderer ' +
            'Stelle in Ihrer Vorlage verwenden.';
        this.reportTemplatesNotesDialogTextFor = 'Eine Schleife über eine Gruppe von Elementen:';
        this.reportTemplatesNotesDialogTextIf = 'Inhalte bedingt einschließen (abhängig von der Auswertung eines JavaScript-Ausdrucks):';
        this.reportTemplatesNotesDialogTextImage = 'Ein Bild in die Vorlage einfügen:';
        this.reportTemplatesNotesDialogTextIns = 'Eine Variable in die Vorlage einfügen:';
        this.reportTemplatesNotesDialogTextVars = 'Verfügbare Variablen ( Ticketbericht )';
        this.reportTemplatesNotesDialogTextVarsDaily = 'Verfügbare Variablen ( Tagesbericht )';
        this.reportTemplatesNotesDialogTitle = 'Berichtsvorlagen';
        this.reportTemplatesTitleChange = 'Berichtsvorlage "{{title}}" bearbeiten';
        this.reportTemplatesTitleCreate = 'Neue Berichtsvorlage erstellen';
        this.reportTicketActivities = 'Aktivitäten';
        this.reportTicketActivitiesCategories = 'Kategorien';
        this.reportTicketActivitiesComments = 'Kommentare';
        this.reportTicketActivitiesFileComment = 'Die Datei "{{filename}}" wurde zum Ticket hinzugefügt.';
        this.reportTicketActivitiesFilterCategories = 'Einbezogene Kategorien';
        this.reportTicketActivitiesFilterForCatHint = 'Kategorien wählen';
        this.reportTicketActivitiesLogbook = 'Verlauf';
        this.reportTicketActivitiesPDFsEmbed = 'PDFs einbetten';
        this.reportTicketActivitiesPhotos = 'Fotos';
        this.reportTicketActivitiesPhotosQuality = 'Fotoauflösung';
        this.reportTicketActivitiesPlansQuality = 'Planauflösung';
        this.reportTicketCreated = 'Erstellt';
        this.reportTicketDetails = 'Ticketdetails';
        this.reportTicketDetailsApprover = 'Prüfer';
        this.reportTicketDetailsAssignee = 'Zugewiesener';
        this.reportTicketDetailsCompletionOn = 'Fälligkeitsdatum';
        this.reportTicketDetailsCraft = 'Gewerk';
        this.reportTicketDetailsCreated = 'Erstellt';
        this.reportTicketDetailsDescription = 'Beschreibung';
        this.reportTicketDetailsDue = 'Fällig am';
        this.reportTicketDetailsLastChanged = 'letzte Änderung';
        this.reportTicketDetailsNumber = 'Ticketnummer';
        this.reportTicketDetailsObserver = 'Beobachter';
        this.reportTicketDetailsPlan = 'Plan';
        this.reportTicketDetailsStatus = 'Status';
        this.reportTicketDetailsTitle = 'Titel';
        this.reportTicketDetailsTags = 'Tags';
        this.reportTicketDetailsTyp = ' Typ';
        this.reportTicketDueOn = 'Fällig am';
        this.reportTicketGroupCompany = 'Unternehmen';
        this.reportTicketGroupNoGroup = 'Keine Gruppierung';
        this.reportTicketGroupProject = 'Projekt';
        this.reportTicketLastChanged = 'Letzte Änderung';
        this.reportTicketPhotoPerLine = '1 Foto pro Zeile';
        this.reportTicketPhotosPerLine = 'Fotos pro Zeile';
        this.reportTypedaily = 'Tagesbericht';
        this.reportTypeticket = 'Ticketbericht';
        this.reportWillBeCreated = 'Der Bericht wird erstellt...';
        this.required = 'Erforderlich';
        this.reset = 'Zurücksetzen';
        this.restoreTicket = 'Ticket wiederherstellen';
        this.restoreTicketShort = 'wiederherstellen';
        this.role = 'Rolle';
        this.roleDeleteQuestionText = 'Möchten Sie die Rolle wirklich löschen?';
        this.roleDeleteQuestionTitle = '"{{roleName}}" löschen?';
        this.roleDescriptioncollaborator = '• kann Tickets erstellen\n• kann alle Tickets sehen und bearbeiten\n• hat Zugriff auf die Planverwaltung\n';
        this.roleDescriptionprojectManager = '• hat vollen Zugriff auf das Projekt';
        this.roleDescriptionsubcontractor = '• kann Tickets erstellen\n• kann nur ihm zugewiesene Tickets sehen\n• hat' +
            ' keinen Zugriff auf die Planverwaltung\n';
        this.roleDescriptionwatcher = '• kann nichts erstellen und bearbeiten\n• kann alles sehen';
        this.roleWithNameExists = 'Es existiert bereits eine Rolle mit diesem Namen';
        this.roles = 'Rollen';
        this.rolesActions = 'Aktionen';
        this.rolesAddDialogActionButton = 'Erstellen';
        this.rolesAddDialogBaseroleLabel = 'Basiert auf Rolle';
        this.rolesAddDialogRolenameLabel = 'Rollenname';
        this.rolesAddDialogTitle = 'Rolle erstellen';
        this.rolesChangeDialogActionButton = 'ändern';
        this.rolesChangeDialogRolenameLabel = 'Rollenname';
        this.rolesChangeDialogTitle = 'Rolle ändern';
        this.rolesInformationText = 'Die Standardrollen sind nicht editierbar. Benutzerdefinierte Rollen basieren immer auf einer Standardrollen, wobei die Berechtigungen nur weiter eingeschränkt werden können, nicht erweitert.';
        this.rolesOnlyInEnterprise = 'Rollen können nur in Firmen-Projekten erstellt werden';
        this.rolesReadRight_allTickets = 'Alle Tickets anzeigen';
        this.rolesReadRight_allTickets_description = 'Darf alle Tickets des Projektes einsehen';
        this.rolesReadRight_logs = 'Logbucheinträge anzeigen';
        this.rolesReadRight_logs_description = 'Darf alle Logbucheinträge des Projektes einsehen';
        this.rolesReadRight_plans = 'Planverwaltung';
        this.rolesReadRight_plans_description = 'Darf alle Pläne der Planverwaltung einsehen';
        this.rolesReadRight_projectSettings = 'Projekteinstellungen';
        this.rolesReadRight_projectSettings_description = 'Darf die Projekteinstellungen einsehen';
        this.rolesReadRight_teams = 'Team';
        this.rolesReadRight_teams_description = 'Darf alle Projektbeteiligten einsehen';
        this.rolesReadRights = 'Leserechte';
        this.rolesWriteRights = 'Schreib-/Änderungsrechte';
        this.rotate = 'Drehen';
        this.rotateLeft = 'Um 90° nach links drehen';
        this.rotateRight = 'Um 90° nach rechts drehen';
        this.save = 'Speichern';
        this.saveChanges = 'Änderungen speichern';
        this.saveCopy = 'Kopie speichern';
        this.search = 'Suchen';
        this.searchFiles = 'Dateien suchen';
        this.searchPlans = 'Suchen';
        this.searchProjects = 'Suchen';
        this.searchTickets = 'Suchen';
        this.searching = 'Suche...';
        this.selectAll = 'Alle auswählen';
        this.selectFile = 'Datei auswählen';
        this.selectNone = 'Keine auswählen';
        this.selectProject = 'Projekt auswählen';
        this.selected = 'ausgewählt';
        this.selectorFolderPrivate = 'privater Ordner';
        this.selectorFolderPublic = 'Projektablage';
        this.selectorPlans = 'neuer oder vorhandener Plan';
        this.selectorTickets = 'neues oder vorhandenes Ticket';
        this.send = 'senden';
        this.sendNotificationsInCases = 'Senden Sie mir in folgenden Fallen Benachrichtgungen:';
        this.settingsClusterMarker = 'Clustern von Ticketmarkierungen';
        this.settingsImageEditor = 'Editor nach Kameraaufnahme';
        this.settingsRegional = 'Regionale Einstellungen';
        this.share = 'Freigeben';
        this.shareAccessCount = 'Zugriffe';
        this.shareCopy = 'Zwischenablage';
        this.shareCopyHelper = 'Freigabelink wurde in die Zwischenablage kopiert:';
        this.shareCreateButton = 'Link erzeugen';
        this.shareDelete = 'löschen';
        this.shareEmail = 'Per E-Mail teilen';
        this.shareExpireText = 'Ablaufdatum';
        this.shareHeader = '{{fileOrFolder}} teilen';
        this.shareHelper = 'Z.B. Freigabegrund, Empfänger, Person oder Firma';
        this.shareMobile = 'Link teilen';
        this.shareName = 'Titel für Freigabe';
        this.shares = 'Freigaben';
        this.show = 'ansehen';
        this.showLess = 'Weniger anzeigen';
        this.showMap = 'Karte anzeigen';
        this.showMore = 'Mehr anzeigen';
        this.showMoreSubscriptionInfo = 'Weitere Abonnementinformationen anzeigen';
        this.showRuleFields = 'Felder anzeigen bei einem dieser Werte: ';
        this.signUpErrorUserAlreadyExisting = 'Benutzer existiert bereits';
        this.sort = 'sortieren';
        this.staffNumber = 'Personalnummer';
        this.startHeader = 'Startseiten';
        this.startHeaderGeneral = 'Startseite';
        this.startHeaderProjects = 'Projektstartseite';
        this.startallTickets = 'Alle Tickets';
        this.startdashboard = 'Dashboard';
        this.startfiles = 'Dateien';
        this.startproject = 'Dashboard';
        this.startprojects = 'Projekte';
        this.startprojectsettings = 'Einstellungen';
        this.starts = 'Start';
        this.startsDate = 'Datum';
        this.starttickets = 'Tickets';
        this.state = 'Status';
        this.statistics = 'Statistik';
        this.status = 'Status';
        this.stopTimeTrackingTitle = 'Erfassungen beenden';
        this.storedFile = 'Dateien';
        this.storedFileCopyAttachment = 'Kopie';
        this.storedFileCopyText = 'Datei duplizieren';
        this.storedFileFilterAddTeammember = 'Hinzufügen';
        this.storedFileFilterCreatedAt = 'Hochgeladen am';
        this.storedFileFilterExtensions = 'Dateiendung';
        this.storedFileFilterHeader = 'Dateifilter';
        this.storedFileFilterPersons = 'Hochgeladen von';
        this.stringToLong = 'zu lang';
        this.subTickets = 'Untergeordnete Tickets';
        this.subcontractor = 'Nachunternehmer';
        this.subcontractorInOwnCompany = 'Basic-Mitarbeiter';
        this.subscription = 'Abos';
        this.subscriptionButtonTextEnt = 'Kontaktieren';
        this.subscriptionButtonTextPro = 'Pro kaufen';
        this.subscriptionDescriptionBasic = 'Zum Testen unserer Funktionen.';
        this.subscriptionDescriptionEnt = 'Für Firmen, die auf Standards setzen.';
        this.subscriptionDescriptionPro = 'Für Profis, die unsere leistungsstarken Features nutzen.';
        this.subscriptionIndividual = 'Individuell';
        this.subscriptionManagement = 'Abonnementverwaltung';
        this.subscriptionManagementBasic = 'Basic';
        this.subscriptionManagementCurrent = 'Aktuell:';
        this.subscriptionManagementEnterprise = 'Enterprise (Firmen-Account)';
        this.subscriptionManagementLocked = 'gesperrt';
        this.subscriptionManagementLockedReasonPaymentEscalated = 'Zahlung nicht erhalten';
        this.subscriptionManagementPro = 'Pro';
        this.subscriptionManagementProAndEnterprise = 'Enterprise (Firmen-Account) und eigenen Pro Account';
        this.subscriptionManagementSeeMore = 'mehr Details anzeigen';
        this.subscriptionOrderTitle = 'Bestellung';
        this.subscriptionPaymentAllPricesWithoutSalesTaxes = 'Alle Preise zzgl. der ges. Umsatzsteuer.';
        this.subscriptionPaymentMethodMonthly = 'Monatlich abgerechnet';
        this.subscriptionPaymentMethodYearly = 'Jährlich abgerechnet';
        this.subscriptionPaymentPerUserAndMonth = 'pro Nutzer und Monat';
        this.subscriptionPaymentUserAndYear = 'Nutzer und Jahr';
        this.subscriptionPossibilitiesDescriptionBasic = 'Für bis zu 5 Projekte \n2 Projektrollen \nStandardbericht\n 250 MB Speicherplatz';
        this.subscriptionPossibilitiesDescriptionEnt = 'Unbegrenzte Projekte & Pläne \nUnbegrenzte Tickets & Fotos \nIndividuelle Berichte \nIndividuelle Projektrollen';
        this.subscriptionPossibilitiesDescriptionPro = 'Unbegrenzte Projekte & Tickets \nBericht mit eigenem Logo \n200' +
            ' GB Speicherplatz pro Projekt \nverschiedene Projektrollen';
        this.subscriptionPriceDescriptionBasic = 'Dauerhaft kostenfrei';
        this.subscriptionPriceDescriptionEnt = 'Jetzt Angebot einholen';
        this.subscriptionPriceDescriptionPro = 'pro Nutzer und Monat bei jährlicher Zahlungsweise | 59€ pro Nutzer und Monat bei monatlicher Zahlungsweise';
        this.subscriptionThankYou = 'Vielen Dank';
        this.subscriptionThankYouContinue = 'weiter';
        this.subscriptionThankYouText = 'Wir von upmesh sagen DANKE für das Upgrade Ihres Abos. Ab sofort können sie' +
            ' sich auf noch mehr Funktionen und einen erweiterten Datenspeicher freuen. Die Rechnung senden wir Ihnen per' +
            ' E-Mail zu.';
        this.subscriptionTypeBasic = 'Basic';
        this.subscriptionTypeEnt = 'Enterprise';
        this.subscriptionTypePro = 'Pro';
        this.subscriptionViewTitle = 'upmesh Preismodell';
        this.support = 'Support';
        this.syncCommands = 'Änderungen übertragen';
        this.syncOnLogin = 'Konto wird geladen...';
        this.systemFields = 'Systemfelder';
        this.tag = 'Tag';
        this.tagAbbrucharbeiten = 'Abbrucharbeiten';
        this.tagAbdichtung = 'Abdichtung';
        this.tagAbwasserinstallation = 'Abwasserinstallation';
        this.tagAnsicht = 'Ansicht';
        this.tagAntennenanlagen = 'Antennenanlagen';
        this.tagArchitekt = 'Architekt';
        this.tagArchitektur = 'Architektur';
        this.tagArmaturen = 'Armaturen';
        this.tagAufzüge = 'Aufzüge';
        this.tagAusfuehrungsplanung = 'Ausführungsplanung';
        this.tagAusführungsplanung = 'Ausführungsplanung';
        this.tagAußen = 'Außen';
        this.tagAußenanlagen = 'Außenanlagen';
        this.tagBauausfuehrung = 'Bauausführung';
        this.tagBauherr = 'Bauherr';
        this.tagBaustelleneinrichtung = 'Baustelleneinrichtung';
        this.tagBauüberwachtung = 'Bauüberwachtung';
        this.tagBeleuchtung = 'Beleuchtung';
        this.tagBelüftung = 'Belüftung';
        this.tagBestand = 'Bestand';
        this.tagBeton = 'Beton';
        this.tagBodenbeläge = 'Bodenbeläge';
        this.tagBrandschutz = 'Brandschutz';
        this.tagDG = 'DG';
        this.tagDachabdichtung = 'Dachabdichtung';
        this.tagDachbedeckung = 'Dachbedeckung';
        this.tagDetail = 'Detail';
        this.tagDämmung = 'Dämmung';
        this.tagEG = 'EG';
        this.tagEbene = 'Ebene';
        this.tagElektrik = 'Elektrik';
        this.tagElektrotechnik = 'Elektrotechnik';
        this.tagEntwurfsplanung = 'Entwurfsplanung';
        this.tagEntwässerung = 'Entwässerung';
        this.tagErdarbeiten = 'Erdarbeiten';
        this.tagErdbohrungen = 'Erdbohrungen';
        this.tagEstrich = 'Estrich';
        this.tagFassaden = 'Fassaden';
        this.tagFenster = 'Fenster';
        this.tagFernmeldeleitungen = 'Fernmeldeleitungen';
        this.tagFernmeldetechnik = 'Fernmeldetechnik';
        this.tagFliesen = 'Fliesen';
        this.tagFundamente = 'Fundamente';
        this.tagGalabau = 'Galabau';
        this.tagGarage = 'Garage';
        this.tagGasinstallation = 'Gasinstallation';
        this.tagGenehmigung47Behörde = 'Genehmigung/Behörde';
        this.tagGenehmigungBehoerde = 'Genehmigung/Behörde';
        this.tagGenehmigungsplanung = 'Genehmigungsplanung';
        this.tagGerüst = 'Gerüst';
        this.tagGroup = 'Gruppe';
        this.tagGroupBauteil = 'Bauteil';
        this.tagGroupDisziplin = 'Disziplin';
        this.tagGroupGeschoss = 'Geschoss';
        this.tagGroupGewerke = 'Gewerke';
        this.tagGroupPhase = 'Phase';
        this.tagGroupPlanart = 'Planart';
        this.tagGroupSonstiges = 'Sonstiges';
        this.tagGrundlagenermittlung = 'Grundlagenermittlung';
        this.tagGrundriss = 'Grundriss';
        this.tagGutachter = 'Gutachter';
        this.tagHaupt = 'Haupt';
        this.tagHaustechnik = 'Haustechnik';
        this.tagHeiztechnik = 'Heiztechnik';
        this.tagHeizung = 'Heizung';
        this.tagHochspannung = 'Hochspannung';
        this.tagHolzbau = 'Holzbau';
        this.tagIT45Technik = 'IT-Technik';
        this.tagKG = 'KG';
        this.tagKlempner = 'Klempner';
        this.tagKlima47Lüftung = 'Klima/Lüftung';
        this.tagKläranlagen = 'Kläranlagen';
        this.tagKorrosionsschutz = 'Korrosionsschutz';
        this.tagKüchentechnik = 'Küchentechnik';
        this.tagLageplan = 'Lageplan';
        this.tagLandschaftsbau = 'Landschaftsbau';
        this.tagMaler = 'Maler';
        this.tagMaurerwerk = 'Maurerwerk';
        this.tagMeldeanlagen = 'Meldeanlagen';
        this.tagMetallbau = 'Metallbau';
        this.tagMitwirkung_bei_der_Vergabe = 'Mitwirkung bei der Vergabe';
        this.tagNeben = 'Neben';
        this.tagNiederspannungsanlagen = 'Niederspannungsanlagen';
        this.tagNutzung = 'Nutzung';
        this.tagOG = 'OG';
        this.tagParksysteme = 'Parksysteme';
        this.tagPflanzen = 'Pflanzen';
        this.tagPutz47Stuck = 'Putz/Stuck';
        this.tagReinigung = 'Reinigung';
        this.tagRohrleitungen = 'Rohrleitungen';
        this.tagRollläden = 'Rollläden';
        this.tagRueckbau = 'Rückbau';
        this.tagRückbau = 'Rückbau';
        this.tagSanitär = 'Sanitär';
        this.tagSanitärinstallation = 'Sanitärinstallation';
        this.tagSchema = 'Schema';
        this.tagSchlussdokumentation = 'Schlussdokumentation';
        this.tagSchnitt = 'Schnitt';
        this.tagSchutzmassnahmen = 'Schutzmassnahmen';
        this.tagSonderplaner = 'Sonderplaner';
        this.tagSonnenschutz = 'Sonnenschutz';
        this.tagSprechanlagen = 'Sprechanlagen';
        this.tagStahlbau = 'Stahlbau';
        this.tagStatiker = 'Statiker';
        this.tagTG = 'TG';
        this.tagTapezierarbeiten = 'Tapezierarbeiten';
        this.tagTiefbau = 'Tiefbau';
        this.tagTischler = 'Tischler';
        this.tagTragwerksplanung = 'Tragwerksplanung';
        this.tagTrockenbau = 'Trockenbau';
        this.tagVergabe = 'Vergabe';
        this.tagVerglasung = 'Verglasung';
        this.tagVermessungstechnik = 'Vermessungstechnik';
        this.tagVorbereitung_der_Vergabe = 'Vorbereitung der Vergabe';
        this.tagVorentwurfsplanung = 'Vorentwurfsplanung';
        this.tagVorplanung = 'Vorplanung';
        this.tagWandplan = 'Wandplan';
        this.tagWasserhaltung = 'Wasserhaltung';
        this.tagWasserinstallation = 'Wasserinstallation';
        this.tagWerkstatt_und_Montageplanung = 'Werkstatt- und Montageplanung';
        this.tagWintergärten = 'Wintergärten';
        this.tagZimmerer = 'Zimmerer';
        this.tags = 'Tags';
        this.tagsAddGroup = 'Neue Tag-Gruppe';
        this.tagsAddGroupHelperText = 'Bestehende Gruppe auswählen oder neue erstellen';
        this.tagsAddGroupLabel = 'gehört zur Tag-Gruppe';
        this.tagsAddLabel = 'Tag Bezeichnung';
        this.tagsAddNewToGroup = '"{{tag}}" als neuen Tag zur Gruppe hinzufügen';
        this.tagsAddTagGroupTooMany = 'Maximale Anzahl an Gruppen erreicht';
        this.tagsAddTagTagAlreadyExists = 'Tag existiert bereits';
        this.tagsAddTagTitle = 'Tag hinzufügen';
        this.tagsAddTitle = 'Tag hinzufügen';
        this.tagsDelete = 'Tag "{tagName}" und alle Verknüfungen wirklich löschen?';
        this.tagsEditTagTitle = 'Tag ändern';
        this.tagsErrorAlreadyExists = 'Tag existiert bereits';
        this.tagsErrorColorRequired = 'Gruppenfarbe wird benötigt';
        this.tagsErrorDbError = 'Datenbank Fehler';
        this.tagsErrorGroupNotFound = 'Taggruppe nicht gefunden';
        this.tagsErrorGroupRequired = 'Taggruppe wird benötigt';
        this.tagsErrorNameRequired = 'Tag Name benötigt';
        this.tagsErrorNameToLong = 'Tagname zu lang';
        this.tagsErrorProjectNotFound = 'Projekt nicht gefunden';
        this.tagsErrorProjectRequired = 'Projekt benötigt';
        this.tagsGroupErrorAlreadyExists = 'Taggruppe existiert bereits';
        this.tagsGroupErrorColorRequired = 'Eine farbe wird für die Taggrupe benötigt';
        this.tagsGroupErrorDbError = 'Datenbankfehler';
        this.tagsGroupErrorNameRequired = 'Taggruppenname wird benötigt';
        this.tagsGroupErrorNameToLong = 'Taggruppenname zu lang';
        this.tagsGroupErrorNotColor = 'Keine Farbe';
        this.tagsSaveErrorExists = 'Tag "{tagName}" bereits vorhanden';
        this.tagsTaggroupColor = 'Gruppenfarbe';
        this.tagsTitle = 'Tagverwaltung';
        this.task = 'Tätigkeit';
        this.taskActive = 'Aktiv';
        this.taskDescription = 'Bezeichnung';
        this.taskFollowUpRequiredIfFollowUp2 = 'Folgelohnart 1 wird benötigt, wenn Folgelohnart 2 vorhanden ist';
        this.taskFollowUpWageType1 = 'Folgelohnart 1';
        this.taskFollowUpWageType2 = 'Folgelohnart 2';
        this.taskHasAbsencesItems = 'Die Tätigkeit hat erfasste Abwesenheiten und kann deswegen nicht gelöscht werden';
        this.taskHasTimeTrackedItems = 'Die Tätigkeit hat erfasste Zeiten und kann deswegen nicht gelöscht werden';
        this.taskId = 'Tätigkeit';
        this.taskInactive = 'Inaktiv';
        this.taskMark = 'Kennzeichen';
        this.taskProductive = 'Produktiv';
        this.taskStatus = 'Status';
        this.taskType = 'Tätigkeitsart';
        this.taskTypeEducation = 'Weiterbildung';
        this.taskTypeHoliday = 'Urlaub';
        this.taskTypeOther = 'Sonstiges';
        this.taskTypeOvertimeReduction = 'Überstundenabbau';
        this.taskTypeShortTimeWork = 'Kurzarbeit';
        this.taskTypeSick = 'Krank';
        this.taskTypeWinterFailure = 'Winterausfall';
        this.taskTypeWinterFailureViaSubstituteFund = 'Winterausfall über Ersatzkasse';
        this.taskTypeWork = 'Arbeitszeit';
        this.taskWageType = 'Lohnart';
        this.taskWageTypeRequiredIfFollowUp = 'Lohnart wird benötigt, wenn Folgelohnart vorhanden ist';
        this.tasks = 'Tätigkeiten';
        this.team = 'Team';
        this.teamAddMember = 'Teammitglied hinzufügen';
        this.teamAddPeople = 'Personen einladen';
        this.teamAddPeoplePlaceholder = 'E-Mail-Adresse';
        this.teamAddPeopleText = 'Laden Sie Nutzer per E-Mail zu Ihrem Projekt ein:';
        this.teamChangeRole = 'Rolle ändern';
        this.teamDeleteInvitation = 'Einladung verwerfen';
        this.teamFilterHeader = 'Projektmitglieder filtern';
        this.teamInvitationPending = 'Einladung ausstehend';
        this.teamInvite = 'einladen';
        this.teamLeaveProject = 'Projekt verlassen';
        this.teamMemberActive = 'Aktiv';
        this.teamMemberInvited = 'Eingeladen';
        this.teamMemberRemoved = 'Entfernt';
        this.teamMemberStatus = 'Status';
        this.teamNoMember = 'Keine Projektmitglieder';
        this.teamNoMemberHintCompany = 'Fügen Sie sich selbst über den + Button unten als Projektleiter hinzu';
        this.teamNoMemberText = 'Fügen Sie jetzt Teammitglieder hinzu um in diesem Projekt zu arbeiten';
        this.teamNoMemberTextCompany = 'Projektmitglieder sind erst sichtbar, wenn Sie Mitglied im Projekt sind';
        this.teamNoSearchResultHint = 'Kein Projektmitglied stimmt mit Ihrer Suche überein';
        this.teamNoSearchResultText = 'Keine Projektmitglieder gefunden';
        this.teamRemoveInvitation = 'Einladung zurückziehen';
        this.teamRemoveInvitationAlert = 'Möchten Sie die Einladung wirklich zurückziehen?';
        this.teamRemovePeople = 'Möchten Sie das Teammitglied "{{username}}" wirklich entfernen?';
        this.teamRemovePeopleMe = 'Möchten Sie das Projekt wirklich verlassen?';
        this.teamRemoveUser = 'Mitglied entfernen';
        this.teamRemoveUserShowTickets = 'Zugewiesene Tickets anzeigen';
        this.teamResendInvitation = 'Erneut senden';
        this.teamResendSuccess = 'Die E-Mail wurde erfolgreich versendet!';
        this.teamRolesBasedOn = 'Basiert auf: ';
        this.teamRolesFreeTooltip = 'Rolle für alle Lizenznehmer';
        this.teamRolesPaidOnlyTooltip = 'Rolle nur für Pro oder Enterprise Lizenznehmer';
        this.teamSearchMembers = 'Projektmitglieder suchen';
        this.testLang = 'test';
        this.thumb = 'Vorschau';
        this.ticket = 'Ticket';
        this.ticketChangePlanSelectPlanAbort = 'abbrechen';
        this.ticketChangePlanSelectPlanChangePlan = 'Plan ändern';
        this.ticketChangePlanSelectPlanSave = 'Position speichern';
        this.ticketChangePlanSelectPlanTitle = 'Wählen Sie einen Plan aus:';
        this.ticketChangeTypeWarningRemovedFieldValuesText = 'Die bereits ausgefüllten Felder “{{fields}}” werden gelöscht';
        this.ticketChangeTypeWarningRemovedFieldValuesTitle = 'Typ wirklich ändern?';
        this.ticketClient = 'Auftraggeber';
        this.ticketClientAddress = 'Auftraggeber Adresse';
        this.ticketClientEmail = 'Auftraggeber E-Mail';
        this.ticketClientPhone = 'Auftraggeber Telefon';
        this.ticketComments = 'Kommentare';
        this.ticketCopyFileToTicketErrorOffline = 'Die Datei ist zur Zeit Offline nicht verfügbar.';
        this.ticketDetailsCommentDeleted = 'Dieser Kommentar wurde gelöscht.';
        this.ticketDetailsCopiedLink = 'Kopiert!';
        this.ticketDetailsDueDate = 'Fälligkeitsdatum';
        this.ticketDetailsDuplicateTicket = 'Duplizieren';
        this.ticketFieldAddress = 'Baustellenadresse';
        this.ticketFiles = 'Dateien';
        this.ticketFilter = 'Tickets filtern';
        this.ticketFilterAddTeammember = 'Hinzufügen';
        this.ticketFilterApprovedBy = 'Prüfer';
        this.ticketFilterArchived = 'Archivierte Tickets anzeigen';
        this.ticketFilterAssignedTo = 'Zugewiesener';
        this.ticketFilterCompletionTill = 'Fällig bis';
        this.ticketFilterCreatedBy = 'Ersteller';
        this.ticketFilterDeleted = 'Gelöschte Tickets anzeigen';
        this.ticketFilterHideSubTickets = 'Sub-Tickets verbergen';
        this.ticketFilterLastModifiedBy = 'Letzter Bearbeiter';
        this.ticketFilterMyTickets = 'Meine Tickets';
        this.ticketFilterNotAssignedTickets = 'Nicht gesetzt';
        this.ticketFilterPersonRoles = 'Rolle';
        this.ticketFilterTicketHierarchyAll = 'Alle Tickets';
        this.ticketFilterTicketHierarchyLabel = 'Ticket Hierarchie';
        this.ticketFilterTicketHierarchyOnlyMain = 'Nur Haupttickets';
        this.ticketFilterTicketHierarchyOnlySub = 'Nur Subtickets';
        this.ticketFilterWatchedBy = 'Beobachter';
        this.ticketHistory = 'Verlauf';
        this.ticketImportedInformation = 'Diese Informationen wurden aus BPS Bau importiert';
        this.ticketLayoutCopyFromCompany = 'Aus Firmenvorlagen kopieren';
        this.ticketLayoutCopyFromProject = 'Aus Projekten kopieren';
        this.ticketLayoutCopyFromProjectTitle = 'Wählen Sie das zu kopierende Layout';
        this.ticketLayoutDiscardChangesQuestion = 'Nicht gespeicherte Inhalte vorhanden. Inhalte verwerfen?';
        this.ticketLayoutDuplicateFieldName = 'Doppelte Bezeichnungen eines Feldes sind nicht möglich: {{label}}';
        this.ticketLayoutEditApprover = 'Prüfer';
        this.ticketLayoutEditAssignee = 'Zugewiesenen';
        this.ticketLayoutEditText = 'Diese Feld darf zusätzlich zum Recht “Alle Tickets bearbeiten” geändert werden vom';
        this.ticketLayoutEditTypes = 'Tickettypen zuweisen';
        this.ticketLayoutEditTypesWarningDescription = 'Das Layout aller Tickets mit folgenden Typen wird geändert: {{types}}';
        this.ticketLayoutEditTypesWarningTitle = 'Zugewiesene Typen wirklich ändern?';
        this.ticketLayoutOptionsDateRange = 'Datumsbereich';
        this.ticketLayoutOptionsDateWithTime = 'Mit Uhrzeit';
        this.ticketLayoutOptionsDefaultValue = 'Standardwert';
        this.ticketLayoutOptionsDescription = 'Beschreibung (optional)';
        this.ticketLayoutOptionsDividerDescription = 'Beschreibung';
        this.ticketLayoutOptionsDividerTitle = 'Titel';
        this.ticketLayoutOptionsIdentifier = 'Technischer Name';
        this.ticketLayoutOptionsIdentifierHelperText = 'Zugriff auf die Werte des Feldes in Word Vorlagen, nur a-z verwenden';
        this.ticketLayoutOptionsLabel = 'Name';
        this.ticketLayoutOptionsListItems = 'Optionen';
        this.ticketLayoutOptionsMultiline = 'Mehrzeilig';
        this.ticketLayoutOptionsNumberDelimiter = 'Trennzeichen';
        this.ticketLayoutOptionsNumberDezimals = 'Nachkommastellen';
        this.ticketLayoutOptionsNumberMax = 'Maximum (optional)';
        this.ticketLayoutOptionsNumberMin = 'Minimum (optional)';
        this.ticketLayoutOptionsNumberUnit = 'Einheit (optional)';
        this.ticketLayoutOptionsNumberUnitHelperText = 'z.B. €, $, m, %';
        this.ticketLayoutOptionsProgressDefaultNotInRange = 'Standardwert muss im Wertebereich liegen';
        this.ticketLayoutOptionsProgressEnd = 'Ende';
        this.ticketLayoutOptionsProgressEndSmallerThanStart = 'Ende kann nicht kleiner als Start sein';
        this.ticketLayoutOptionsProgressStart = 'Start';
        this.ticketLayoutOptionsProgressStepWidth = 'Schrittweite (optional)';
        this.ticketLayoutOptionsProgressStepWidthBelowOne = 'Schrittweite muss größer als 0 sein';
        this.ticketLayoutOptionsTileEdit = 'Feld "{{title}}" bearbeiten';
        this.ticketLayoutOptionsTileNew = 'Feld erstellen';
        this.ticketLayoutRemoveFieldQuestion = 'Möchten Sie das Feld "{{title}}" wirklich löschen?';
        this.ticketLayoutTitleChange = 'Ticketlayout "{{title}}" bearbeiten';
        this.ticketLayoutTitleCreate = 'Ticketlayout erstellen';
        this.ticketLayoutTitleCreateNew = 'Neues Ticketlayout erstellen';
        this.ticketLayouts = 'Ticketlayouts';
        this.ticketMappingDialogAction = 'Übernehmen';
        this.ticketMappingDialogNew = 'Neu';
        this.ticketMappingDialogOld = 'Vorhanden';
        this.ticketMappingDialogText = 'Positionieren Sie die Tickets auf dem neuen Plan an den gleichen Stellen wie im vorhandenen Plan. ' +
            'Ziehen Sie dafür die Tickets auf die entsprechenden Positionen im neuen Plan.';
        this.ticketMappingDialogTextNoTickets = 'Möchten Sie die neue Planversion übernehmen?';
        this.ticketMappingDialogTitle = 'Ticket Verortung aktualisieren';
        this.ticketMappingDialogTitleNoTickets = 'Planversion übernehmen';
        this.ticketNoAccessRight = 'Sie haben keine Berechtigung (mehr) für den Zugriff auf das Ticket';
        this.ticketReadViewDescription = 'Beschreibung';
        this.ticketReadViewDueDate = 'Fälligkeitsdatum';
        this.ticketReadViewTitle = 'Tickettitel';
        this.ticketReportByTemplate = 'Vorlagen';
        this.ticketReportDefault = 'Standardbericht';
        this.ticketState = 'Ticket Status';
        this.ticketStateChangeTitle = 'Status "{{originalState}}" bearbeiten';
        this.ticketStateCustom = 'Eigener';
        this.ticketStateOrder = 'Nr.';
        this.ticketStateOriginal = 'Original';
        this.ticketStatusInvalid = 'Ungültiger Ticketstatus';
        this.ticketSubordinate = 'Untergeordnet';
        this.ticketSuperordinate = 'Übergeordnet';
        this.ticketType = 'Tickettyp';
        this.ticketTypeAddendum = 'Nachtrag';
        this.ticketTypeClaim = 'Nachtrag';
        this.ticketTypeClientRequest = 'Bauherrenwunsch';
        this.ticketTypeConditionAssessment = 'Zustandsfeststellung';
        this.ticketTypeDefect = 'Mangel';
        this.ticketTypeDocumentation = 'Dokumentation';
        this.ticketTypeIndication = 'Hinweis';
        this.ticketTypeInventory = 'Bestandsaufnahme';
        this.ticketTypeNotSelected = 'Kein Typ';
        this.ticketTypeNote = 'Hinweis';
        this.ticketTypeObstruction = 'Behinderung';
        this.ticketTypeOutstandingWork = 'Restleistung';
        this.ticketTypeTask = 'Aufgabe';
        this.ticketTypeWarrantyDeficiency = 'Gewährleistungsmangel';
        this.ticketTypes = 'Tickettypen';
        this.ticketTypesShort = 'Typen';
        this.tickets = 'Tickets';
        this.ticketsAddFile = 'Datei zum Ticket {{ticketTitle}} hinzufügen?';
        this.ticketsAddFiles = '{{counted}} Dateien zum Ticket {{ticketTitle}} hinzufügen?';
        this.ticketsAddImage = 'Bild zum Ticket hinzufügen?';
        this.ticketsAddImages = '{{counted}} Bilder zum Ticket hinzufügen?';
        this.ticketsAddTicket = 'Ticket erstellen';
        this.ticketsCalendarMoveNoDateError = 'Das Ticket hat kein Datumsfeld';
        this.ticketsCalendarMoveWhichDate = 'Welches Datum soll geändert werden?';
        this.ticketsCraftNotSelected = 'Kein Gewerk';
        this.ticketsCreateNewTicketQuestion = 'Ein neues Ticket erstellen?';
        this.ticketsDetails = 'Details';
        this.ticketsDetailsActivities = 'Aktivitäten';
        this.ticketsDetailsAddAttachment = 'Anhang hinzufügen';
        this.ticketsDetailsAddComment = 'Kommentar hinzufügen';
        this.ticketsDetailsAddDescription = 'Beschreibung hinzufügen';
        this.ticketsDetailsAddWatcher = 'Beobachter hinzufügen';
        this.ticketsDetailsChangeCommentError = 'Fehler beim Bearbeiten des Ticket- Kommentars.';
        this.ticketsDetailsCompletionOn = 'Fällig am';
        this.ticketsDetailsCopyLinkToTicket = 'Link kopieren';
        this.ticketsDetailsCraft = 'Gewerk';
        this.ticketsDetailsDescription = 'Beschreibung';
        this.ticketsDetailsEditableAddTitle = 'Titel hinzufügen';
        this.ticketsDetailsErrorGenericIssue = 'Fehler beim Bearbeiten des Tickets.';
        this.ticketsDetailsErrorStatusChange = 'Sie haben keine Berechtigung, den Ticketstatus in "{{newState}}" zu ändern.';
        this.ticketsDetailsGetCommentError = 'Fehler beim Abrufen der Ticket- Kommentare.';
        this.ticketsDetailsLastEdit = 'Letzte Bearbeitung';
        this.ticketsDetailsMap = 'Ausführungsadresse';
        this.ticketsDetailsNoDescription = 'Keine Beschreibung';
        this.ticketsDetailsNumber = 'Ticketnummer';
        this.ticketsDetailsObserver = 'Beobachter';
        this.ticketsDetailsOpenFileStorage = 'aus Projektablage kopieren';
        this.ticketsDetailsOpenFiles = 'Dateien hinzufügen';
        this.ticketsDetailsPlan = 'Planzugehörigkeit';
        this.ticketsDetailsRemoveTicketLocation = 'Ticketverortung entfernen';
        this.ticketsDetailsSendCommentError = 'Fehler beim Erzeugen des Ticket- Kommentars.';
        this.ticketsDetailsState = 'Status';
        this.ticketsDetailsStateNoStatus = 'Kein Status';
        this.ticketsDetailsStatechecked = 'Fertig';
        this.ticketsDetailsStateclosed = 'Zu prüfen';
        this.ticketsDetailsStatenoStatus = 'Kein Status';
        this.ticketsDetailsStateopen = 'Offen';
        this.ticketsDetailsStateprocessing = 'In Bearbeitung';
        this.ticketsDetailsTags = 'Tags';
        this.ticketsDetailsTitle = 'Titel';
        this.ticketsDetailsTitleApprover = 'Prüfer';
        this.ticketsDetailsTitleAssignedToUser = 'Zugewiesen an';
        this.ticketsDetailsTitleNoApproverAssignedToUser = 'Kein Prüfer';
        this.ticketsDetailsTitleNotAssignedToUser = 'Nicht zugewiesen';
        this.ticketsDetailsTitlePlaceholder = 'Kein Titel';
        this.ticketsDetailsType = 'Typ';
        this.ticketsDetailsTypeDefect = 'Mangel';
        this.ticketsDetailsViewChangeToCalendarView = 'Kalender-Ansicht';
        this.ticketsDetailsViewChangeToCanban = 'Canban-Ansicht';
        this.ticketsDetailsViewChangeToGantt = 'Gantt-Diagrammansicht';
        this.ticketsDetailsViewChangeToListView = 'Listenansicht';
        this.ticketsDetailsViewChangeToTableView = 'Tabellenansicht';
        this.ticketsDetailsWatchNow = 'Ich beobachte nicht';
        this.ticketsDetailsWatching = 'Ich beobachte';
        this.ticketsDetailsWriteComment = 'Kommentieren...';
        this.ticketsFilterEndBeforeStart = 'Ende vor Start';
        this.ticketsFilterInTwoWeeks = 'In 2 Wochen';
        this.ticketsFilterPersons = 'Personen';
        this.ticketsFilterStartOrEndNotSet = 'Ende oder Anfang nicht gesetzt';
        this.ticketsFilterTimePeriod = 'Änderungszeitraum';
        this.ticketsFilterToday = 'Heute';
        this.ticketsFilterTomorrow = 'Morgen';
        this.ticketsNotFoundDescription = 'Tickets erstellen';
        this.ticketsNotFoundDescriptionAllProjects = 'Alle Tickets an einem Ort';
        this.ticketsNotFoundDueToFilter = 'Keine Tickets gefunden...';
        this.ticketsNotFoundDueToFilterDescription = 'Führen Sie eine neue Suche durch oder benutzen Sie die Filterfunktion.';
        this.ticketsNotFoundHint = 'Wählen Sie einen Plan aus, um darauf Tickets zu erstellen oder fügen Sie Tickets' +
            ' ohne Verortung hinzu, indem Sie auf den + Button drücken';
        this.ticketsNotFoundHintAllProjects = 'Hier finden Sie alle Tickets über alle Projekt an einem Ort. Neue Tickets' +
            ' erstellen Sie direkt im jeweiligen Projekt';
        this.ticketsNotFoundHintSplitView = 'Erstellen Sie ein Ticket, indem Sie auf den + Button drücken';
        this.ticketsShowPlan = 'Plan anzeigen';
        this.ticketsShowTickets = 'Tickets anzeigen';
        this.ticketsSortBy = 'Sortieren nach';
        this.ticketsWithoutCompletionOn = 'Tickets ohne Datum';
        this.till = 'bis';
        this.timeTracking = 'Zeiterfassung';
        this.timeTrackingAddTimeTrack = 'Zeit erfassen';
        this.timeTrackingBulkChangesChangeCostCenter = 'Kostenstelle ändern';
        this.timeTrackingBulkChangesChangeEnd = 'Endzeit ändern';
        this.timeTrackingBulkChangesChangePause = 'Pausenzeit ändern';
        this.timeTrackingBulkChangesChangeProjekt = 'Projekt ändern';
        this.timeTrackingBulkChangesChangeStart = 'Startzeit ändern';
        this.timeTrackingBulkChangesChangeStatus = 'Status ändern';
        this.timeTrackingBulkChangesChangeTask = 'Tätigkeit ändern';
        this.timeTrackingBulkChangesChangingTickets = 'Ändere Zeiterfassung {{count}} von {{sum}}.';
        this.timeTrackingBulkChangesConfirmationDialogHeader = 'Zeiterfassungen bearbeiten';
        this.timeTrackingBulkChangesConfirmationDialogHeaderDelete = 'Zeiterfassungen löschen';
        this.timeTrackingBulkChangesConfirmationDialogText = 'Wollen Sie diese {{count}} Zeiterfassungen wirklich bearbeiten?';
        this.timeTrackingBulkChangesConfirmationDialogTextDelete = 'Wollen Sie diese {{count}} Zeiterfassungen wirklich löschen?';
        this.timeTrackingBulkChangesNoRights = '{{count}} Zeiterfassungseinträge konnten wegen fehlender Berechtigung nicht geändert werden';
        this.timeTrackingBulkChangesSelectAll = 'Alle auswählen';
        this.timeTrackingBulkChangesSelectNone = 'Keine auswählen';
        this.timeTrackingCSVexportButtonExport = 'Exportieren';
        this.timeTrackingCSVexportCostCenter = 'Kostenstelle/Kostenträger';
        this.timeTrackingCSVexportDate = 'Datum';
        this.timeTrackingCSVexportEnde = 'Ende';
        this.timeTrackingCSVexportHours = 'Stunden';
        this.timeTrackingCSVexportHoursOrValue = 'Menge';
        this.timeTrackingCSVexportIncludeAbsence = 'Abwesenheiten einbinden';
        this.timeTrackingCSVexportLockText = 'Einträge als "exportiert" kennzeichnen?';
        this.timeTrackingCSVexportNote = 'Notizen';
        this.timeTrackingCSVexportPause = 'Pause';
        this.timeTrackingCSVexportProject = 'Projekt';
        this.timeTrackingCSVexportStaffNumber = 'Personalnummer';
        this.timeTrackingCSVexportStart = 'Start';
        this.timeTrackingCSVexportTask = 'Tätigkeit';
        this.timeTrackingCSVexportText = 'Es werden durch die aktuellen Filtereinstellungen {{count}} Einträge exportiert. Sie können den Filter nutzen, um' +
            ' die Auswahl zu ändern.';
        this.timeTrackingCSVexportUnit = 'Einheit';
        this.timeTrackingCSVexportUser = 'Mitarbeiter';
        this.timeTrackingCSVexportWageType = 'Lohnart';
        this.timeTrackingCapture = 'Erfassen';
        this.timeTrackingCaptureNewWorktime = 'Neue Arbeitszeit erfassen';
        this.timeTrackingCaptureTime = 'Zeit erfassen';
        this.timeTrackingChangeFoundCollision = 'Es gibt kollidierende Einträge';
        this.timeTrackingChangeProject = 'Projekt auswählen';
        this.timeTrackingChangeSaveAnyway = 'speichern';
        this.timeTrackingChangeShowCollision = 'Einträge anzeigen';
        this.timeTrackingChangeStatus = 'Status ändern';
        this.timeTrackingChangeTimeTrack = 'Zeiterfassung ändern';
        this.timeTrackingCurrentPause = 'pausiert {{currentPause}}';
        this.timeTrackingDeleteEntryQuestion = 'Möchten Sie diesen Eintrag wirklich löschen?';
        this.timeTrackingEndAuto = 'Automatisch beendet durch Routine';
        this.timeTrackingEntryLocked = 'Der Eintrag ist für Veränderungen gesperrt';
        this.timeTrackingErrorEndsBeforeStart = 'Endzeit vor Startzeit';
        this.timeTrackingErrorEndsStartDiff = 'Die Gesamtzeit darf nicht größer als 24 Stunden sein';
        this.timeTrackingExportAddisonCompanyInvalid = 'Die Firmennummer "{{error}}" ist ungültig für einen Addison-Export. (maximal 7 Ziffern).';
        this.timeTrackingExportAddisonCompanyNumber = 'Firmennummer';
        this.timeTrackingExportAddisonCompanyNumberNeeded = 'Für den Addison-Export wird eine Firmennummer benötigt.';
        this.timeTrackingExportDatevAdditionalFields = 'Für den LODAS-Export wird eine MandantenNr und eine BeraterNr benötigt.';
        this.timeTrackingExportDatevBeraterInvalid = 'Die Beraternummer "{{error}}" ist ungültig für einen LODAS-Export. (mindestens 4 maximal 7 Stellen)';
        this.timeTrackingExportDatevBeraterNrNeeded = 'BeraterNr';
        this.timeTrackingExportDatevLohnAccountingDate = 'Abrechnungdatum';
        this.timeTrackingExportDatevLohnBeraterInvalid = 'Die BeraterNr "{{error}}" ist ungültig. (Wertebereich von 1000 bis 9999999.)';
        this.timeTrackingExportDatevLohnIniFileDownload = 'INI-Datei herunterladen';
        this.timeTrackingExportDatevLohnMandantInvalid = 'Die MandantenNr "{{error}}" ist ungültig. (Wertebereich von 1 bis 99999.)';
        this.timeTrackingExportDatevLohnPersAsCompanyPers = 'Personalnummer als betriebliche Personalnummer kennzeichnen';
        this.timeTrackingExportDatevMandantenInvalid = 'Die MandantenNr "{{error}}" ist ungültig für einen LODAS-Export. (mindestens eine maximal 5 Stellen)';
        this.timeTrackingExportDatevMandantenNrNeeded = 'MandantenNr';
        this.timeTrackingExportExcelActualTime = 'Ist Zeit';
        this.timeTrackingExportExcelDiffTime = 'Differenz';
        this.timeTrackingExportExcelFilename = 'Stundenliste';
        this.timeTrackingExportExcelNoEntries = 'Keine Einträge zum Exportieren vorhanden.';
        this.timeTrackingExportExcelNotOnline = 'Das Exportieren der Zeiterfassungseinträge ist nur Online möglich.';
        this.timeTrackingExportExcelOverview = 'Übersicht';
        this.timeTrackingExportExcelRecords = 'Erfassungseinträge';
        this.timeTrackingExportExcelStaffNumber = 'Personal-Nr';
        this.timeTrackingExportExcelSumAsDecimal = 'Gesamt:';
        this.timeTrackingExportExcelTargetTime = 'Soll Zeit';
        this.timeTrackingExportExcelTooltip = 'Excel-Export der Zeiterfassungstabelle';
        this.timeTrackingExportNovalineKndNrHelper = 'Kunden-Nr.';
        this.timeTrackingExportOptionsAddison = 'Addison-Export';
        this.timeTrackingExportOptionsBRZ = 'BRZ-Export';
        this.timeTrackingExportOptionsCSV = 'CSV-Export';
        this.timeTrackingExportOptionsDatev = 'LODAS-Export';
        this.timeTrackingExportOptionsGDI = 'GDI-Export';
        this.timeTrackingExportOptionsLohnUndGehalt = 'Datev Lohn und Gehalt';
        this.timeTrackingExportOptionsNovaline = 'Novaline-Export';
        this.timeTrackingExportTitle = 'Exportieren';
        this.timeTrackingExportType = 'Exporttyp';
        this.timeTrackingExportWarningText = 'Einige Kennzeichen sind ungültig und wurden im Export angepasst:';
        this.timeTrackingFilter = 'Erfassungseinträge filtern';
        this.timeTrackingFilterDateRange = 'Zeitraum';
        this.timeTrackingFilterDay = 'Vom';
        this.timeTrackingFilterDayTo = 'Bis';
        this.timeTrackingFilterLastCalcMonth = 'Letzten Abrechnungsmonat';
        this.timeTrackingFilterLastMonth = 'Letzten Monat';
        this.timeTrackingFilterLastWeek = 'Letzte Woche';
        this.timeTrackingFilterLastYear = 'Letztes Jahr';
        this.timeTrackingFilterStatus = 'Status';
        this.timeTrackingFilterStatusAll = 'Alle';
        this.timeTrackingFilterStatusExported = 'exportiert';
        this.timeTrackingFilterStatusLocked = 'gesperrt';
        this.timeTrackingFilterStatusRecorded = 'erfasst';
        this.timeTrackingFilterStatusRunning = 'laufend';
        this.timeTrackingFilterTask = 'Tätigkeiten filtern';
        this.timeTrackingFilterThisCalcMonth = 'Diesen Abrechnungsmonat';
        this.timeTrackingFilterThisMonth = 'Diesen Monat';
        this.timeTrackingFilterThisWeek = 'Diese Woche';
        this.timeTrackingFilterThisYear = 'Dieses Jahr';
        this.timeTrackingFilterToday = 'Heute';
        this.timeTrackingFilterYesterday = 'Gestern';
        this.timeTrackingHoursShort = 'Std';
        this.timeTrackingLock = 'Für andere sperren';
        this.timeTrackingMinutesShort = 'Min';
        this.timeTrackingMyTimes = 'Meine Zeiten';
        this.timeTrackingNoDataHint = 'Erstellen Sie neue Zeiterfassungseinträge, indem Sie auf den + Button drücken';
        this.timeTrackingNoDataText = 'Keine Zeiten erfasst';
        this.timeTrackingNoProjectOrCostCenter = 'Kein Projekt und keine Kostenstelle vorhanden';
        this.timeTrackingNotInRecordingPeriod = 'Das Startdatum liegt nicht im möglichen Erfassungszeitraum';
        this.timeTrackingNotes = 'Notizen';
        this.timeTrackingPause = 'Tätigkeit pausieren';
        this.timeTrackingPlay = 'Tätigkeit wiederaufnehmen';
        this.timeTrackingRecorder = 'Erfasser';
        this.timeTrackingShort = 'Zeiten';
        this.timeTrackingSince = 'seit';
        this.timeTrackingStatus = 'Status';
        this.timeTrackingStopTimer = 'Beenden';
        this.timeTrackingSum = 'Gesamt';
        this.timeTrackingTaskOrProject = 'Tätigkeit/Projekt wechseln';
        this.timeTrackingUnlock = 'entsperren';
        this.timeTrackingViewChangeCalandar = 'Kalenderansicht';
        this.timeTrackingViewChangeHideMissing = 'Fehlende Erfassungen ausblenden';
        this.timeTrackingViewChangeList = 'Listenansicht';
        this.timeTrackingViewChangeShowMissing = 'Fehlende Erfassungen einblenden';
        this.timeframe = 'Zeitraum';
        this.timetrackingErrorNoEnd = 'Endzeit wird benötigt';
        this.timetrackingErrorNoStart = 'Startzeit wird benötigt';
        this.timetrackingsRunningHeadline = 'Laufende Zeiterfassungen';
        this.toStartSite = 'Zur Startseite';
        this.today = 'Heute';
        this.tokenAlreadyExpired = 'Der Token ist bereits abgelaufen';
        this.tokenError = 'Zugang abgelaufen';
        this.tomorrow = 'Morgen';
        this.tooltipAddTicketWithPlanAffiliation = 'Neues Ticket mit Planzugehörigkeit erstellen';
        this.tooltipBackToTicketSite = 'Zurück zur Ticket- Übersicht';
        this.tooltipCreateMap = 'Karte erstellen';
        this.tooltipCreateNewProject = 'Neues Projekt erstellen';
        this.tooltipDown = 'Runter';
        this.tooltipEditPlanDetails = 'Plan- Details bearbeiten';
        this.tooltipEditProjectDetails = 'Projektdetails bearbeiten';
        this.tooltipEditTicketComment = 'Ticketkommentar bearbeiten';
        this.tooltipEditTicketDescription = 'Ticketbeschreibung bearbeiten';
        this.tooltipEditTicketTitle = 'Tickettitel bearbeiten';
        this.tooltipOpenCamera = 'Kamera öffnen';
        this.tooltipOpenFiling = 'Dateien';
        this.tooltipPlanZoomIn = 'Plan vergrößern';
        this.tooltipPlanZoomOut = 'Plan verkleinern';
        this.tooltipTicketsDetailsSend = 'Ticketkommentar abschicken';
        this.tooltipUp = 'Hoch';
        this.tooltipUploadPlan = 'Pläne hochladen';
        this.tooltopFilterProjects = 'Projekte filtern';
        this.totalNumberOfEmployees = 'Gesamtzahl Mitarbeiter';
        this.totalNumberOfHours = 'Gesamtzahl Stunden';
        this.trackingType = 'Erfassungstyp';
        this.trackingTypeAbsence = 'Abwesenheit';
        this.trackingTypeExtraPay = 'Zulage';
        this.trackingTypeTime = 'Zeit';
        this.type = 'Art';
        this.unarchiveTicket = 'Ticket reaktivieren';
        this.unarchiveTicketShort = 'reaktivieren';
        this.unauthorized = 'nicht autorisiert';
        this.undefined = '?';
        this.unsavedChangesInDialog = 'Änderungen wurden nicht gespeichert.';
        this.upTicket = 'Übergeordnetes Ticket';
        this.updateNeededButton = 'Jetzt aktualisieren';
        this.updateNeededText = 'Es wird ein Update für die Serververbindung benötigt. Bitte aktualisieren Sie die App.';
        this.updateNeededTitle = 'Update benötigt';
        this.upgradeProject = 'Upgrade';
        this.upgradeProjectConfirmationMessageEnterprise = 'Sind Sie sicher, dass Sie das Projekt zu einem Firmen-Projekt (Enterprise)' +
            ' upgraden möchten?\nDann gehört das Projekt ab sofort Ihrer Firma.\nDas Upgrade kann nicht rückkängig gemacht' +
            ' werden!';
        this.upgradeProjectConfirmationMessageEnterpriseOrPro = 'Möchten Sie das Projekt zu einem Pro-Projekt oder zu einem' +
            ' Firmen-Projekt upgraden?\nDas Firmen-Projekt (Enterprise) gehört dann ab sofort der Firma.\nDas Upgrade kann' +
            ' nicht rückgängig gemacht werden';
        this.upgradeProjectConfirmationMessagePro = 'Sind Sie sicher, dass Sie das Projekt zu einem Pro-Projekt upgraden' +
            ' möchten?\n Das Upgrade kann nicht rückgängig gemacht werden!';
        this.upgradeProjectConfirmationToEnterpriseButton = 'Firmen-Projekt';
        this.upgradeProjectConfirmationToProButton = 'Pro-Projekt';
        this.upgradeProjectQuestion = 'Sie müssen das Projekt upgraden, um diese Funktion nutzen zu können. Das Upgrade kann' +
            ' im Dashboard durchgeführt werden. Wollen Sie zum Dashboard wechseln?';
        this.upgradeProject_enterprise = 'Upgrade zu Firmen-Projekt';
        this.upgradeProject_pro = 'Upgrade zu Pro-Projekt';
        this.uploadCompanyBackGroundLogo = 'Hintergrundbild hochladen';
        this.uploadCompanyLogo = 'Firmenlogo hochladen';
        this.uploadFiles = 'Datei(en) hochladen';
        this.uploadFolder = 'Ordner hochladen';
        this.uploadNewLogo = 'Neu hochladen';
        this.uploadPending = 'Upload ausstehend';
        this.upmeshPartner = 'Partner';
        this.user = 'Benutzer';
        this.userAdress = 'Benutzeradressen';
        this.userAlreadyAssignedToOneCompany = 'Der Benutzer ist bereits in einer anderen Firma gelistet';
        this.userAlreadyDeleted = 'Benutzer bereits entfernt';
        this.userDetailsBackToUser = 'Mitarbeiter infos anzeigen';
        this.userDetailsQrCodeHelp = 'vcard anzeigen';
        this.userDetailsQrDownload = 'vcard herunterladen';
        this.userId = 'Benutzer';
        this.userMailNotFound = 'E-Mail-Adresse nicht gefunden';
        this.userMails = 'Benutzer Mails';
        this.userNotFound = 'Benutzer nicht gefunden';
        this.userSettings = 'Benutzereinstellungen';
        this.userWithNoAccountCantGetFullLicense = 'Einem Benutzer ohne Account kann keine Volllizenz zugeordnet werden';
        this.username = 'Benutzername';
        this.validFrom = 'Gültig ab';
        this.videos = 'Videos';
        this.watcher = 'Beobachter';
        this.watcherIamNotWatching = 'Beobachten';
        this.watcherIamWatching = 'Beobachtung beenden';
        this.watcherMemberAreWatching = 'Dieses Ticket wird beobachtet von:';
        this.watcherMemberEdit = 'Beobachter bearbeiten';
        this.weather = 'Wetter';
        this.website = 'Webseite';
        this.week = 'Woche';
        this.weeks = `{{count}} Wochen`;
        this.welcome = 'Willkommen';
        this.whatsNewBugs = 'Problembehebungen';
        this.whatsNewFeatures = 'Neuerungen';
        this.whatsNewShow = 'Neuerungen anzeigen';
        this.whatsNewTitle = 'Neues in Version {{version}}';
        this.widgetIframeInvalidUrl = 'Bitte geben sie eine gültige URL ein';
        this.widgetIframeTitle = 'Titel';
        this.widgetIframeUrl = 'Url';
        this.workingTimeModel = 'Arbeitszeitmodell';
        this.workingTimeModelAdd = 'Arbeitszeitmodell hinzufügen';
        this.workingTimeModelAddSection = 'Zeitabschnitt hinzufügen';
        this.workingTimeModelBillingDate = 'Beginn des Abrechnungszeitraums:';
        this.workingTimeModelCantDeleteDefault = 'Das Standard Arbeitszeitmodell kann nicht gelöscht werden';
        this.workingTimeModelCantDeleteUsed = 'Das Arbeitszeitmodell kann nicht gelöscht werden solange es einem aktiven Benutzer zugewiesen ist oder ein' +
            ' gelöschter Benutzer noch Zeiten erfasst hat. Benutzer: {{users}}';
        this.workingTimeModelEdit = 'Arbeitszeitmodell bearbeiten';
        this.workingTimeModelMark = 'Kennzeichen';
        this.workingTimeModelNoSectionsWithSameValidDate = 'Es darf kein Zeitabschnitt mit der selben Gültigkeit geben (Abschnitt {{section1}} und Abschnitt {{section2}})';
        this.workingTimeModelPostRecordingInDays = 'Nacherfassen innnerhalb Tagen ermöglichen';
        this.workingTimeModelPostRecordingInDaysText = 'Nacherfassen möglich innnerhalb des letzten Tages';
        this.workingTimeModelPostRecordingInDaysText_0 = 'Keine Nacherfassen möglich';
        this.workingTimeModelPostRecordingInDaysText_plural = 'Nacherfassen möglich innnerhalb der letzten {{count}} Tage';
        this.workingTimeModelPostRecordingInDaysText_zero = 'Keine Nacherfassen möglich';
        this.workingTimeModelPostRecordingInDaysTooLarge = 'Der Zeitraum für das Nacherfassen ist zu groß';
        this.workingTimeModelSectionRequired = 'Es wird mindestens ein Zeitabschnitt benötigt';
        this.workingTimeModelSetAsDefault = 'als Standard setzen';
        this.workingTimeModels = 'Arbeitszeitmodelle';
        this.workingTimeModelsPause = 'Pause';
        this.workingTimeModelsStart = 'Start';
        this.workingTimeModelsStop = 'Ende';
        this.workingTimeModelsTimes = 'Zeiten';
        this.year = 'Jahr';
        this.yes = 'Ja';
        this.yesterday = 'Gestern';
        this.zoomAndMoveToDefinePlanSnapshot = 'Zoomen und verschieben um Planausschnitt zu definieren';
        this.zoomError = 'Fehler beim Ändern der Zoomstufe.';
        this.zoomIn = 'vergrößern';
        this.zoomOut = 'verkleinern';
    }
}
